import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';

/** The code is defining a functional component called `WarningModal`
 * that represents a modal window for showing a warning popup.
 * The component takes three props: `isModalOpen`, `handleConfirm`, `handleDeny`.
 * The `isModalOpen` prop is a boolean that determines whether the modal is open or not.
 * The `handleConfirm` prop is a function that closes the modal when user confirms to take action even after the warning.
 * The `handleDeny` prop is a function that closes the modal when user denies to take action after the warning.
 * The component uses the `Modal` component from the `react-modal` library to render the modal.
 * The modal is a div with a white background and rounded corners.
 * The modal has a header and a warning text and two buttons to confirm and deny.
 * */

function WarningModal(props) {
  const { isModalOpen, handleConfirm, handleDeny } = props;
  const { t } = useTranslation('common');

  return (
    <Modal
      isOpen={isModalOpen}
      className="fixed inset-0 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
      appElement={document.getElementById('root')}
      ariaHideApp={false}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg z-10">
        <h2 className="text-lg font-semibold mb-4">
          {t('repAccessLandingPage.editWarningModal.warningHeader')}
        </h2>
        <p className="mb-6">
          {t('repAccessLandingPage.editWarningModal.warningText')}
        </p>
        <div className="flex justify-center space-x-4">
          <Button
            content={t('repAccessLandingPage.editWarningModal.confirm')}
            dataTestid="submit-switch-user"
            onClick={handleConfirm}
          />
          <Button
            content={t('repAccessLandingPage.editWarningModal.deny')}
            dataTestid="submit-switch-user"
            onClick={handleDeny}
          />
        </div>
      </div>
    </Modal>
  );
}

WarningModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleDeny: PropTypes.func.isRequired,
};

export default WarningModal;
