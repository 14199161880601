import { REFRESH_INTERVAL } from '../../../constants/pollingDuration.constant';

/**
 * This class contains all the secrets required to communicate with PingID
 * @class PingIdSecrets
 * @description
 * This class contains all the secrets required to communicate with PingID
 */

class PingIdSecrets {
  static clientId = `${process.env.REACT_APP_CLIENT_ID}`;

  static ssoProvider = `${process.env.REACT_APP_SSO_PROVIDER}`;

  static ssoTokenProvider = `${process.env.REACT_APP_SSO_TOKEN_PROVIDER}`;

  // static redirectUri = 'https://dev.rep-copilot-app.jetnerve.apps.jnj.com';
  static redirectUri = window.location.origin;

  static refreshInterval = REFRESH_INTERVAL;

  static getAuthTokenUrl() {
    return `${PingIdSecrets.ssoProvider}?response_type=code&client_id=${PingIdSecrets.clientId}&redirect_uri=${PingIdSecrets.redirectUri}&scope=profile`;
  }

  static decodeAccessToken(jwtString) {
    const [, encodedPayload] = jwtString.split('.');
    return JSON.parse(atob(encodedPayload));
  }
}

export default PingIdSecrets;
