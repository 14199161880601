import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button/Button';

const onLoginAgain = () => {
  window.location.href = window.location.origin;
};

/**
 * The Logout function renders a div with a button that says "Login again" and calls the onLoginAgain
 * function when clicked.
 * @returns The Logout component is returning a div element with the class name "flex flex-col
 * items-center justify-center h-screen bg-white px-10". Inside the div, there is a Button component
 * with the content "Login again" and an onClick event handler set to the onLoginAgain function.
 */
function Logout(props) {
  const { content } = props;
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-10">
      {content && <h1 className="m-8">{content}</h1>}
      <Button content="Login again" onClick={onLoginAgain} />
    </div>
  );
}

Logout.propTypes = {
  content: PropTypes.string,
};

Logout.defaultProps = {
  content: '',
};

export default Logout;
