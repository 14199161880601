/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import JSON_QUES_TYPE from '../../constants/jsonQuestionType';

/**
 * The `ChatMessageDisplayCard` component is a React component that displays a chat message.
 * @returns The `ChatMessageDisplayCard` component is returning a `div` element.
 */
function ChatMessageDisplayCard(props) {
  const { isUserMessage, messageText } = props;
  const {
    isLastInputQues,
    onPreCallPlannerSelect,
    preCallPrevSelected,
    userInputQues,
  } = props;
  const formattedText = isUserMessage
    ? messageText
    : messageText.replace(/\n/g, '<br />');
  return (
    <div
      className={`border flex-1 p-3 ${
        isUserMessage ? 'bg-card-500' : 'bg-secondary-100'
      }`}
    >
      <p dangerouslySetInnerHTML={{ __html: formattedText }} />
      {isLastInputQues &&
        (!userInputQues || Object.keys(userInputQues)?.length <= 0) && (
          <Button
            dataTestid="edit-pre-call"
            content="Edit pre-call plan"
            onClick={() => onPreCallPlannerSelect(preCallPrevSelected)}
            buttonType="secondary"
            className="mt-3 w-fit"
          />
        )}
    </div>
  );
}

ChatMessageDisplayCard.propTypes = {
  isUserMessage: PropTypes.bool.isRequired,
  messageText: PropTypes.string.isRequired,
  isLastInputQues: PropTypes.bool,
  onPreCallPlannerSelect: PropTypes.func,
  preCallPrevSelected: JSON_QUES_TYPE,
  userInputQues: JSON_QUES_TYPE,
};

ChatMessageDisplayCard.defaultProps = {
  isLastInputQues: false,
  onPreCallPlannerSelect: () => {},
  preCallPrevSelected: {},
  userInputQues: {},
};

export default ChatMessageDisplayCard;
