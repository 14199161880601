import React, { useCallback, useContext } from 'react';
import { get } from 'lodash';
import ChatMessageDisplayCard from '../../components/ChatMessageDisplayCard/ChatMessageDisplayCard';
import ConversationContext from '../../context/Conversation.context';
import {
  useAddFeedback,
  useChatsData,
  useAddBookmark,
  useFetchBookmarks,
} from '../../httpRequestHooks';
import FeedbackPanel from '../../components/FeedbackPanel/FeedbackPanel';
import Loader from '../../components/Loader/Loader';
import DateTimeField from '../../components/DateTimeField/DateTimeField';
import Error from '../../components/Error';
import { aiIcon, userProfile } from '../../assets/icons';
import Avatar from '../../components/Avatar/Avatar';
import DefaultAIMessage from '../../components/DefaultAIMessage/DefaultAIMessage';

/**
 * The `Conversation` function is a React component that renders the conversation section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useAddFeedback` hook is used to add the feedback.
 * The `useAddBookmark` hook is used to add the bookmark.
 * The `useFetchBookmarks` hook is used to fetch the bookmarks.
 * The `useEffect` hook is used to set the last message index and type effect.
 * @returns The `Conversation` function returns a React component.
 * @category Components
 * @example <caption>Example usage of Conversation.</caption>
 * @param {object} props The `Conversation` function does not take in any props.
 * The `Conversation` function renders the conversation section.
 */
function Conversation() {
  const { conversationId } = useContext(ConversationContext);

  const {
    data: allChatsData,
    isLoading: isChatDataLoading,
    isSuccess: isChatDataSuccess,
    error: chatError,
    isError: isChatError,
    refetch: fetchChatsData,
  } = useChatsData(conversationId);

  const onFeedbackSuccess = useCallback(() => {
    fetchChatsData();
  }, []);

  const { mutate: addFeedback } = useAddFeedback(onFeedbackSuccess);

  const onFeedbackChange = useCallback(
    (messageId, isHelpful) => {
      addFeedback({ conversationId, messageId, isHelpful });
    },
    [conversationId],
  );

  const { refetch: refetchBookmarks } = useFetchBookmarks();

  const onBookmarkSuccess = useCallback(() => {
    refetchBookmarks();
    fetchChatsData();
  }, []);

  const { mutate: addBookmark } = useAddBookmark(onBookmarkSuccess);

  const onBookmarkChange = useCallback(
    (messageId, isBookmarked) => {
      addBookmark({ conversationId, messageId, isBookmarked });
    },
    [conversationId],
  );

  return (
    <>
      {isChatDataLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isChatError && <Error error={chatError} />}
      {isChatDataSuccess && (
        <div
          className="flex flex-col my-4 flex-1 mb-10 justify-end"
          data-testid="dataId"
        >
          <DefaultAIMessage />
          {allChatsData.map(message => {
            const messageRole = get(message, 'role', 'AI');
            const isUserMessage = messageRole === 'USER';
            const messageText = get(message, 'message.body', null);
            const messageId = get(message, 'messageId', null);
            const isHelpful = get(message, 'isHelpful', null);
            const isBookmarked = get(message, 'isBookmarked', false);
            const messageCreation = get(message, 'createdAt', null);
            return (
              <div
                key={`conversation-message-${messageId}`}
                className="my-4 flex flex-row"
              >
                <Avatar srcImage={isUserMessage ? userProfile : aiIcon} />
                <div className="flex flex-col ml-3 w-full" id={messageId}>
                  <ChatMessageDisplayCard
                    isUserMessage={isUserMessage}
                    messageText={messageText}
                  />
                  <div className="flex flex-row justify-between">
                    <DateTimeField messageCreation={messageCreation} />
                    {messageRole === 'AI' && (
                      <FeedbackPanel
                        conversationId={conversationId}
                        messageId={messageId}
                        isHelpful={isHelpful}
                        onFeedbackChange={onFeedbackChange}
                        onBookmarkChange={onBookmarkChange}
                        isBookmarked={isBookmarked}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Conversation;
