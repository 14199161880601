/* eslint-disable react/require-default-props */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import ChatMessageDisplayCard from '../../components/ChatMessageDisplayCard/ChatMessageDisplayCard';
import ConversationContext from '../../context/Conversation.context';
import {
  useAddFeedback,
  useChatsData,
  useAddBookmark,
  useFetchBookmarks,
} from '../../httpRequestHooks';
import FeedbackPanel from '../../components/FeedbackPanel/FeedbackPanel';
import Loader from '../../components/Loader/Loader';
import DateTimeField from '../../components/DateTimeField/DateTimeField';
import Error from '../../components/Error';
import { aiIcon, callPlanner, userProfile } from '../../assets/icons';
import Avatar from '../../components/Avatar/Avatar';
import OptionSelectCard from '../../components/OptionSelectCard/OptionSelectCard';
import SpecialMessage from '../../components/SpecialMessage/SpecialMessage';
import { preCallPlannerOptions } from '../../constants/preCallPlanCriteria.constant';
import JSON_QUES_TYPE from '../../constants/jsonQuestionType';

/**
 * The `Conversation` function is a React component that renders the conversation section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useAddFeedback` hook is used to add the feedback.
 * The `useAddBookmark` hook is used to add the bookmark.
 * The `useFetchBookmarks` hook is used to fetch the bookmarks.
 * The `Conversation` component renders the `Loader` component if the chats data is loading.
 * The `Conversation` component renders the `Error` component if the chats data is errored.
 * The `Conversation` component renders the `DefaultAIMessage` component if the chats data is success and the chats data is empty.
 * The `Conversation` component renders the `ChatMessageDisplayCard` component for each message in the chats data.
 * The `Conversation` component renders the `FeedbackPanel` component for each message in the chats data if the message role is AI.
 */
function Conversation({
  onPreCallSelectionComplete,
  onPreCallPlannerSelect,
  preCallPrevSelected,
  sellingDivision,
}) {
  const { conversationId, userInputQues, setUserInputQues } =
    useContext(ConversationContext);
  const { t } = useTranslation('common');

  const {
    data: allChatsData,
    isLoading: isChatDataLoading,
    isSuccess: isChatDataSuccess,
    error: chatError,
    isError: isChatError,
    refetch: fetchChatsData,
  } = useChatsData(conversationId);

  const onFeedbackSuccess = useCallback(() => {
    fetchChatsData();
  }, []);

  const { mutate: addFeedback } = useAddFeedback(onFeedbackSuccess);

  const onFeedbackChange = useCallback(
    (messageId, isHelpful) => {
      addFeedback({ conversationId, messageId, isHelpful });
    },
    [conversationId],
  );

  const { refetch: refetchBookmarks } = useFetchBookmarks();

  const onBookmarkSuccess = useCallback(() => {
    refetchBookmarks();
    fetchChatsData();
  }, []);

  const { mutate: addBookmark } = useAddBookmark(onBookmarkSuccess);

  const onBookmarkChange = useCallback(
    (messageId, isBookmarked) => {
      addBookmark({ conversationId, messageId, isBookmarked });
    },
    [conversationId],
  );

  const messageText = t('preCallPlannerChat.title');

  const flowOptions = [
    {
      type: t('preCallPlannerChat.heading-widget'),
      onSelect: () =>
        onPreCallPlannerSelect(preCallPlannerOptions(sellingDivision)),
      metaData: t('preCallPlannerChat.description'),
      optionIcon: callPlanner,
    },
  ];

  const onCancel = () => {
    setUserInputQues(null);
  };
  const userInputQuesType = get(userInputQues, 'questionType', null);
  const preCallQuesType = get(
    preCallPlannerOptions(sellingDivision),
    'questionType',
    null,
  );

  return (
    <>
      {isChatDataLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isChatError && <Error error={chatError} />}
      {isChatDataSuccess && (
        <div className="flex flex-col my-4 flex-1 justify-end">
          <OptionSelectCard
            messageText={messageText}
            flowOptions={flowOptions}
          />
          {allChatsData.map((message, index) => {
            const messageRole = get(message, 'role', 'AI');
            const isUserMessage = messageRole === 'USER';
            const messageBody = get(message, 'message.body', null);
            const messageType = get(message, 'message.type', null);
            const messageId = get(message, 'messageId', null);
            const isHelpful = get(message, 'isHelpful', null);
            const isBookmarked = get(message, 'isBookmarked', false);
            const messageCreation = get(message, 'createdAt', null);
            const jsonContext = get(message, 'message.jsonContext', {});
            const messageData = {
              body: messageBody,
              ...jsonContext,
            };
            const checkIsLastInputQues = () => {
              if (!isUserMessage && allChatsData.length - 1 === index) {
                const questionType = get(
                  allChatsData,
                  `[${index - 1}].message.type`,
                  'text',
                );
                return questionType === 'json';
              }
              return false;
            };
            return (
              <div
                key={`conversation-message-${messageId}`}
                className="my-4 flex flex-row"
              >
                <Avatar srcImage={isUserMessage ? userProfile : aiIcon} />
                <div className="flex flex-col ml-3 w-full" id={messageId}>
                  {messageType === 'json' ? (
                    <SpecialMessage specialMessage={messageData} />
                  ) : (
                    <ChatMessageDisplayCard
                      isUserMessage={isUserMessage}
                      messageText={messageBody}
                      isLastInputQues={checkIsLastInputQues()}
                      onPreCallPlannerSelect={onPreCallPlannerSelect}
                      preCallPrevSelected={preCallPrevSelected}
                      userInputQues={userInputQues}
                    />
                  )}

                  <div className="flex flex-row justify-between">
                    <DateTimeField messageCreation={messageCreation} />
                    {messageRole === 'AI' && (
                      <FeedbackPanel
                        conversationId={conversationId}
                        messageId={messageId}
                        isHelpful={isHelpful}
                        onFeedbackChange={onFeedbackChange}
                        onBookmarkChange={onBookmarkChange}
                        isBookmarked={isBookmarked}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {userInputQuesType === preCallQuesType && (
            <div className="flex flex-row">
              <Avatar srcImage={userProfile} />
              <div className="flex flex-col ml-3 w-full">
                <SpecialMessage
                  specialMessage={userInputQues}
                  isSelectionPermitted
                  onSubmit={onPreCallSelectionComplete}
                  onCancel={onCancel}
                />
                <DateTimeField messageCreation={new Date()} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
Conversation.propTypes = {
  onPreCallSelectionComplete: PropTypes.func.isRequired,
  onPreCallPlannerSelect: PropTypes.func.isRequired,
  preCallPrevSelected: JSON_QUES_TYPE.isRequired,
  sellingDivision: PropTypes.string,
};

export default Conversation;
