import React, { useState, useRef, useCallback } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { userProfile, dropdown } from '../../assets/icons';
import Avatar from '../../components/Avatar/Avatar';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import authFactory from '../../utility/auth/authFactory';

/**
 * The `Profile` function is a React component that renders the profile section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useState` hook to keep track of the dropdown state.
 * The `useCallback` hook is used to memoize the functions that handle the dropdown open and close.
 * The `useRef` hook is used to get the reference of the dropdown.
 * The `useOnClickOutside` hook is used to close the dropdown when the user clicks outside of the dropdown.
 * @returns The `Profile` function returns a React component.
 */
function Profile() {
  const { t } = useTranslation('common');

  const auth = authFactory();
  const authProfile = auth.getProfile();
  const userName = get(authProfile, 'given_name', '');
  const familyName = get(authProfile, 'family_name', '');
  const repId = get(authProfile, 'cn', '');
  const [isDropdown, setIsDropdown] = useState(false);

  const onDropdown = useCallback(() => {
    setIsDropdown(true);
  }, [setIsDropdown]);

  const onCloseDropdown = useCallback(() => {
    setIsDropdown(false);
  }, [setIsDropdown]);

  const onLogout = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('isLoggedOut', 'true');

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    auth.logout();
  }, [auth]);

  const ref = useRef();
  useOnClickOutside(ref, onCloseDropdown);

  const deploymentPlatform = `${process.env.REACT_APP_DEPLOYMENT_PLATFORM}`;

  return (
    <div className="justify-end bg-secondary-500 mt-2 p-2 w-full">
      <div className="flex flex-row items-center w-full justify-between">
        <div className="flex flex-row items-center justify-between">
          <Avatar srcImage={userProfile} />
          <div className="flex flex-col sm:max-w-10 md:max-w-10 lg:max-w-20 truncate">
            <h3>
              {userName}&nbsp;{familyName}
            </h3>
            <p>{repId}</p>
          </div>
        </div>
        {deploymentPlatform === 'microSite' && (
          <div className="relative justify-end cursor-pointer">
            <img
              src={dropdown}
              onClick={onDropdown}
              alt="overflow"
              data-testid="overflow"
            />
            {isDropdown && (
              <div
                className="absolute bg-white p-2 -top-2 left-5"
                onClick={onLogout}
                ref={ref}
              >
                {t('common.logout')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
