import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * The GreetUser function is a React component that displays a greeting message with the user's name.
 * @returns a JSX element. It is a div element with a class name of "p-3" and contains a paragraph
 * element with a class name of "text-5xl" that says "Hello {userName}!" The JSX element also includes
 * a horizontal line element with a class name of "border border-secondary-800 my-2".
 */
function GreetUser({ userName }) {
  const { t } = useTranslation('common');
  return (
    <div className="p-5">
      <h1>
        {t('common.greeting')} {userName}!
      </h1>
      <hr className="border border-secondary-800" />
    </div>
  );
}

GreetUser.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default GreetUser;
