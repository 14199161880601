import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import ConversationContext from '../../context/Conversation.context';
import { useChatsData, useDeleteMessage } from '../../httpRequestHooks';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error';
import { aiIcon, userProfile } from '../../assets/icons';
import Avatar from '../../components/Avatar/Avatar';
import RepAccessChatMessageDisplayCard from '../../components/RepAccessChatMessageDisplayCard/RepAccessChatMessageDisplayCard';
import pencil from '../../assets/icons/pencil.svg';
import { repAccessAnswer } from '../../utility/fetchRepAccessAnswers';
import WarningModal from '../../components/WarningModal/WarningModal';

/**
 * The `Conversation` function is a React component that renders the conversation section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useAddFeedback` hook is used to add the feedback.
 * The `useAddBookmark` hook is used to add the bookmark.
 * The `useFetchBookmarks` hook is used to fetch the bookmarks.
 * The `editResponse` function handles when a user tries to edit the conversation. It also decides when to show the warning modal depending on the values of `lastUserMessage` and `confirmWarning`.
 * The `Conversation` component renders the `Loader` component if the chats data is loading.
 * The `Conversation` component renders the `Error` component if the chats data is errored.
 * The `Conversation` component renders the `DefaultAIMessage` component if the chats data is success and the chats data is empty.
 * The `Conversation` component renders the `ChatMessageDisplayCard` component for each message in the chats data.
 * The `Conversation` component renders the `FeedbackPanel` component for each message in the chats data if the message role is AI.
 * The `Conversation` component renders the `WarningModal` component which is displayed when a user edits the conversation on a leaf node.
 */
function Conversation() {
  const checkForPreviousMessage = 2;
  const { conversationId } = useContext(ConversationContext);
  const [lastUserMessage, setLastUserMessage] = useState('');
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const {
    data: allChatsData,
    isLoading: isChatDataLoading,
    isSuccess: isChatDataSuccess,
    refetch: refetchChatData,
    error: chatError,
    isError: isChatError,
  } = useChatsData(conversationId);

  const { mutateAsync: deleteMessage } = useDeleteMessage();

  const editResponse = async (confirmWarning = false) => {
    if (lastUserMessage.message.body === 'Select the HCP.' && !confirmWarning) {
      setShowWarningMessage(true);
    } else {
      const totalMessages = allChatsData.length;
      const deleteMessagesCount = 2;

      const deletePromises = [];
      for (let index = 1; index <= deleteMessagesCount; index += 1) {
        const message = allChatsData[totalMessages - index];
        const messageId = get(message, 'messageId', null);
        const currentConversationId = get(message, 'conversationId', null);

        deletePromises.push(
          deleteMessage({ messageId, conversationId: currentConversationId }),
        );
      }
      await Promise.all(deletePromises);
      refetchChatData();
    }
  };

  const handleConfirm = async () => {
    setShowWarningMessage(false);
    editResponse(true);
  };

  const handleDeny = async () => {
    setShowWarningMessage(false);
  };

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length !== 0) {
      const lastUserMessageData = allChatsData.findLast(
        chatData => chatData.role.toLowerCase() === 'user',
      );
      setLastUserMessage(lastUserMessageData);
    }
  }, [allChatsData]);

  const isEditIconVisible =
    Array.isArray(allChatsData) &&
    allChatsData[allChatsData.length - 1]?.role === 'AI';
  const getAvatarImage = isUserMessage =>
    isUserMessage ? userProfile : aiIcon;
  return (
    <>
      {isChatDataLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isChatError && <Error error={chatError} />}
      {isChatDataSuccess && (
        <div
          className="flex flex-col my-4 flex-1 justify-end"
          data-testid="rep-access-conversation"
        >
          {allChatsData.map((message, index) => {
            const messageRole = get(message, 'role', 'AI');
            const isUserMessage = messageRole === 'USER';
            const messageId = get(message, 'messageId', null);
            const currentAIQuestion = get(
              message,
              'messageMetadata.additional_context',
              null,
            );
            const messageCreatedAt = get(message, 'createdAt', null);
            const nextAIQuestion =
              index !== allChatsData.length - 1
                ? allChatsData[index + 1].message
                : {};
            const messageBody = get(message, 'message', null);
            const oldAIQuestion =
              index >= checkForPreviousMessage
                ? allChatsData?.[index - 1]?.messageMetadata?.additional_context
                : {};
            let chatDialogContent = '';
            if (isUserMessage && messageBody?.type === 'json') {
              const answer = oldAIQuestion?.[0]?.next_question?.questionkey;
              const answerKey =
                messageBody?.jsonContext?.access_context?.[answer];
              chatDialogContent = repAccessAnswer(
                answerKey,
                oldAIQuestion,
              )?.optionexplanation;
            }

            return (
              <div
                key={`conversation-message-${messageId}`}
                className={classnames('flex flex-col', {
                  hidden: index === 0 || (isUserMessage && !chatDialogContent),
                })}
              >
                <div className="my-4 flex flex-row">
                  <Avatar srcImage={getAvatarImage(isUserMessage)} />
                  <div className="flex flex-col ml-3 w-full" id={messageId}>
                    <RepAccessChatMessageDisplayCard
                      isUserMessage={isUserMessage}
                      currentAIQuestion={currentAIQuestion}
                      oldAnswerContext={
                        allChatsData?.[index - 1]?.message?.type === 'json'
                          ? allChatsData?.[index - 1]?.message
                          : { jsonContext: { access_context: {} } }
                      }
                      isActive={index === allChatsData.length - 1}
                      nextAIQuestion={nextAIQuestion}
                      chatDialogContent={chatDialogContent}
                      messageBody={messageBody}
                      messageCreatedAt={messageCreatedAt}
                    />
                  </div>
                </div>
                {lastUserMessage?.messageId === messageId && (
                  <div className="w-full flex justify-end">
                    {isEditIconVisible && (
                      <div
                        className="cursor-pointer bg-secondary-100 max-w-[32px] min-w-[32px] min-h-[32px] rounded-full flex justify-center items-center"
                        onClick={() => editResponse()}
                      >
                        <img src={pencil} alt="edit" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <WarningModal
        isModalOpen={showWarningMessage}
        handleConfirm={handleConfirm}
        handleDeny={handleDeny}
      />
    </>
  );
}

export default Conversation;
