import React, { useEffect, useState, useContext, useCallback } from 'react';
import { get, last } from 'lodash';
import ChatInput from './ChatInput.subsection';
import Conversation from './Conversation.subSection';
import {
  useChatsData,
  useAddUserMessage,
  useEditConversation,
  useFetchConversations,
  useFeedbackQuestions,
} from '../../httpRequestHooks';

import PollingSubSection from './Polling.subSection';
import ConversationContext from '../../context/Conversation.context';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';
import useAutoScroll from '../../hooks/useAutoScroll';

/**
 * The `GenericChat` function is a React component that renders the generic chat section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useEffect` hook is used to refetch the chats data.
 * The `useEffect` hook is used to refetch the feedback questions.
 * The `useEffect` hook is used to set the last conversation role and input disabled effect.
 * @returns The `GenericChat` function returns a React component.
 * @param {object} props The `GenericChat` function does not take in any props.
 */
function GenericChat() {
  const { conversationId } = useContext(ConversationContext);

  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [lastConversationRole, setLastConversationRole] = useState(null);
  const { mutateAsync: addChatDataAsync } = useAddUserMessage();

  const {
    data: allChatsData,
    dataUpdatedAt: allChatsDataTs,
    refetch: refetchChatData,
  } = useChatsData(conversationId);

  useEffect(() => {
    refetchChatData();
  }, [conversationId]);

  const { mutateAsync: editConversation } = useEditConversation();
  const { refetch: refetchConversations } = useFetchConversations({
    conversationType: CONVERSATION_TYPE.GENERIC,
  });

  const scrollToBottom = useAutoScroll('conversationArea');

  const onPostMessage = useCallback(
    async (userMessage, questionId = null) => {
      if (lastConversationRole === 'USER') return;
      if (Array.isArray(allChatsData) && allChatsData.length === 0) {
        await editConversation({
          title: userMessage,
          conversationId,
        });
        refetchConversations();
      }
      setIsInputDisabled(true);

      await addChatDataAsync({
        conversationId,
        message: userMessage,
        questionId,
      });
      scrollToBottom();

      refetchChatData();
    },
    [conversationId, lastConversationRole, allChatsData],
  );

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length !== 0) {
      const lastMessageData = last(allChatsData);
      const lastMessageRole = get(lastMessageData, 'role', null);
      const isLastUserRole = lastMessageRole === 'USER';
      setLastConversationRole(lastMessageRole);
      setIsInputDisabled(isLastUserRole);
    }
  }, [allChatsData, allChatsDataTs]);

  const { refetch: fetchFeedbackQuestions } = useFeedbackQuestions();

  useEffect(() => {
    fetchFeedbackQuestions();
  }, []);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-1 flex flex-col flex-col-reverse overflow-y-auto justify-end mb-4">
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div
            className="flex flex-col flex-1 flex-col-reverse overflow-y-auto"
            id="conversationArea"
          >
            <Conversation key={conversationId} />
          </div>
          {Array.isArray(allChatsData) && allChatsData.length !== 0 && (
            <PollingSubSection
              key={allChatsData.length}
              isProcessed={lastConversationRole === 'AI'}
            />
          )}
        </div>
      </div>
      <div>
        <ChatInput
          key={conversationId + lastConversationRole}
          onPostMessage={onPostMessage}
          isInputDisabled={isInputDisabled}
        />
      </div>
    </div>
  );
}

export default GenericChat;
