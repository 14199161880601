import React from 'react';
import Loader from '../../components/Loader/Loader';

/**
 * The Login function returns a React component that displays a loader and a message indicating that
 * authentication is in progress.
 * @returns The Login component is returning a div element with the class name "flex flex-col
 * items-center justify-center h-screen bg-white px-10". Inside the div, there is a Loader component
 * and an h1 element with the text "Authenticating...".
 */
function Login() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-10">
      <Loader className="mb-8" />
      <h1>Authenticating...</h1>
    </div>
  );
}

export default Login;
