import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import UserModal from '../../components/UserModal/UserModal';

/**
 * The `SwitchUser` function is a React component that renders the switch user section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useState` hook to set the user modal.
 */
function SwitchUser() {
  const [isUserModal, setIsUserModal] = useState(false);
  const { t } = useTranslation('common');

  const onCloseModal = useCallback(() => {
    setIsUserModal(false);
  }, []);
  const onOpenModal = useCallback(() => {
    setIsUserModal(true);
  }, []);

  const currentRepId = localStorage.getItem('REP_ID');
  return (
    <>
      <div className="flex flex-col self-end mt-2">
        <Button content={t('button.switchUser')} onClick={onOpenModal} />
      </div>
      <p className="self-end">{currentRepId}</p>
      <UserModal closeModal={onCloseModal} isModalOpen={isUserModal} />
    </>
  );
}
export default SwitchUser;
