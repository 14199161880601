import PropTypes from 'prop-types';

const JSON_QUES_TYPE = PropTypes.shape({
  behaviors: PropTypes.arrayOf(
    PropTypes.shape({
      behaviorType: PropTypes.string,
    }),
  ),
  questionType: PropTypes.string,
  criteria: PropTypes.string,
});

export default JSON_QUES_TYPE;
