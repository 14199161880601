import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { APPLICATION_VERSION } from '../constants/appVersion.constant';
import showFeature from '../utility/showFeature';

/**
 * The function `usePollConversation` is a custom hook that uses the `useQuery` hook from a library
 * called `react-query` to fetch polling data for a conversation.
 * @returns The `usePollConversation` function is returning a custom hook that can be used to fetch
 * polling data for a conversation.
 */
const fetchPollingData = conversationId => {
  const thresholdTime = localStorage.getItem('POLLING_THRESHOLD');
  const isDev = showFeature([APPLICATION_VERSION.ENV.DEV]);

  const isThresholdNumber = !Number.isNaN(Number(thresholdTime));
  const timeoutThreshold = isThresholdNumber && isDev ? thresholdTime : null;
  const pollConversationURL = `poll/${conversationId}`;
  const params = { timeoutThreshold };
  return axios.get(pollConversationURL, { params }).then(pollData => {
    const payloadPath = 'data.data.processed';
    return get(pollData, payloadPath, false);
  });
};

const usePollConversation = conversationId => {
  const queryKey = ['poll-conversation', conversationId];
  const queryFn = () => fetchPollingData(conversationId);
  const queryConfig = { enabled: false };
  return useQuery(queryKey, queryFn, queryConfig);
};

export default usePollConversation;
