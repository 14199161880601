/* eslint-disable no-console */
import authFactory from '../auth/authFactory';
import AxiosHttpError from '../errors';

/**
 * The function is an interceptor for successful requests in JavaScript that adds headers and modifies
 * the URL.
 * @returns The successRequestInterceptor function returns an object with the updated config object.
 */
export const successRequestInterceptor = config => {
  const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

  if (config.url.startsWith('http')) {
    throw new AxiosHttpError('INVALID URL PATH');
  }

  const isSwitchUserEnabled = `${process.env.REACT_APP_ENABLE_USER_SWITCH}`;

  const mockRepId = localStorage.getItem('REP_ID');
  const repOverride = isSwitchUserEnabled === 'enabled' ? mockRepId : null;

  const auth = authFactory();
  const accessToken = auth.getCurrentAccessToken();

  return {
    ...config,
    url: `${baseUrl}/${config.url}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Rep-Id': repOverride,
    },
  };
};

export const errorRequestInterceptor = error => Promise.reject(error);
