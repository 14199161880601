import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import POLLING_TIME from '../../constants/pollingDuration.constant';
import useInterval from '../../hooks/useInterval';
import { useChatsData, usePollConversation } from '../../httpRequestHooks';
import ConversationContext from '../../context/Conversation.context';

/**
 * The `Polling` function is a React component that renders the polling section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `usePollConversation` hook is used to poll the conversation.
 * The `useInterval` hook is used to poll the conversation.
 * The `useEffect` hook is used to refetch the chats data.
 * The `useEffect` hook is used to set the is processed effect.
 * @returns The `Polling` function returns a React component.
 */
function Polling(props) {
  const { isProcessed: isProcessedInitial } = props;
  const { debugMode } = props;
  const { conversationId } = useContext(ConversationContext);
  const { t } = useTranslation('common');

  const [isProcessed, setIsProcessed] = useState(isProcessedInitial);
  const { refetch: refetchChatData } = useChatsData(conversationId);
  const {
    refetch: pollConversation,
    data: isProcessedResponse,
    dataUpdatedAt: isProcessedResponseTs,
  } = usePollConversation(conversationId);

  useInterval(
    async () => pollConversation(),
    !isProcessed && conversationId ? POLLING_TIME : null,
  );

  useEffect(() => {
    setIsProcessed(isProcessedResponse);
    if (isProcessedResponse) refetchChatData();
  }, [isProcessedResponse, isProcessedResponseTs]);

  useEffect(() => {
    setIsProcessed(isProcessedInitial);
  }, [isProcessedInitial]);

  return (
    <>
      {debugMode && (
        <div>
          <p>
            isProcessedInitial:{' '}
            {isProcessedInitial ? t('common.true') : t('common.false')}
          </p>
          <p>
            isProcessedResponse:{' '}
            {isProcessedResponse ? t('common.true') : t('common.false')}
          </p>
          <p>
            isProcessed: {isProcessed ? t('common.true') : t('common.false')}
          </p>
        </div>
      )}
      {!isProcessed && (
        <div className="flex flex-col items-center">
          <Loader className="flex flex-col items-center" />
        </div>
      )}
    </>
  );
}

Polling.propTypes = {
  isProcessed: PropTypes.bool.isRequired,
  debugMode: PropTypes.bool,
};

Polling.defaultProps = {
  debugMode: false,
};

export default Polling;
