import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import GreetUser from '../../components/GreetUser/GreetUser';
import ErrorFallbackComponent from '../../components/Error/ErrorFallbackComponent';
import ChatHistoryPanel from '../../sections/ChatHistoryPanel/ChatHistoryPanel.section';
import GenericChat from '../../sections/GenericChat/GenericChat.section';
import HCPChat from '../../sections/HCPChat/HCPChat.section';
import BookmarkSummary from '../../sections/BookmarkSummary/BookmarkSummary.section';
import ConversationContext from '../../context/Conversation.context';
import ConversationSelection from '../../sections/ConversationSelection/ConversationSelection.section';
import SwitchUser from '../../sections/SwitchUser/SwitchUser';
import { useFetchRepData } from '../../httpRequestHooks';
import UsageGuidelines from '../../components/UsageGuidelines/UsageGuidelines';
import authFactory from '../../utility/auth/authFactory';
import DisclaimerCard from '../../components/DisclaimerCard/DisclaimerCard';

/**
 * The `Copilot` component is a React component that renders the main page of the application.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useState` hook to keep track of the current conversation and selected HCP.
 * The `useMemo` hook is used to memoize the context value.
 * The `useEffect` hook is used to fetch the rep data and set the rep user flag.
 * The `useEffect` hook is used to set the viewport height.
 * The `useCallback` hook is used to memoize the functions that handle the modal open and close.
 * The `useCallback` hook is used to memoize the function that handles the view guidelines click.
 * @returns The `Copilot` component is being returned.
 * @param {object} props The `Copilot` component does not take in any props.
 * The `Copilot` component renders the main page of the application.
 * It renders the chat history panel, the chat panel, and the bookmark summary.
 * It also renders the conversation selection panel if there is no conversation or selected HCP.
 * It renders the HCP chat if there is a selected HCP.
 * It renders the generic chat if there is a conversation.
 */
function Copilot({ setConversationTool }) {
  const { t } = useTranslation('common');

  const [conversationId, setConversationId] = useState('');
  const [selectedHcp, setSelectedHcp] = useState(null);
  const [isHcpConversation, setIsHcpConversation] = useState(null);
  const [userInputQues, setUserInputQues] = useState(null);
  const [sellingDivision] = useState('Darzalex');

  const contextValue = useMemo(
    () => ({
      conversationId,
      setConversationId,
      selectedHcp,
      setSelectedHcp,
      isHcpConversation,
      setIsHcpConversation,
      userInputQues,
      setUserInputQues,
    }),
    [conversationId, selectedHcp, isHcpConversation, userInputQues],
  );

  const getUserName = () => {
    const auth = authFactory();
    const pingIdProfile = auth.getProfile();
    return get(pingIdProfile, 'given_name', '');
  };

  const userName = getUserName();

  const { refetch: refetchRepsData } = useFetchRepData();

  useEffect(() => {
    refetchRepsData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onViewGuidelines = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const auth = authFactory();
  const switchUserAccess = auth.getSwitchUser() || 'disabled';
  const isSwitchUserEnabled = `${process.env.REACT_APP_ENABLE_USER_SWITCH}`;
  const copyrightYear = new Date().getFullYear();

  return (
    <ConversationContext.Provider value={contextValue}>
      <div className="flex flex-row h-full">
        <div className="w-2.5/12 p-4 bg-secondary-100 h-full">
          <div className="rounded h-full">
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ChatHistoryPanel setConversationTool={setConversationTool} />
            </ErrorBoundary>
          </div>
        </div>
        <div className="w-7/12 p-5 h-full">
          <div className="flex flex-col bg-white-500 h-full">
            <div className="flex flex-col h-full">
              {!conversationId && !selectedHcp ? (
                <>
                  <div className="flex-1 overflow-y-auto">
                    <DisclaimerCard userName={userName} />
                  </div>
                  <div className="justify-end">
                    <ConversationSelection />
                  </div>
                </>
              ) : (
                <>
                  <GreetUser userName={userName} />
                  <div className="flex flex-col overflow-y-auto flex-1">
                    {isHcpConversation ? (
                      <HCPChat
                        key={selectedHcp}
                        sellingDivision={sellingDivision}
                      />
                    ) : (
                      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                        <GenericChat key={conversationId} />
                      </ErrorBoundary>
                    )}
                  </div>
                </>
              )}

              <span className="self-center mt-2">
                {t('copilotLandingPage.disclaimer')} &nbsp;
                <span
                  className="text-textColor-link underline cursor-pointer"
                  onClick={onViewGuidelines}
                  data-testid="guidelines"
                >
                  {t('copilotLandingPage.viewUserGuidelineLink')}
                </span>
                <UsageGuidelines
                  closeModal={onModalClose}
                  isModalOpen={isModalOpen}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-2.5/12 h-full p-4 bg-secondary-100">
          <BookmarkSummary />
          {isSwitchUserEnabled === 'enabled' &&
            switchUserAccess === 'enabled' && <SwitchUser />}
          <hr className="border-secondary-800 mb-1 mt-3" />
          <span className="m-1 self-end">
            {t('copilotLandingPage.copyright', { year: copyrightYear })}
          </span>
        </div>
      </div>
    </ConversationContext.Provider>
  );
}

Copilot.propTypes = {
  setConversationTool: PropTypes.func.isRequired,
};

export default Copilot;
