import authProvider from './AuthProvider/AuthProvider';
import pingIdInstance from './PindId/PingId';
import veevaInstance from './Veeva/Veeva';
import noAuthInstance from './NoAuth/NoAuth';

const authFactory = () => {
  switch (`${process.env.REACT_APP_DEPLOYMENT_PLATFORM}`) {
    case 'microSite':
      return pingIdInstance;
    case 'veeva':
      return veevaInstance;
    case 'noAuth':
      return noAuthInstance;
    default:
      return authProvider;
  }
};
export default authFactory;
