/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AxiosHttpError from '../../utility/errors';
import { FALLBACK_ERROR_MESSAGE } from '../../constants/clientError.constant';
import SafeHtmlElements from '../SafeComponents';
import { NOT_FOUND_ERROR_CODE } from '../../constants/errorCodes.constant';

/**
 * The Error component is a React component that displays an error message based on the provided props.
 * @returns The Error component is returning a div element with the className "m-2 text-gray-400" and
 * the errorNode as its content. The errorNode is determined based on the conditions in the code. If
 * the error is an instance of AxiosHttpError and has a statusCode of NOT_FOUND_ERROR_CODE, and a
 * notFoundPreview is provided, then the notFoundPreview is returned. Otherwise, a
 */
function Error(props) {
  const { errorMessage, error, notFoundPreview } = props;
  const errorMessageDisplay = error?.errorMessage;
  if (
    error instanceof AxiosHttpError &&
    error.statusCode === NOT_FOUND_ERROR_CODE &&
    notFoundPreview
  ) {
    return notFoundPreview;
  }
  let errorNode = (
    <SafeHtmlElements tagName="p" placeholder={errorMessage}>
      {errorMessageDisplay}
    </SafeHtmlElements>
  );

  if (error instanceof AxiosHttpError) {
    errorNode = (
      <SafeHtmlElements tagName="p">{errorMessageDisplay}</SafeHtmlElements>
    );
  }

  return <div className="m-2 text-gray-400">{errorNode}</div>;
}

Error.propTypes = {
  errorMessage: PropTypes.string,
  error: PropTypes.object.isRequired,
  notFoundPreview: PropTypes.node,
};

Error.defaultProps = {
  errorMessage: FALLBACK_ERROR_MESSAGE,
  notFoundPreview: null,
};
export default Error;
