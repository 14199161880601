import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The `useFetchHCP` function is a custom hook that uses the `useQuery` hook from a library called
 * `react-query` to fetch data from an API endpoint and return the result.
 * @param search - The `search` parameter is used to specify the search query for fetching HCP
 * (Healthcare Professional) data. It is a string that represents the search term or criteria to filter
 * the HCP data.
 * @param limit - The `limit` parameter is used to specify the maximum number of results to be returned
 * in a single request. It determines the number of items to be fetched from the API at a time.
 * @param offset - The offset parameter is used to specify the starting index of the data to be
 * fetched. It determines how many items should be skipped before starting to fetch the data.
 * @returns The `useFetchHCP` function is returning the result of the `useQuery` hook.
 */
const fetchHCPData = (search, limit, offset) => {
  const fetchHcpURL = 'hcp';
  const params = {
    search,
    limit,
    offset,
  };
  return axios.get(fetchHcpURL, { params }).then(hcp => {
    const payloadPath = 'data.data';
    return get(hcp, payloadPath, []);
  });
};

const useFetchHCP = payload => {
  const { search, limit, offset } = payload;
  const queryKey = ['get HCP', search];
  const queryConfig = {
    enabled: false,
    keepPreviousData: true,
  };
  const queryFn = () => fetchHCPData(search, limit, offset);

  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFetchHCP;
