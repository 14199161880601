import React, { useEffect, useState, useContext } from 'react';
import { get, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useAddUserMessage,
  useChatsData,
  useAddConversation,
} from '../../httpRequestHooks';
import PollingSubSection from './Polling.subsection';
import ConversationContext from '../../context/Conversation.context';
import Conversation from './Conversation.subSection';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';

/**
 * The `RepAccessConversationSection` function is a React component that renders the HCP chat section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useEffect` hook is used to refetch the chats data.
 * The `useEffect` hook is used to refetch the feedback questions.
 * The `useEffect` hook is used to set the last conversation role and input disabled effect.
 * @returns The `RepAccessConversationSection` function returns a React component.
 * @category Components
 * @example <caption>Example usage of RepAccessConversationSection.</caption>
 */
function RepAccessConversationSection() {
  const { conversationId, currentChat, setConversationId } =
    useContext(ConversationContext);

  const [lastConversationRole, setLastConversationRole] = useState(null);
  const { mutateAsync: addConversationAsync } = useAddConversation();

  const onError = async conversationError => {
    const badRequestStatusCode = 400;
    if (conversationError.statusCode === badRequestStatusCode) {
      const conversation = await addConversationAsync({
        conversationTitle: currentChat,
        conversationType: CONVERSATION_TYPE.REP_ACCESS,
      });
      setConversationId(conversation.conversationId);
    }
  };

  const { data: allChatsData, refetch: refetchChatData } = useChatsData(
    conversationId,
    onError,
  );

  const { t } = useTranslation('common');

  const { mutateAsync: addUserMessageAsync } = useAddUserMessage(onError);

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length === 0) {
      const bodyType =
        currentChat === 'rep-access'
          ? t('repAccessLandingPage.conversationSection.section1.heading')
          : t('repAccessLandingPage.conversationSection.section2.heading');
      addUserMessageAsync({
        conversationId,
        message: {
          body: bodyType,
          jsonContext: {
            app: currentChat,
            access_context: {},
          },
          questionType: currentChat,
        },
        messageType: 'text',
        questionId: currentChat,
      })?.then(() => refetchChatData());
    }
  }, [allChatsData]);

  useEffect(() => {
    if (conversationId) refetchChatData();
  }, [conversationId]);

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length !== 0) {
      const lastMessageData = last(allChatsData);
      const lastMessageRole = get(lastMessageData, 'role', null);
      setLastConversationRole(lastMessageRole);
    }
  }, [allChatsData]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-1 flex flex-col flex-col-reverse overflow-y-auto justify-end mb-4">
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div
            className="flex flex-col flex-1 flex-col-reverse overflow-y-auto"
            id="conversationArea"
            data-testid="conversation-area"
          >
            <Conversation key={conversationId} />
          </div>
          {Array.isArray(allChatsData) && allChatsData.length !== 0 && (
            <PollingSubSection
              key={allChatsData.length}
              isProcessed={lastConversationRole === 'AI'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RepAccessConversationSection;
