import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * The `BookmarkCard` component is a React component that displays a bookmarked message and allows the
 * user to view its details.
 * @returns The BookmarkCard component is being returned.
 */
function BookmarkCard(props) {
  const { bookMarkedMessage, messageId } = props;
  const { onClickViewDetails, currentConversationId } = props;
  const { t } = useTranslation('common');

  return (
    <div className="bookmark-card shadow-lg flex flex-col w-full p-2 rounded bg-white mb-4">
      <div className="flex-grow pb-4">
        <p
          dangerouslySetInnerHTML={{ __html: bookMarkedMessage }}
          className="line-clamp-4"
        />
      </div>
      <p
        className="text-textColor-blue cursor-pointer"
        onClick={() => onClickViewDetails(messageId, currentConversationId)}
        data-testid="bookmark-card"
      >
        {t('bookmarkCard.seeDetails')}
      </p>
    </div>
  );
}

BookmarkCard.propTypes = {
  bookMarkedMessage: PropTypes.string.isRequired,
  onClickViewDetails: PropTypes.func.isRequired,
  currentConversationId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default BookmarkCard;
