import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import InputChat from '../../components/InputChat/InputChat';
import SuggestedQuesCard from '../../components/QuestionCard/QuestionCard';
import { useFetchQues } from '../../httpRequestHooks';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error';
import ConversationContext from '../../context/Conversation.context';

/**
 * The `ChatInput` function is a React component that renders the chat input section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the selected hcp.
 * The `useFetchQues` hook is used to fetch the questions.
 * The `useEffect` hook is used to fetch the questions.
 * @returns The `ChatInput` function returns a React component.
 * The `ChatInput` function renders the chat input section.
 */
function ChatInput(props) {
  const { onPostMessage, isInputDisabled } = props;
  const { selectedHcp, userInputQues } = useContext(ConversationContext);
  const hcpFirstName = get(selectedHcp, 'hcpFirstName', null);
  const hcpLastName = get(selectedHcp, 'hcpLastName', null);

  const label = 'HCP';
  const {
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess,
    isError: isQuestionsError,
    data: questionsData,
    error: questionsError,
    refetch: fetchQuestions,
  } = useFetchQues({ label });

  useEffect(() => {
    fetchQuestions();
  }, []);

  const onQuestionSelect = useCallback(
    (question, questionId) => {
      onPostMessage(question, questionId);
    },
    [onPostMessage],
  );

  return (
    <div className="p-5 border border-secondary-500 w-full">
      <InputChat
        onSubmit={onQuestionSelect}
        isInputDisabled={isInputDisabled}
      />

      <div className="flex flex-row overflow-x-auto mt-3">
        {isQuestionsLoading && (
          <div className="flex flex-col items-center w-full">
            <Loader />
          </div>
        )}
        {isQuestionsError && <Error error={questionsError} />}
        {isQuestionsSuccess && !userInputQues && (
          <>
            {questionsData.map(questionItem => {
              const questionText = get(questionItem, 'question', '');
              const questionId = get(questionItem, 'questionId', null);
              const hcpQuestion = questionText.replace(
                /HCP_NAME/g,
                `${hcpFirstName} ${hcpLastName}`,
              );
              return (
                <SuggestedQuesCard
                  key={`suggested-questions-${questionId}`}
                  questionId={questionId}
                  questionText={hcpQuestion}
                  onClick={onQuestionSelect}
                  isInputDisabled={isInputDisabled}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

ChatInput.propTypes = {
  onPostMessage: PropTypes.func.isRequired,
  isInputDisabled: PropTypes.bool.isRequired,
};

export default ChatInput;
