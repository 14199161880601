export class AuthProvider {
  #isLogin = false;

  #loginStatusCallback = null;

  #isRefreshing = false;

  constructor() {
    this.isLogin = false;
  }

  get isLogin() {
    return this.#isLogin;
  }

  set isLogin(value) {
    if (typeof this.#loginStatusCallback === 'function') {
      this.#loginStatusCallback(value);
    }
    this.#isLogin = value;
  }

  get isRefreshing() {
    return this.#isRefreshing;
  }

  set isRefreshing(value) {
    this.#isRefreshing = value;
  }

  addLoginCallback(callbackFunction) {
    this.#loginStatusCallback = callbackFunction;
  }

  getCurrentAccessToken() {
    throw new Error('Method not implemented');
  }

  getProfile() {
    throw new Error('Method not implemented');
  }

  getScope() {
    throw new Error('Method not implemented');
  }

  getSwitchUser() {
    throw new Error('Method not implemented');
  }

  logout() {
    throw new Error('Method not implemented');
  }

  refetchToken() {
    throw new Error('Method not implemented');
  }

  async authenticate() {
    throw new Error('Method not implemented');
  }
}
const AuthProviderInstance = new AuthProvider();
export default AuthProviderInstance;
