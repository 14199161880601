const toastConfig = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  className: 'w-56 h-10 overlay-toast absolute z-50',
};

export default toastConfig;
