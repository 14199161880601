/* eslint-disable no-console */

import showFeature from '../utility/showFeature';
/**
 * @description
 * This file is used to display the application version in the console.
 * It is also used to set the environment variables.
 * @example
 * import APPLICATION_VERSION from '../constants/appVersion.constant';
 * APPLICATION_VERSION.ENV.PROD // true
 */
const VERSION_ID = '1.0.0';
const LAST_UPDATED = '2023-07-31';

const ENV = {
  DEV: 'dev',
  TEST: 'test',
  DEMO: 'demo',
  PROD: 'prod',
};

const IS_PRODUCTION = showFeature([ENV.PROD]);

export const APPLICATION_VERSION = {
  VERSION_ID,
  LAST_UPDATED,
  IS_PRODUCTION,
  ENV,
};

const displayApplicationVersion = () => {
  console.group('APPLICATION_VERSION');
  console.info('VERSION_ID:', APPLICATION_VERSION.VERSION_ID);
  console.info('LAST_UPDATED:', APPLICATION_VERSION.LAST_UPDATED);
  console.info('IS_PRODUCTION:', APPLICATION_VERSION.IS_PRODUCTION);
  console.groupEnd();
};

export default displayApplicationVersion;
