import { useEffect } from 'react';

/**
 * The `useOnClickOutside` function is a custom hook in JavaScript that allows you to detect when a
 * user clicks outside of a specified element.
 * @param ref - The ref parameter is a reference to the DOM element that you want to detect clicks
 * outside of. It is typically created using the useRef() hook.
 * @param handler - The `handler` parameter is a function that will be called when a click event occurs
 * outside of the element referenced by the `ref` parameter.
 */
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, []);
}

export default useOnClickOutside;
