import axios from 'axios';
import { useMutation } from 'react-query';

/**
 * The `useDeleteConversation` function is a custom hook that deletes a conversation using the
 * `deleteConversation` function and triggers an `onSuccess` callback when the deletion is successful.
 * @returns The `useDeleteConversation` function is returning a mutation function that can be used to
 * delete a conversation.
 */
const deleteConversation = conversationId => {
  const deleteConversationURL = `conversation/${conversationId}`;
  return axios.delete(deleteConversationURL);
};

const useDeleteConversation = onSuccess => {
  const mutationFn = payload => {
    const { conversationId } = payload;
    return deleteConversation(conversationId);
  };
  return useMutation(mutationFn, { onSuccess });
};

export default useDeleteConversation;
