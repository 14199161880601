import axios from 'axios';
import {
  errorRequestInterceptor,
  successRequestInterceptor,
} from './requestInterceptor';
import {
  errorResponseInterceptor,
  successResponseInterceptor,
} from './responseInterceptor';

/**
 * The `setupAxios` function sets up request and response interceptors for the Axios library in
 * JavaScript.
 */
const setupAxios = () => {
  if (axios.interceptors.request.handlers.length === 0) {
    axios.interceptors.request.use(
      successRequestInterceptor,
      errorRequestInterceptor,
    );
    axios.interceptors.response.use(
      successResponseInterceptor,
      errorResponseInterceptor,
    );
  }
};

export default setupAxios;
