import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The function `useFetchRepData` is a custom hook that uses the `useQuery` hook to fetch
 * representative data from an API.
 * @returns The `useFetchRepData` function is returning the result of calling the `useQuery` hook with
 * the provided `queryKey`, `queryFn`, and `queryConfig` parameters.
 */
const fetchRepData = () => {
  const fetchQuesURL = 'reps';
  return axios.get(fetchQuesURL).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFetchRepData = () => {
  const queryKey = 'fetch rep list';
  const queryConfig = { enabled: false };
  const queryFn = fetchRepData;
  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFetchRepData;
