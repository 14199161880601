import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from '../../hooks/useOnClickOutside';

/* 
The code you provided is a 
React component called `ConversationDropdown`. 
It is a dropdown menu that
appears when a user interacts
with a conversation. 
*/
function ConversationDropdown(props) {
  const ref = useRef();
  const { t } = useTranslation('common');

  const { onDelete, onRename } = props;
  const { onCloseDropdown, position } = props;
  const { isHCPConversation } = props;
  useOnClickOutside(ref, onCloseDropdown);

  const { top, left } = position;

  return (
    <div
      className="absolute right-4 bg-white bg-opacity-100 flex flex-col w-fit h-fit shadow-2xl"
      style={{
        top,
        left,
      }}
      ref={ref}
    >
      {!isHCPConversation && (
        <>
          <div data-testid="rename" onClick={onRename} className="p-3">
            {t('conversationDropdown.rename')}
          </div>
          <hr className="border-secondary-500 mx-3" />
        </>
      )}
      <div data-testid="archive" onClick={onDelete} className="p-3">
        {t('conversationDropdown.archive')}
      </div>
    </div>
  );
}

ConversationDropdown.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onCloseDropdown: PropTypes.func.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  isHCPConversation: PropTypes.bool,
};
ConversationDropdown.defaultProps = {
  position: {
    top: 0,
    left: 0,
  },
  isHCPConversation: false,
};

export default ConversationDropdown;
