import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The function `useChatsData` is a custom hook that fetches chat data for a given conversation ID
 * using the `fetchChatsData` function and returns a `useQuery` hook.
 * @returns The `useChatsData` function is returning the result of the `useQuery` hook.
 */
const fetchChatsData = conversationId => {
  const fetchChatsURL = `conversation/${conversationId}/message`;
  return axios.get(fetchChatsURL).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useChatsData = (conversationId, onError) => {
  const queryKey = ['conversationMessages', conversationId];
  const queryFn = () => fetchChatsData(conversationId);
  const queryConfig = { enabled: false, onError };
  return useQuery(queryKey, queryFn, queryConfig);
};
export default useChatsData;
