import axios from 'axios';
import { useMutation } from 'react-query';

/**
 * The `useEditConversation` function is a custom hook that allows for editing a conversation title and
 * provides a mutation function to handle the API request.
 * @param conversationId - The conversationId parameter is the unique identifier of the conversation
 * that you want to edit.
 * @param title - The `title` parameter is the new title that you want to set for the conversation.
 * @returns The `useEditConversation` function is returning a custom hook that can be used to edit a
 * conversation title.
 */
const editConversation = (conversationId, title, contextMetadata) => {
  const payload = {
    title,
    contextMetadata,
  };
  const editConversationURL = `conversation/${conversationId}`;
  return axios.put(editConversationURL, payload);
};

const useEditConversation = onSuccess => {
  const mutationFn = payload => {
    const { conversationId, title, contextMetadata } = payload;
    return editConversation(conversationId, title, contextMetadata);
  };
  return useMutation(mutationFn, { onSuccess });
};

export default useEditConversation;
