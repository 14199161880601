import axios from 'axios';
import { get } from 'lodash';
import PingIdSecrets from './PingIdSecrets';

/**
 * The PingIdMiddlewareRequests class provides methods for generating and refreshing access and refresh
 * tokens using the PingId authentication service.
 */
class PingIdMiddlewareRequests {
  static generateAccessAndRefreshToken(authToken) {
    const params = {
      token: authToken,
      action: 'GET_ACCESS_TOKEN',
      redirectionUri: PingIdSecrets.redirectUri,
    };

    return axios
      .get('auth', { params })
      .then(response => get(response, 'data', null));
  }

  static reGenerateAccessAndRefreshToken(refreshToken) {
    const params = {
      token: refreshToken,
      action: 'REFRESH_ACCESS_TOKEN',
      redirectionUri: PingIdSecrets.redirectUri,
    };
    return axios
      .get('auth', { params })
      .then(response => get(response, 'data', null));
  }
}

export default PingIdMiddlewareRequests;
