import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  bookmarkIcon,
  unbookmarkIcon,
  feedback,
  selectedDislike,
  unselectedDislike,
  selectedLike,
  unselectedLike,
} from '../../assets/icons';
import FeedbackModal from './FeedbackPopup';

/** The code is defining a React
 * component called `FeedbackPanel`.
 * This component is responsible for
 * rendering a feedback panel with various
 * icons and functionality.
 * It receives several props including
 * `messageId`,
 * `conversationId`,
 * `isHelpful`,
 * `onFeedbackChange`,
 * `isBookmarked`, and
 * `onBookmarkChange`.
 */
function FeedbackPanel(props) {
  const { messageId, conversationId } = props;
  const { isHelpful, onFeedbackChange } = props;
  const { isBookmarked, onBookmarkChange } = props;

  const [isFeedbackModal, setIsFeedbackModal] = useState(false);

  const divRef = useRef(null);
  const [overlayPosition, setOverlayPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const { top, left } = divRef.current.getBoundingClientRect();
    setOverlayPosition({ top, left });
  }, [divRef, isFeedbackModal]);

  const toggleLike = useCallback(() => {
    const toggledLike = isHelpful ? null : true;
    onFeedbackChange(messageId, toggledLike);
    if (toggledLike) {
      setIsFeedbackModal(true);
    }
  }, [isHelpful, messageId]);

  const toggleDislike = useCallback(() => {
    const toggledDislike = isHelpful === false ? null : false;
    onFeedbackChange(messageId, toggledDislike);
    if (toggledDislike === false) {
      setIsFeedbackModal(true);
    }
  }, [isHelpful, messageId]);

  const toggleBookmark = useCallback(() => {
    const toggledBookmark = !isBookmarked;
    onBookmarkChange(messageId, toggledBookmark);
  }, [isBookmarked, messageId]);

  const onFeedbackResponse = useCallback(() => {
    const { top, left } = divRef.current.getBoundingClientRect();
    setOverlayPosition({ top, left });
    setIsFeedbackModal(!isFeedbackModal);
  });

  const onCloseModal = () => {
    setIsFeedbackModal(false);
  };

  return (
    <div className="flex flex-row">
      <img
        src={isBookmarked ? bookmarkIcon : unbookmarkIcon}
        onClick={toggleBookmark}
        className="cursor-pointer m-1"
        alt="bookmark"
        data-testid="bookmark"
      />
      <img
        src={isHelpful ? selectedLike : unselectedLike}
        onClick={toggleLike}
        className="cursor-pointer m-1"
        alt="like"
        data-testid="like"
      />
      <img
        src={isHelpful === false ? selectedDislike : unselectedDislike}
        onClick={toggleDislike}
        className="cursor-pointer m-1"
        alt="dislike"
        data-testid="dislike"
      />
      <div className="relative" ref={divRef}>
        <img
          src={feedback}
          onClick={onFeedbackResponse}
          className="cursor-pointer m-1"
          alt="feedback"
          data-testid="feedback"
        />
      </div>
      {isFeedbackModal && (
        <div>
          <FeedbackModal
            onCloseModal={onCloseModal}
            position={overlayPosition}
            messageId={messageId}
            conversationId={conversationId}
          />
          <div className="fixed w-screen h-screen z-40 top-0 left-0" />
        </div>
      )}
    </div>
  );
}

FeedbackPanel.propTypes = {
  messageId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  isHelpful: PropTypes.bool,
  onFeedbackChange: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool,
  onBookmarkChange: PropTypes.func.isRequired,
};

FeedbackPanel.defaultProps = {
  isHelpful: null,
  isBookmarked: null,
};

export default FeedbackPanel;
