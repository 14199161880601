import React from 'react';
import PropTypes from 'prop-types';

/**
 * The `QuestionCard` component is a React component that renders a card with a question text and an
 * optional click event handler.
 * @returns The `QuestionCard` component is being returned.
 */
function QuestionCard(props) {
  const { questionText, questionId, onClick } = props;
  const { isInputDisabled } = props;

  return (
    <div
      data-testid="question-card"
      className={`w-56 shrink-0 max-h-28 bg-primary-100 rounded-md mr-2 p-2 flex ${
        isInputDisabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer hover:bg-primary-200'
      }`}
      onClick={() => onClick(questionText, questionId)}
    >
      <div className="flex-1">
        <p className="line-clamp-3">{questionText}</p>
      </div>
    </div>
  );
}

QuestionCard.propTypes = {
  questionText: PropTypes.string.isRequired,
  isInputDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  questionId: PropTypes.string.isRequired,
};

QuestionCard.defaultProps = {
  onClick: () => {},
  isInputDisabled: false,
};
export default QuestionCard;
