import { get } from 'lodash';
import PingIdSecrets from './PingIdSecrets';

/**
 * @class PingIdRouter
 * @description
 * This class is responsible for managing the authentication flow.
 * It will check if the user is authenticated and if not it will redirect the user to the login page.
 * It will also monitor the user's authentication status and will redirect the user to the login page if the user is logged out.
 */
class PingIdRouter {
  static getAuthToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = {};

    Array.from(urlParams.entries()).forEach(([key, value]) => {
      queryParams[key] = value;
    });

    return get(queryParams, 'code', null);
  }

  static removeAuthTokenFromUrl() {
    const newURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.replaceState({}, document.title, newURL);
  }

  static redirectToLogin() {
    const loginPageUrl = PingIdSecrets.getAuthTokenUrl();
    window.location.href = loginPageUrl;
  }
}

export default PingIdRouter;
