import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * The Button component is a reusable React component that renders a clickable div with customizable
 * content, styling, and event handling.
 * @returns The Button component is returning a div element with the specified props and content. The
 * div has a role of "presentation" and an onClick event handler. The className is a combination of the
 * provided className prop and some default styles. The content is rendered either as a string wrapped
 * in an h2 element or as a custom React component. The data-testid prop is used for testing purposes.
 */
function Button(props) {
  const {
    content,
    onClick,
    dataTestid,
    isHoverActive,
    isDisabled,
    isSelected,
  } = props;
  const { className, buttonType } = props;
  return (
    <div
      data-testid={dataTestid}
      role="presentation"
      onClick={onClick}
      className={classnames(
        `cursor-pointer px-2 rounded-lg flex items-center justify-center ${className}`,
        {
          'h-11 bg-gradient-to-r from-primary-400 to-primary-800 text-textColor-white':
            buttonType === 'primary',
          'h-11 bg-white border border-primary-800': buttonType === 'secondary',
          'h-11 bg-white border': buttonType === 'tertiary',
          'hover:h-11 hover:bg-primary-250 hover:border hover:border-primary-800':
            isHoverActive,
          'h-11 !bg-primary-250 border border-primary-800': isSelected,
          'pointer-events-none ': isDisabled,
        },
      )}
    >
      {typeof content === 'string' ? (
        <h2 className="p-1">{content}</h2>
      ) : (
        content
      )}
    </div>
  );
}

Button.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
  buttonType: PropTypes.string,
  isHoverActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

Button.defaultProps = {
  buttonType: 'primary',
  className: '',
  dataTestid: 'button',
  isHoverActive: false,
  isDisabled: false,
  isSelected: false,
};

export default Button;
