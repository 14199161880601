import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

function MultiSelectTiles({
  behaviorsData,
  setBehaviorsData,
  isSelectionPermitted,
  setSelectAllText,
}) {
  const { t } = useTranslation('common');

  const toggleSelection = (behaviorType, optionId) => {
    if (isSelectionPermitted === false) return;
    const updatedBehavior = behaviorsData.map(behavior => {
      const currentBehaviorType = get(behavior, 'behaviorType', '');
      const currentOptions = get(behavior, 'behaviorOptions', '');
      if (currentBehaviorType === behaviorType) {
        const updatedOptions = currentOptions.map(option => {
          if (option.id === optionId) {
            return {
              ...option,
              isSelected: !option.isSelected,
            };
          }
          return option;
        });
        return {
          ...behavior,
          behaviorOptions: updatedOptions,
        };
      }
      return behavior;
    });
    setBehaviorsData(updatedBehavior);
    const selected = updatedBehavior.map(behavior =>
      behavior.behaviorOptions.map(bs => bs.isSelected),
    );
    if (selected.flat().some(sel => sel === false)) {
      setSelectAllText({
        text: t('preCallPlanner.buttons.selectAll'),
        select: true,
      });
    } else {
      setSelectAllText({
        text: t('preCallPlanner.buttons.deselectAll'),
        select: false,
      });
    }
  };

  return (
    <div>
      {behaviorsData.map(behavior => {
        const behaviorType = get(behavior, 'behaviorType', '');
        const behaviorOptions = get(behavior, 'behaviorOptions', []);
        return (
          <div className="mt-3" key={behaviorType}>
            <header>{behaviorType}</header>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-1">
              {behaviorOptions.map(behaviorOption => {
                const isOptionSelected = get(
                  behaviorOption,
                  'isSelected',
                  false,
                );
                const optionId = get(behaviorOption, 'id', null);
                const optionValue = get(behaviorOption, 'option', '');
                return (
                  <div
                    className={`p-2 rounded-md ${
                      isSelectionPermitted ? 'cursor-pointer' : ''
                    } ${
                      isOptionSelected
                        ? 'border-primary-800 border bg-primary-100'
                        : 'bg-white'
                    }`}
                    key={optionId}
                    data-testid="multi-select-tile"
                    onClick={() => toggleSelection(behaviorType, optionId)}
                  >
                    <p>{optionValue}</p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

MultiSelectTiles.propTypes = {
  behaviorsData: PropTypes.arrayOf(
    PropTypes.shape({
      behaviorType: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setBehaviorsData: PropTypes.func.isRequired,
  isSelectionPermitted: PropTypes.bool.isRequired,
  setSelectAllText: PropTypes.func.isRequired,
};

export default MultiSelectTiles;
