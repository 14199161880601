import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { closeButton } from '../../assets/icons';

/**
 * The code is defining a React functional component called `UsageGuidelines`.
 * The component takes two props: `isModalOpen` and `closeModal`.
 * The `isModalOpen` prop is a boolean that determines whether the modal is open or not.
 * The `closeModal` prop is a function that closes the modal.
 * The component uses the `Modal` component from the `react-modal` library to render the modal.
 * The modal is a div with a white background and rounded corners.
 * The modal has a header with the text "Usage Guidelines" and a close button.
 * The modal has a body with a list of usage guidelines.
 */
function UsageGuidelines(props) {
  const { t } = useTranslation('common');
  const { isModalOpen, closeModal } = props;
  const jnjGuidelines =
    'https://jnj.sharepoint.com/sites/LawCenter/JJ%20Online%20Engagement%20Policy/Guidelines%20on%20the%20Responsible%20Use%20of%20Generative%20AI%20at%20Johnson%20and%20Johnson_FINAL.pdf';
  return (
    <Modal
      isOpen={isModalOpen}
      className="fixed inset-0 flex flex-col justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
      appElement={document.getElementById('root')}
      ariaHideApp={false}
    >
      <div className="bg-white w-fit max-w-2xl rounded-lg">
        <div className="p-0 my-4">
          <div className="flex flex-row mb-2 relative">
            <h3 className="mx-4 flex flex-1 justify-center items-center">
              {t('usageGuidelinesPopUp.heading')}
            </h3>
            <img
              src={closeButton}
              alt="close"
              className="flex justify-end items-center mx-4 cursor-pointer right-0 absolute"
              onClick={closeModal}
            />
          </div>
          <hr className="border-secondary-800" />

          <div className="m-4 flex flex-col">
            <p className="mb-2">{t('usageGuidelinesPopUp.listHeading')}</p>
            <ol className="list-decimal list-inside">
              <li className="mb-2">{t('usageGuidelinesPopUp.list.point1')}</li>
              <li className="mb-2">{t('usageGuidelinesPopUp.list.point2')}</li>
              <li className="mb-2">{t('usageGuidelinesPopUp.list.point3')}</li>
              <li className="mb-2">{t('usageGuidelinesPopUp.list.point4')}</li>
              <li className="mb-2">
                {t('usageGuidelinesPopUp.list.point5.part1')}&nbsp;
                <a
                  className="text-textColor-link underline"
                  href={jnjGuidelines}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('usageGuidelinesPopUp.list.point5.part2')}
                </a>
              </li>
            </ol>
            <br />
            <h4>{t('usageGuidelinesPopUp.termAgreement')}</h4>
          </div>
        </div>
      </div>
    </Modal>
  );
}

UsageGuidelines.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UsageGuidelines;
