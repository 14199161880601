import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { bulletIcon, closeButton, selectedBullet } from '../../assets/icons';
import {
  useAddFeedbackAnswer,
  useFeedbackQuestions,
} from '../../httpRequestHooks';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Error from '../Error';
import toastConfig from '../../utility/toastConfig';

/**
 * The above code is defining
 * a React component called `FeedbackPopup`.
 * This component is a popup modal
 * that displays feedback questions and
 * allows the user to provide responses.
 * It uses various hooks and
 * functions to handle the state and
 * logic of the component.
 * The component receives props such as
 * `onCloseModal`,
 * `messageId`,
 * `conversationId`, and
 * `position`.
 * It renders the feedback questions,
 * handles user responses, and
 * provides buttons for navigation and
 * submission of the feedback.
 * The component uses the `useState` hook to keep track of the responses.
 * The `useCallback` hook is used to memoize the functions that handle the user responses.
 * The `useFeedbackQuestions` hook is used to fetch the feedback questions.
 * The `useAddFeedbackAnswer` hook is used to add the user responses to the database.
 * The `get` function from the `lodash` library is used to get the values from the feedback questions.
 */
function FeedbackPopup(props) {
  const topOffset = 70;
  const leftOffset = 40;
  const { onCloseModal, position } = props;
  const { conversationId, messageId } = props;

  const { top, left } = position;

  const [responses, setResponses] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);

  const {
    data: feedbackQuestionsData,
    isLoading: isQuestionLoading,
    isError: isQuestionsError,
    error: questionsError,
  } = useFeedbackQuestions();

  const onBack = useCallback(() => {
    setQuestionIndex(questionIndex - 1);
  }, [questionIndex]);

  const { mutateAsync: addFeedbackAnswer } = useAddFeedbackAnswer();
  const addFeedbackResponse = useCallback(() => {
    if (responses[questionIndex]) {
      addFeedbackAnswer({
        messageId,
        conversationId,
        response: responses[questionIndex],
        questionId: feedbackQuestionsData[questionIndex].questionId,
      });
    }
  }, [
    conversationId,
    messageId,
    questionIndex,
    feedbackQuestionsData,
    responses,
  ]);

  const onSubmit = useCallback(() => {
    addFeedbackResponse();
    onCloseModal();
    toast.success('Feedback submitted', toastConfig);
  }, [addFeedbackResponse]);

  const onNext = useCallback(() => {
    addFeedbackResponse();
    setQuestionIndex(questionIndex + 1);
  }, [addFeedbackResponse]);

  const handleChange = useCallback(
    event => {
      const updatedResponses = { ...responses };
      updatedResponses[questionIndex] = event.target.value;
      setResponses(updatedResponses);
    },
    [responses, questionIndex],
  );

  const onOptionSelection = useCallback(
    option => {
      const updatedResponses = { ...responses };
      updatedResponses[questionIndex] = option.value;
      setResponses(updatedResponses);
    },
    [responses, questionIndex],
  );

  const question = get(
    feedbackQuestionsData,
    `[${questionIndex}].question`,
    null,
  );
  const questionType = get(
    feedbackQuestionsData,
    `[${questionIndex}].questionType`,
    'PARAGRAPH',
  );

  const selectOptions = get(
    feedbackQuestionsData,
    `[${questionIndex}].options`,
    [],
  );
  const totalQuestions = get(feedbackQuestionsData, 'length', null);

  return (
    <div
      className="fixed bg-white bg-opacity-100 flex flex-col w-60 shadow-2xl p-4 z-50"
      style={{
        top: top - topOffset,
        left: left + leftOffset,
      }}
    >
      <div className="flex flex-row mb-2">
        <img
          src={closeButton}
          alt="close"
          className="justify-end cursor-pointer ml-auto"
          onClick={onCloseModal}
        />
      </div>
      {isQuestionLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isQuestionsError && <Error error={questionsError} />}
      {feedbackQuestionsData && Array.isArray(feedbackQuestionsData) && (
        <div className="w-full">
          <p>{question}</p>
          <hr className="border-secondary-800 my-3" />
          {questionType === 'SINGLE_SELECT' &&
            Array.isArray(selectOptions) &&
            selectOptions.map(option => {
              const optionValue = get(option, 'value', null);
              const optionLabel = get(option, 'label', null);
              const userResponse = responses[questionIndex];
              return (
                <div className="flex flex-row mt-2" key={optionValue}>
                  <img
                    src={
                      userResponse === optionValue ? selectedBullet : bulletIcon
                    }
                    alt="option"
                    className="mr-1 cursor-pointer"
                    onClick={() => onOptionSelection(option)}
                  />
                  <p>{optionLabel}</p>
                </div>
              );
            })}
          {questionType === 'PARAGRAPH' && (
            <textarea
              data-testid="textarea"
              type="textarea"
              name="textValue"
              onChange={handleChange}
              className="w-full border border-primary-800 h-24 focus:outline-none p-1"
            >
              {responses[questionIndex]}
            </textarea>
          )}
          <div className="flex flex-row-reverse justify-between mt-6">
            <div className="justify-end">
              {questionIndex !== totalQuestions - 1 ? (
                <Button
                  dataTestid="next-button"
                  content="Next"
                  onClick={onNext}
                  buttonType="secondary"
                />
              ) : (
                <Button
                  dataTestid="submit-button"
                  content="Submit"
                  onClick={onSubmit}
                />
              )}
            </div>
            {questionIndex !== 0 && (
              <Button
                dataTestid="back-button"
                content="Back"
                onClick={onBack}
                buttonType="secondary"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

FeedbackPopup.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
};

FeedbackPopup.defaultProps = {
  position: {
    top: 0,
    left: 0,
  },
};

export default FeedbackPopup;
