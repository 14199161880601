import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The function `useFetchQues` is a custom hook that uses the `useQuery` hook from a library called
 * `react-query` to fetch question data from a specified URL.
 * @returns The `useFetchQues` function is returning the result of the `useQuery` hook.
 */
const fetchQuesData = params => {
  const fetchQuesURL = 'question_catalog';
  return axios.get(fetchQuesURL, { params }).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFetchQues = params => {
  const queryKey = ['get questions', params];
  const queryConfig = { enabled: false };
  const queryFn = () => fetchQuesData(params);
  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFetchQues;
