import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LoginContext from '../context/Login.context';
import Copilot from '../pages/Copilot/Copilot';
import RepAccess from '../pages/RepAccess/RepAccess';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import authFactory from '../utility/auth/authFactory';
import BASIC_CACHE from '../utility/cache/cacheConfiguration';
import { SCOPE_TYPE } from '../constants/scope.constant';
import translationEN from '../translations/en.json';
import Button from '../components/Button/Button';
import Landing from '../pages/Landing/Landing';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: BASIC_CACHE,
    useErrorBoundary: true,
  },
});

/** The `AppRoutes` function is a React component
 * that serves as the main entry point for rendering
 * the application routes.
 * @returns The `AppRoutes` function returns a React component.
 * The `AppRoutes` function renders the main page of the application.
 */
function AppRoutes({ landingState }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [scope, setScope] = useState('');
  const [conversationTool, setConversationTool] = useState('');
  const [showLanding, setShowLanding] = useState(landingState);

  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    ns: ['common'],
    resources: {
      en: {
        common: translationEN,
      },
    },
  });

  useEffect(() => {
    // default everyone to rep access
    if (conversationTool === '') {
      localStorage.setItem('currentAuthScope', SCOPE_TYPE.REP_ACCESS);
      setConversationTool(SCOPE_TYPE.REP_ACCESS);
    }
  }, [setConversationTool, conversationTool]);

  const auth = authFactory();
  auth.addLoginCallback(value => setIsLoggedIn(value));

  const loginContextValues = useMemo(
    () => ({ isLoggedIn, setIsLoggedIn, scope, setScope }),
    [isLoggedIn, setIsLoggedIn, scope, setScope],
  );

  const onLoginAgain = () => {
    auth.logout();
    window.location.href = window.location.origin;
  };

  const url = new URL(window.location.href);
  const [showLoggedOut, setShowLoggedOut] = useState(
    url.searchParams.get('isLoggedOut'),
  );

  useEffect(() => {
    setShowLoggedOut(url.searchParams.get('isLoggedOut'));
  }, [window.location.search]);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deploymentPlatform = `${process.env.REACT_APP_DEPLOYMENT_PLATFORM}`;

  return (
    <LoginContext.Provider value={loginContextValues}>
      {!isLoggedIn && (showLoggedOut ? <Logout /> : <Login />)}
      {isLoggedIn && (
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18next}>
            <div style={{ height: viewportHeight }}>
              {showLanding && <Landing setShowLanding={setShowLanding} />}
              {!showLanding && (
                <div style={{ height: viewportHeight }}>
                  {conversationTool === SCOPE_TYPE.COPILOT && (
                    <Copilot setConversationTool={setConversationTool} />
                  )}
                  {conversationTool === SCOPE_TYPE.REP_ACCESS && (
                    <RepAccess setConversationTool={setConversationTool} />
                  )}
                  {![SCOPE_TYPE.COPILOT, SCOPE_TYPE.REP_ACCESS].includes(
                    conversationTool,
                  ) && (
                    <div>
                      {deploymentPlatform === 'microSite' && (
                        <div className="flex flex-col items-center justify-center h-screen bg-white px-10">
                          <h1 className="m-8">No Access</h1>
                          <Button
                            content="Login again"
                            onClick={onLoginAgain}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </I18nextProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </QueryClientProvider>
      )}
    </LoginContext.Provider>
  );
}

AppRoutes.propTypes = {
  landingState: PropTypes.bool,
};

AppRoutes.defaultProps = {
  landingState: true,
};

export default AppRoutes;
