import { useCallback } from 'react';

const useAutoScroll = id =>
  useCallback(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [id]);

export default useAutoScroll;
