import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SCOPE_TYPE } from '../../constants/scope.constant';

function DisclaimerCard({ userName, scope }) {
  const { t } = useTranslation('common');
  return (
    <div className="bg-primary-250 p-3">
      <h1>
        {t('common.greeting')} {userName},<br />
      </h1>
      {scope === SCOPE_TYPE.COPILOT && (
        <>
          <h1 className="mb-3">{t('common.welcomeText')}</h1>
          <aside className="mb-2">{t('copilotLandingPage.description')}</aside>
        </>
      )}
      {scope === SCOPE_TYPE.REP_ACCESS && (
        <aside className="mb-2">{t('repAccessLandingPage.description1')}</aside>
      )}
      <div className="flex flex-row">
        {scope === SCOPE_TYPE.COPILOT && (
          <>
            <div className="flex-1 bg-primary-300 p-3 my-3 mr-4">
              <h5>{t('copilotLandingPage.disclaimerCard.card1.heading')}</h5>
              <h6>
                {t('copilotLandingPage.disclaimerCard.card1.description')}
              </h6>
            </div>
            <div className="flex-1 bg-primary-300 p-3 my-3">
              <h5>{t('copilotLandingPage.disclaimerCard.card2.heading')}</h5>
              <h6>
                {t('copilotLandingPage.disclaimerCard.card2.description')}
                <br />
                {t(
                  'copilotLandingPage.disclaimerCard.card2.subCards.subCard1.description',
                )}
              </h6>
            </div>
          </>
        )}
        {scope === SCOPE_TYPE.REP_ACCESS && (
          <div className="flex-1 bg-primary-300 p-3 my-3 mr-4">
            <h5>{t('repAccessLandingPage.disclaimerCard.card1.heading')}</h5>
            <h6>
              {t('repAccessLandingPage.disclaimerCard.card1.description')}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
}

DisclaimerCard.propTypes = {
  userName: PropTypes.string.isRequired,
  scope: PropTypes.string,
};

DisclaimerCard.defaultProps = {
  scope: SCOPE_TYPE.COPILOT,
};

export default DisclaimerCard;
