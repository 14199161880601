/**
 * @param {Array} envList - List of environments where the feature should be shown
 * @returns {Boolean} - True if the feature should be shown, false otherwise
 */
const showFeature = envList => {
  const currentEnv = `${process.env.REACT_APP_ENV}`;
  return envList.includes(currentEnv);
};

export default showFeature;
