/* eslint-disable */
/** Returns IN statement from an array for MyInsights query */
export const getInStatementArray = values => {
  if (values) {
    return `{'` + values.join(`', '`) + `'}`;
  } else {
    return '{}';
  }
};

/** Returns an IN statement from a Set of IDs for MyInsights query */
export const getInStatement = values => {
  if (values) {
    return `{'` + values.toArray().join(`', '`) + `'}`;
  } else {
    return '{}';
  }
};

/** Returns an IN statement from a single ID for MyInsights query */
export const getInStatementString = value => {
  if (value) {
    return `{'` + value + `'}`;
  } else {
    return '{}';
  }
};

/**
 * Verifies if an object is empty
 * @param {Object} obj
 * @returns true if object is empty or else false.
 */
export const isObjectEmpty = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

/**
 * Converts array to object
 * @param {Array} array in context
 * @param {String} keyField as the object key
 * @param {String} valueField as value
 * @return {Object} obj
 */
export const arrayToObject = (array, keyField, valueField) => {
  if (array && array.length > 0) {
    return array.reduce((obj, item) => {
      obj[item[keyField]] = item[valueField];
      return obj;
    }, {});
  } else {
    return {};
  }
};

/**
 * Formats string literal {0}, {1} with specified values
 * Treat the first argument as a pattern and return a string using the second argument for substitution and formatting.
 */
export const formatStr = (strToFormat, formattingArgs) => {
  formattingArgs.forEach((val, i) => {
    strToFormat = strToFormat.replace(new RegExp('\\{' + i + '\\}', 'g'), val);
  });
  return strToFormat;
};

/**
 * Determine if an array contains one or more items from another array.
 * @param {Array} haystack the array to search.
 * @param {Array} arr the array providing items to check for in the haystack.
 * @return {Boolean} true|false if haystack contains at least one item from arr.
 */
export const existInArray = (haystack, arr) => {
  return arr.some(item => {
    return haystack.indexOf(item) >= 0;
  });
};

/**
 * Test the existence of a string in an array of string.
 * @param {Array} array
 * @param {String} str
 * @returns {Boolean} true if found and false if not found.
 */
export const existsIgnoreCase = (array, str) => {
  return -1 !== indexOfIgnoreCase(array, str);
};

/**
 * Find the index of a string in an array of string.
 * @param {Array} array
 * @param {String} str
 * @returns {Number} the index of the str in the array or -1 if not found.
 */
export const indexOfIgnoreCase = (array, str) => {
  let ret = -1;
  if (array && array.length > 0) {
    array.some(function (item, index) {
      if (equalsIgnoreCase(str, item)) {
        ret = index;
        return true;
      }
    });
  }
  return ret;
};

/**
 * Test for String equality ignoring case.
 * @param {String} str1
 * @param {String} str2
 * @returns {Boolean} true if both string is equals ignoring case.
 */
export const equalsIgnoreCase = (str1, str2) => {
  return str1.trim().toLowerCase() === str2.trim().toLowerCase();
};

/**
 * converts specified string to proper title case
 */
export const toProperCase = str => {
  return str
    .split(' ')
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
};

/**
 * formats address
 */
export const formatAddress = address => {
  let retVal = '';
  if (address) {
    retVal += address.Name.value + ',';
    if (address.City_vod__c.value) {
      retVal += ' ' + address.City_vod__c.value + ',';
    }
    if (address.State_vod__c.value) {
      retVal += ' ' + address.State_vod__c.value;
    }
    if (address.Zip_vod__c.value) {
      retVal += ' ' + address.Zip_vod__c.value;
    }
  }
  return retVal;
};

/*
 * format phone number in standard (xxx) xxx-xxxx format
 */
export const normalizePhone = phone => {
  // normalize string and remove all unnecessary characters
  const formattedPhone = phone.replace(/[^\d]/g, '');

  // check if number length equals to 10
  if (formattedPhone.length == 10) {
    //reformat and return phone number
    return formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return phone;
};
