import React, { useState, useCallback, useEffect } from 'react';
import { debounce, get } from 'lodash';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { closeButton } from '../../assets/icons';
import { useFetchHCP } from '../../httpRequestHooks';
import Loader from '../Loader/Loader';
import Error from '../Error';

/**
 * The code is defining a
 * React functional component
 * called `HCPModal`.
 * It is a modal component that
 * displays a search input field
 * and a list of healthcare professionals
 * (HCPs) based on the search input.
 * The user can select an HCP from the list,
 * and the selected HCP will be passed
 * to the `setSelectedHcp` function and
 * the `setIsHcpConversation` function.
 * The modal can be opened or closed by
 * setting the `isModalOpen` state and
 * calling the `setIsModalOpen` function.
 */
function HCPModal(props) {
  const { t } = useTranslation('common');
  const { setSelectedHcp, setIsHcpConversation } = props;
  const { isModalOpen, setIsModalOpen } = props;
  const [inputText, setInputText] = useState('');

  const {
    isLoading: isHcpLoading,
    isSuccess: isHcpSuccess,
    isError: isHcpError,
    data: hcpData,
    error: hcpError,
    refetch: fetchHcp,
  } = useFetchHCP({ search: inputText, offset: 0, limit: 500 });

  const waitInterval = 200;
  const onSearch = debounce(fetchHcp, waitInterval);

  const onChangeHandler = useCallback(event => {
    setInputText(event.target.value);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSelectHcp = useCallback(hcpDetails => {
    setSelectedHcp(hcpDetails);
    setIsHcpConversation(true);
    closeModal();
  }, []);

  useEffect(() => {
    onSearch();
  }, [inputText]);

  useEffect(() => {
    fetchHcp();
  }, []);

  return (
    <Modal
      isOpen={isModalOpen}
      className="fixed inset-0 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
      appElement={document.getElementById('root')}
      ariaHideApp={false}
    >
      <div className="bg-white min-w-1/4 min-h-1/3 rounded-lg">
        <div className="p-0 my-4">
          <div className="flex flex-row mb-2 relative">
            <h3 className="mx-4 flex flex-1 justify-center items-center ">
              {t('HCPModal.searchHeading')}
            </h3>
            <img
              src={closeButton}
              alt="close"
              className="flex justify-end items-center mx-4 cursor-pointer absolute right-0"
              onClick={closeModal}
            />
          </div>
          <hr className="border-secondary-800" />
          {isHcpLoading && (
            <div className="flex flex-col items-center m-10">
              <Loader />
            </div>
          )}
          {isHcpSuccess && (
            <div className="m-4">
              <input
                data-testid="hcp-search"
                name={t('HCPModal.input.label')}
                value={inputText}
                placeholder={t('HCPModal.input.placeholder')}
                className="w-full focus:outline-none p-1"
                onChange={onChangeHandler}
                autoComplete="off"
              />
              <hr className="border-primary-800" />
              <div className="mt-3 mx-2 max-h-40 overflow-y-auto">
                {hcpData.map(hcpDetails => {
                  const firstName = get(hcpDetails, 'hcpFirstName', null);
                  const lastName = get(hcpDetails, 'hcpLastName', null);
                  const hcpId = get(hcpDetails, 'hcpId', null);
                  return (
                    <div
                      className="flex justify-between cursor-pointer hover:bg-secondary-100 p-1"
                      onClick={() => onSelectHcp(hcpDetails)}
                      key={hcpId}
                    >
                      <p
                        data-testid="hcp-name"
                        key={`${firstName}-${lastName}`}
                      >
                        {firstName}&nbsp;{lastName}
                      </p>
                      &nbsp;
                      <p className="text-textColor-meta">{hcpId}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {isHcpError && <Error error={hcpError} />}
      </div>
    </Modal>
  );
}

HCPModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setSelectedHcp: PropTypes.func.isRequired,
  setIsHcpConversation: PropTypes.func.isRequired,
};

HCPModal.defaultProps = { isModalOpen: false, setIsModalOpen: () => '' };
export default HCPModal;
