import axios from 'axios';
import { useMutation } from 'react-query';

const deleteMessage = (conversationId, messageId) => {
  const deleteMessageURL = `conversation/${conversationId}/message/${messageId}`;
  return axios.delete(deleteMessageURL);
};

const useDeleteMessage = () => {
  const mutationFn = payload => {
    const { conversationId, messageId } = payload;
    return deleteMessage(conversationId, messageId);
  };
  return useMutation(mutationFn);
};

export default useDeleteMessage;
