/* eslint-disable */
import * as ds from './query';

// User info
export const getUserInfo = () => {
  // get account in context
  return ds
    .getDataForCurrentObject('User')
    .then(userId => {
      return ds.getUserDetail([userId]);
    })
    .then(userResp => {
      return userResp;
    });
};

// Account info
export const getAccountInfo = parent => {
  // get account in context
  return ds
    .getDataForCurrentObject('Account')
    .then(acctId => {
      return ds.getAccountDetail(acctId, parent);
    })
    .then(acctResp => {
      return acctResp;
    });
};

// suggestion info
export const getSuggestionInfo = (
  acctIds,
  recTypeNames,
  userIds,
  date,
  start,
  end,
) => {
  let suggestions = [],
    suggestionTags = [],
    recTypes = [];
  return ds
    .getRecordType('Suggestion_vod__c', recTypeNames)
    .then(rtResp => {
      let rtIds = [];
      if (rtResp && rtResp.length > 0) {
        recTypes = [...rtResp];
        rtIds = rtResp.map(rt => rt.Id.value);
      }
      return ds.getSuggestions(acctIds, rtIds, userIds, date, start, end);
    })
    .then(sResp => {
      let suggestionIds = [];
      if (sResp && sResp.length > 0) {
        suggestions = [...sResp];
        suggestionIds = sResp.map(s => s.Id.value);
      }
      return ds.getSuggestionTags(suggestionIds);
    })
    .then(tagResp => {
      let productIds = [];
      if (tagResp && tagResp.length > 0) {
        suggestionTags = [...tagResp];
        for (let t of tagResp) {
          if (t.Product_vod__c.value && t.Product_vod__c.value != '') {
            productIds.push(t.Product_vod__c.value);
          }
        }
      }
      return ds.getProductsById(productIds, ['Detail']);
    })
    .then(products => {
      return {
        suggestions,
        suggestionTags,
        recTypes,
        products,
      };
    });
};
