import { useEffect, useRef } from 'react';

/**
 * The `useInterval` function is a custom React hook that allows you to execute a callback function at
 * a specified interval.
 * @param callbackfn - The `callbackfn` parameter is a function that will be called repeatedly at the
 * specified interval.
 * @param delay - The `delay` parameter is the time interval (in milliseconds) between each execution
 * of the `callbackfn` function. If `delay` is set to `null`, the interval will be cleared and the
 * `callbackfn` function will no longer be executed.
 */
function useInterval(callbackfn, delay) {
  const savedCallback = useRef(() => '');

  useEffect(() => {
    savedCallback.current = callbackfn;
  }, [callbackfn]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
    return undefined;
  }, [callbackfn, delay]);
}

export default useInterval;
