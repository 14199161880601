import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from './routes/appRoutes';
import authFactory from './utility/auth/authFactory';
import setupAxios from './utility/axios';

function App() {
  /**
   * The App function sets up Axios and authenticates with PingId before rendering the AppRoutes
   * component.
   */
  setupAxios();
  const auth = authFactory();
  auth.authenticate();
  return (
    <>
      <ToastContainer />
      <AppRoutes />
    </>
  );
}

export default App;
