import React, { useEffect, useState, useContext, useCallback } from 'react';
import { get, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ChatInput from './ChatInput.subsection';
import Conversation from './Conversation.subSection';
import {
  useAddConversation,
  useFetchConversations,
  useChatsData,
  useAddUserMessage,
  useFeedbackQuestions,
  useEditConversation,
  useFetchConversationById,
} from '../../httpRequestHooks';
import PollingSubSection from './Polling.subsection';
import ConversationContext from '../../context/Conversation.context';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';
import useAutoScroll from '../../hooks/useAutoScroll';
import {
  PRE_CALL_PLANNER_KEY,
  preCallPlannerOptions,
} from '../../constants/preCallPlanCriteria.constant';
import { callPlanner } from '../../assets/icons';

/**
 * The `HCPChat` function is a React component that renders the HCP chat section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id and set the conversation id.
 * The `useChatsData` hook is used to fetch the chats data.
 * The `useEffect` hook is used to refetch the chats data.
 * The `useEffect` hook is used to refetch the feedback questions.
 * The `useEffect` hook is used to set the last conversation role and input disabled effect.
 * @returns The `HCPChat` function returns a React component.
 * @category Components
 * @example <caption>Example usage of HCPChat.</caption>
 */
function HCPChat({ sellingDivision }) {
  const { conversationId, setConversationId } = useContext(ConversationContext);
  const { selectedHcp, userInputQues, setUserInputQues } =
    useContext(ConversationContext);
  const { t } = useTranslation('common');

  const selectedHcpId = get(selectedHcp, 'hcpId', '');
  const hcpFirstName = get(selectedHcp, 'hcpFirstName', null);
  const hcpLastName = get(selectedHcp, 'hcpLastName', null);

  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [lastConversationRole, setLastConversationRole] = useState(null);

  const conversationType = CONVERSATION_TYPE.HCP;
  const { refetch: refetchConversations } = useFetchConversations({
    conversationType,
  });
  const { mutateAsync: addChatDataAsync } = useAddUserMessage();
  const { mutateAsync: addConversationAsync } = useAddConversation();

  const { data: allChatsData, refetch: refetchChatData } =
    useChatsData(conversationId);

  const { mutateAsync: editConversation } = useEditConversation();
  const { refetch: refetchConversationById, data: currentConversationData } =
    useFetchConversationById(conversationId);

  const scrollToBottom = useAutoScroll('conversationArea');

  const onPostMessage = useCallback(
    async (userMessage, questionId = null, messageType = 'text') => {
      if (lastConversationRole === 'USER') return;

      setIsInputDisabled(true);

      await addChatDataAsync({
        conversationId,
        message: userMessage,
        questionId,
        messageType,
      });
      scrollToBottom();

      refetchChatData();
    },
    [conversationId, lastConversationRole],
  );

  useEffect(() => {
    setUserInputQues(null);
    refetchChatData();
  }, [conversationId]);

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length !== 0) {
      const lastMessageData = last(allChatsData);
      const lastMessageRole = get(lastMessageData, 'role', null);
      const isLastUserRole = lastMessageRole === 'USER';
      setLastConversationRole(lastMessageRole);
      setIsInputDisabled(isLastUserRole);
    }
  }, [allChatsData]);

  const onError = async () => {
    const conversationTitle = `${hcpFirstName} ${hcpLastName}`;
    const conversation = await addConversationAsync({
      conversationTitle,
      hcpId: selectedHcpId,
    });
    const id = get(conversation, 'conversationId', '');
    setConversationId(id);
    refetchConversations();
  };

  const onSuccess = async response => {
    if (Array.isArray(response) && response.length !== 0) {
      setConversationId(response[0].conversationId);
    } else {
      onError();
    }
  };

  const { refetch: refetchHCPConversation } = useFetchConversations(
    { hcpId: selectedHcpId },
    onSuccess,
    onError,
  );

  useEffect(() => {
    if (selectedHcpId) {
      refetchHCPConversation();
    }
  }, [selectedHcpId]);

  const { refetch: fetchFeedbackQuestions } = useFeedbackQuestions();

  useEffect(() => {
    fetchFeedbackQuestions();
  }, []);

  useEffect(() => {
    if (conversationId) refetchConversationById();
    if (userInputQues) setIsInputDisabled(true);
    else setIsInputDisabled(false);
  }, [userInputQues, conversationId]);

  const onPreCallPlannerSelect = question => {
    if (!isInputDisabled) {
      setUserInputQues(question);
      scrollToBottom();
    }
  };

  const onPreCallSelectionComplete = async (
    behaviorsData,
    isSelectionPermitted,
  ) => {
    const messageType = 'json';

    if (!isSelectionPermitted) return;

    const ques = {
      ...userInputQues,
      behaviors: behaviorsData,
    };

    const questionId = PRE_CALL_PLANNER_KEY;
    onPostMessage(ques, questionId, messageType);
    await editConversation({
      conversationId,
      contextMetadata: {
        [PRE_CALL_PLANNER_KEY]: ques,
      },
    });
    setUserInputQues(null);
  };

  const preCallPrevSelected = get(
    currentConversationData,
    `contextMetadata.${PRE_CALL_PLANNER_KEY}`,
    null,
  );

  return (
    <div className="flex flex-col h-full w-full">
      {userInputQues === null &&
        Array.isArray(allChatsData) &&
        allChatsData.length !== 0 && (
          <div>
            <div
              className={`flex flex-row items-center justify-center bg-primary-100 p-2 w-fit m-2 ${
                isInputDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() =>
                onPreCallPlannerSelect(preCallPlannerOptions(sellingDivision))
              }
            >
              <p>{t('preCallPlannerChat.heading-widget')}</p>
              <img src={callPlanner} alt="call-planner" className="ml-4" />
            </div>
          </div>
        )}
      <div className="flex-1 flex flex-col flex-col-reverse overflow-y-auto justify-end mb-4">
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div
            className="flex flex-col flex-1 flex-col-reverse overflow-y-auto"
            id="conversationArea"
          >
            <Conversation
              key={conversationId}
              onPreCallSelectionComplete={onPreCallSelectionComplete}
              onPreCallPlannerSelect={onPreCallPlannerSelect}
              preCallPrevSelected={preCallPrevSelected}
              sellingDivision={sellingDivision}
            />
          </div>
          {Array.isArray(allChatsData) && allChatsData.length !== 0 && (
            <PollingSubSection
              key={allChatsData.length}
              isProcessed={lastConversationRole === 'AI'}
            />
          )}
        </div>
      </div>
      <div>
        <ChatInput
          key={conversationId + lastConversationRole}
          onPostMessage={onPostMessage}
          isInputDisabled={isInputDisabled}
        />
      </div>
    </div>
  );
}
HCPChat.propTypes = {
  sellingDivision: PropTypes.string,
};

HCPChat.defaultProps = {
  sellingDivision: 'Darzalex',
};

export default HCPChat;
