class AuthStore {
  /**
   * This class is used to store the ID token and refresh token in local storage.
   * @class AuthStore
   * @description
   * This class is used to store the ID token and refresh token in local storage.
   * @example
   * import AuthStore from 'utility/auth/AuthStore';
   * const AuthStore = new AuthStore();
   * AuthStore.storeAccessToken('idToken');
   * AuthStore.storeRefreshToken('refreshToken');
   * AuthStore.storeProfile({ name: 'John Doe' });
   */
  #accessTokenKey = 'authAccessToken';

  #refreshTokenKey = 'authRefreshToken';

  #profileKey = 'authProfile';

  #scopeKey = 'authScope';

  #switchUser = 'switchUser';

  #currentScopeKey = 'currentAuthScope';

  // Store the ID token in local storage
  storeAccessToken(idToken) {
    localStorage.setItem(this.#accessTokenKey, idToken);
  }

  // Retrieve the ID token from local storage
  getAccessToken() {
    return localStorage.getItem(this.#accessTokenKey);
  }

  // Store the profile in local storage
  storeProfile(profile) {
    localStorage.setItem(this.#profileKey, JSON.stringify(profile));
  }

  // Retrieve the profile from local storage
  getProfile() {
    const profile = localStorage.getItem(this.#profileKey);
    try {
      return JSON.parse(profile);
    } catch {
      return null;
    }
  }

  // Store the scope in local storage
  storeScope(scope) {
    localStorage.setItem(this.#scopeKey, scope);
  }

  storeSwitchUser(switchUser) {
    localStorage.setItem(this.#switchUser, switchUser);
  }

  getScope() {
    const currentAuthScope = localStorage.getItem(this.#currentScopeKey);

    const authScope = localStorage.getItem(this.#scopeKey);

    if (currentAuthScope && currentAuthScope !== 'null') {
      return currentAuthScope;
    }

    localStorage.setItem(this.#currentScopeKey, authScope);

    return authScope;
  }

  getSwitchUser() {
    return localStorage.getItem(this.#switchUser);
  }

  // Store the refresh token in local storage
  storeRefreshToken(refreshToken) {
    localStorage.setItem(this.#refreshTokenKey, refreshToken);
  }

  // Retrieve the refresh token from local storage
  getRefreshToken() {
    return localStorage.getItem(this.#refreshTokenKey);
  }

  // Clear both the ID token and refresh token from local storage
  clearTokens() {
    localStorage.removeItem(this.#accessTokenKey);
    localStorage.removeItem(this.#refreshTokenKey);
    localStorage.removeItem(this.#profileKey);
    localStorage.removeItem(this.#scopeKey);
    localStorage.removeItem(this.#currentScopeKey);
  }
}

export default AuthStore;
