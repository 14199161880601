import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { closeButton } from '../../assets/icons';
import Button from '../Button/Button';
import { useFetchRepData } from '../../httpRequestHooks';

/** The code is defining a functional component called `UserModal`
 * that represents a modal window for switching users.
 * The component takes two props: `isModalOpen` and `closeModal`.
 * The `isModalOpen` prop is a boolean that determines whether the modal is open or not.
 * The `closeModal` prop is a function that closes the modal.
 * The component uses the `Modal` component from the `react-modal` library to render the modal.
 * The modal is a div with a white background and rounded corners.
 * The modal has a header with the text "Switch User" and a close button.
 * */
function UserModal(props) {
  const { isModalOpen, closeModal } = props;
  const [selectedOption, setSelectedOption] = useState(null);

  const onChangeRepId = useCallback(() => {
    const optionValue = get(selectedOption, 'value', null);
    if (optionValue) {
      localStorage.setItem('REP_ID', selectedOption.value);
    } else {
      localStorage.removeItem('REP_ID');
    }
    localStorage.removeItem('conversationIds');
    localStorage.removeItem('currentChat');
    window.location.reload();
  }, [selectedOption]);

  const { data: repsData } = useFetchRepData();

  let allRepIds = [];
  if (Array.isArray(repsData)) {
    allRepIds = repsData.map(repData => {
      const currentRepId = get(repData, 'repId', null);
      return {
        value: currentRepId,
        label: currentRepId,
      };
    });
  }

  const handleChange = useCallback(
    option => {
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  return (
    <Modal
      isOpen={isModalOpen}
      className="fixed inset-0 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
      appElement={document.getElementById('root')}
      ariaHideApp={false}
    >
      <div className="bg-white w-1/4 rounded-lg">
        <div className="p-0 my-4">
          <div className="flex flex-row mb-2 relative">
            <h3 className="mx-4 flex flex-1 justify-center items-center">
              Switch User
            </h3>
            <img
              src={closeButton}
              alt="close"
              className="flex justify-end items-center mx-4 cursor-pointer right-0 absolute"
              onClick={closeModal}
            />
          </div>
          <hr className="border-secondary-800" />

          <div className="m-4 flex flex-row items-center justify-between">
            <Select
              options={allRepIds}
              value={selectedOption}
              onChange={handleChange}
              placeholder="Select Rep Id"
              className="flex-1 mr-2"
            />
            <Button
              content="Submit"
              dataTestid="submit-switch-user"
              onClick={onChangeRepId}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

UserModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UserModal;
