import { useState, useEffect, useRef } from "react";

/**
 * The `useIsMainTab` function is a custom React hook that allows you to determine wheather the current tab is primary tab or not.
 * The `getWindowArray` function gets the values of all browser windows from local storage in which th eapplication is open with the key `checkTab`.
 * The `setWindowArray` function updates the values of all the open windows whenever a new window is open. It updates the value of `checkTab` in the local storage.
 * The `determineWindowState` function is used to determine if the current tab is the main tab.
 * The `makeThisTabMain` function sets the current tab as main tab if the current one is not the main tab. It uses `determineWindowState` to do so.
 * The `handleVisibilityChange` function handles the state to the tab when user switches focus between the different tabs.
 * The `removeWindow` function removes the tab from the window array in local storage when the tab is closed.
 * This hook returns a value `isMain` which is used to conditionally render components and call functions in other components.
 */

const getWindowArray = () => {
  const storage = window.localStorage.getItem("checkTab");
  return storage ? JSON.parse(storage) : [];
};

const setWindowArray = data => {
  window.localStorage.setItem("checkTab", JSON.stringify(data));
};

const useIsMainTab = () => {
  const initialized = useRef(false);
  const windowId = useRef(null);
  const [isMain, setIsMain] = useState(false);

  const determineWindowState = () => {
    const windowArray = getWindowArray();
    if (initialized.current) {
      const {length} = windowArray;
      if (windowArray[length - 1] === windowId.current) {
        setIsMain(true);
      } else {
        setIsMain(false);
      }
    } else {
      if (windowArray.length === 0) {
        setIsMain(true);
      } else {
        setIsMain(false);
      }
      const newWindowArray = [...windowArray, windowId.current];
      setWindowArray(newWindowArray);
    }
    setTimeout(() => {
      determineWindowState();
    }, 1500);
  };

  const makeThisTabMain = () => {
    const windowArray = getWindowArray();
    const newWindowArray = windowArray.filter(id => id !== windowId.current);
    newWindowArray.push(windowId.current);
    setWindowArray(newWindowArray);
    determineWindowState();
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      makeThisTabMain();
    }
  };

  const removeWindow = () => {
    const newWindowArray = getWindowArray().filter(id => id !== windowId.current);
    setWindowArray(newWindowArray);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", removeWindow);
    window.addEventListener("unload", removeWindow);
    windowId.current = Date.now().toString();
    determineWindowState();
    initialized.current = true;

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", removeWindow);
      window.removeEventListener("unload", removeWindow);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      removeWindow();
    };
  }, []);

  return isMain;
};

export default useIsMainTab;