import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

const fetchConversationById = conversationId => {
  const fetchBookmarkURL = `conversation/${conversationId}`;
  return axios.get(fetchBookmarkURL).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFetchConversationById = conversationId => {
  const queryKey = ['get conversation', conversationId];
  const queryConfig = { enabled: false };
  const queryFn = () => fetchConversationById(conversationId);
  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFetchConversationById;
