/* eslint-disable no-useless-return */
/* eslint-disable no-console */
import { AuthProvider } from '../AuthProvider/AuthProvider';

class NoAuth extends AuthProvider {
  getCurrentAccessToken() {
    return null;
  }

  getProfile() {
    return null;
  }

  getScope() {
    return null;
  }

  getSwitchUser() {
    return null;
  }

  refetchToken() {
    throw new Error('Refresh not required');
  }

  addLoginCallback(callbackFunction) {
    if (!this.isLogin) {
      super.addLoginCallback(callbackFunction);
      this.isLogin = true;
    }
  }

  authenticate() {
    return;
  }
}

export default new NoAuth();
