import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerCircularFixed } from 'spinners-react';

/**
 * The Loader component is a React component that renders a circular spinner with customizable size and
 * styling.
 * @returns The Loader component is returning a SpinnerCircularFixed component with various props
 * passed to it.
 */
function Loader({ className, spinnerSize }) {
  return (
    <SpinnerCircularFixed
      size={spinnerSize}
      thickness={150}
      speed={100}
      color="black"
      secondaryColor="transparent"
      className={className}
    />
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  spinnerSize: PropTypes.number,
};

Loader.defaultProps = {
  className: '',
  spinnerSize: 75,
};
export default Loader;
