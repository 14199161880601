/* eslint-disable */
import * as Utils from '../commonUtils';
// import { mockAdapter } from '../mockAdapter';

/* eslint no-unused-vars: ["error", { "args": "none" }] */

// promise global variable
const $q = window.Q;
// data service global variable
const ds = window.ds;

/* CRM DB CALLS */
// smart link for record detail view
export const viewRecord = (sObject, recordId) => {
  const deferred = $q.defer();
  const smartLink = {
    object: sObject,
    fields: {
      Id: recordId,
    },
  };
  ds.viewRecord(smartLink).then(
    resp => {
      deferred.resolve(resp);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get available object
export const getAvailableObjects = () => {
  const deferred = $q.defer();
  ds.getAvailableObjects().then(result => {
    deferred.resolve(result.data);
  });
  return deferred.promise;
};

// get available object
export const getSFDCSessionID = () => {
  const deferred = $q.defer();
  ds.getSFDCSessionID().then(result => {
    deferred.resolve(result);
  });
  return deferred.promise;
};

// get token
export const getSSOAccessToken = (authIdentifier, providerName) => {
  console.log('getSSOAccessToken', ds.getSSOAccessToken);

  const deferred = $q.defer();
  ds.getSSOAccessToken(authIdentifier, providerName)
    .then(result => {
      console.log('getSSOAccessTokenTHEN');
      deferred.resolve(result);
    })
    .catch(err => {
      console.log('getSSOAccessTokenCat');
      deferred.reject(err);
    });
  return deferred.promise;
};

export const refreshSSOAccessToken = (
  authIdentifier,
  providerName,
  oldToken,
) => {
  console.log('getSSOAccessToken', ds.getSSOAccessToken);

  const deferred = $q.defer();
  ds.getSSOAccessToken(authIdentifier, providerName, oldToken)
    .then(result => {
      console.log('getSSOAccessTokenTHEN');
      deferred.resolve(result);
    })
    .catch(err => {
      console.log('getSSOAccessTokenCat');
      deferred.reject(err);
    });
  return deferred.promise;
};

// get object metadata
export const getObjectMetadata = sObject => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: sObject,
    },
  };
  ds.getObjectMetadata(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get data for current object in context
export const getDataForCurrentObject = sObject => {
  const deferred = $q.defer();
  ds.getDataForCurrentObject(sObject, 'Id').then(
    result => {
      deferred.resolve(result[sObject].Id);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get user language
export const getUserLocale = () => {
  const deferred = $q.defer();
  ds.getDataForCurrentObject('User', 'LanguageLocaleKey').then(
    result => {
      deferred.resolve(result.User.LanguageLocaleKey);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get user details
export const getUserDetail = userIds => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'User',
      fields: [
        'Id',
        'Department',
        'Division',
        'JnJ_External_ID__c',
        'Territory_JNJ__c',
      ],
      where: 'Id IN ' + Utils.getInStatementArray(userIds),
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get user details
export const getUsers = userIds => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'User',
      fields: ['Id', 'Name'],
      where: 'Id IN ' + Utils.getInStatementArray(userIds),
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get account names

export const getAccountNames = accIds => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Account',
      fields: ['Id', 'Name'],
      where: 'Id IN ' + Utils.getInStatementArray(accIds),
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get start and end date
export const getStartEndDates = sellingDivision => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'JJ_Selling_Division__c',
      fields: [
        'Suggestion_Start_Date__c',
        'Suggestion_End_Date__c',
        'JJ_Wizard_URL__c',
      ],
      where: "Selling_Division_JNJ__c = '" + sellingDivision + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get recordType
export const getRecordType = (objName, rtNames) => {
  const deferred = $q.defer();
  const whereClause =
    rtNames && rtNames.length > 0
      ? "SobjectType = '" +
        objName +
        "' AND DeveloperName IN " +
        Utils.getInStatementArray(rtNames)
      : "SobjectType = '" + objName + "'";
  const queryConfig = {
    params: {
      object: 'RecordType',
      fields: ['Id', 'DeveloperName', 'Name'],
      where: whereClause,
    },
  };
  console.log('WHERE IN REC :: ', queryConfig.params.where);
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Account information
export const getAccountDetail = (acctId, parent) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Account',
      fields: [
        'Id',
        'Name',
        'Phone',
        'IMS_Best_Address_JNJ__c',
        'Specialty_1_vod__c',
        'Specialty_2_vod__c',
        'RecordTypeId',
        'External_ID_vod__c',
      ],
      where: "Id = '" + acctId + "'",
    },
  };
  queryConfig.params.fields.push(parent);
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Account tsf information
export const getTSFDetail = (acctId, sellingDivision) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'TSF_vod__c',
      fields: [
        'Account_vod__c',
        'JJ_insightReason__c',
        'JJ_Metric1__c',
        'JJ_Metric2__c',
        'JJ_Metric3__c',
        'Sales_Direction_JNJ__c',
        'Address_vod__c',
        'JJ_NextBestMessage__c',
      ],
      where:
        "Account_vod__c = '" +
        acctId +
        "' AND Territory_vod__c = '" +
        sellingDivision +
        "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Account additional address
export const getAddress = addId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Address_vod__c',
      fields: [
        'State_vod__c',
        'Zip_vod__c',
        'City_vod__c',
        'Name',
        'Address_line_2_vod__c',
      ],
      where: "Id = '" + addId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getAddress2 = accId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Address_vod__c',
      fields: [
        'State_vod__c',
        'Zip_vod__c',
        'City_vod__c',
        'Name',
        'Address_line_2_vod__c',
        'LastModifiedDate',
      ],
      where: "Account_vod__c = '" + accId + "'",
      sort: ['LastModifiedDate DESC'],
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Account name for parent account
export const getParentAccount = parentId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Account',
      fields: ['Name'],
      where: "Id = '" + parentId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// Get most recent call activity
export const getRecentCall = acctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Call2_vod__c',
      fields: 'Call_Date_vod__c',
      where:
        "Account_vod__c = '" +
        acctId +
        "' AND Call_Date_vod__c <> '' AND Status_vod__c = 'Submitted_vod'",
    },
  };
  console.log(queryConfig.params);
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get suggestions
export const getSuggestions = (acctId, recTypeIds, userId, today, past) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Suggestion_vod__c',
      fields: [
        'Id',
        'Account_vod__c',
        'Title_vod__c',
        'Priority_vod__c',
        'Reason_vod__c',
        'Posted_Date_vod__c',
        'Call_Objective_To_Date_vod__c',
        'Display_Mark_As_Complete_vod__c',
        'Display_Dismiss_vod__c',
        'Dismissed_vod__c',
        'Marked_As_Complete_vod__c',
        'JJ_InProgress__c',
        'Actioned_vod__c',
        'Expiration_Date_vod__c',
        'Account_Priority_Score_vod__c',
        'RecordTypeId',
        'OwnerId',
        'LastModifiedDate',
      ],
      where:
        'RecordTypeId IN ' +
        Utils.getInStatementArray(recTypeIds) +
        ' AND OwnerId IN ' +
        Utils.getInStatementArray(userId) +
        " AND Account_vod__c = '" +
        acctId +
        "'" +
        " AND Expiration_Date_vod__c >= '" +
        past +
        "'",
      sort: ['Account_Priority_Score_vod__c DESC', 'Posted_Date_vod__c DESC'],
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get suggestions
export const getSuggestionTags = suggestionIds => {
  const deferred = $q.defer();
  if (suggestionIds && suggestionIds.length > 0) {
    const queryConfig = {
      params: {
        object: 'Suggestion_Tag_vod__c',
        fields: ['Suggestion_vod__c', 'Product_vod__c', 'Driver_vod__c'],
        where:
          'Suggestion_vod__c IN ' + Utils.getInStatementArray(suggestionIds),
        sort: ['CreatedDate DESC'],
      },
    };
    ds.runQuery(queryConfig.params).then(
      result => {
        deferred.resolve(result.data);
      },
      err => {
        console.log(err);
        deferred.resolve(null);
      },
    );
  } else {
    deferred.resolve(null);
  }
  return deferred.promise;
};

// suggestion action
export const executeSuggestionAction = (suggestionId, actionType) => {
  const deferred = $q.defer();
  ds.executeSuggestionAction(suggestionId, actionType).then(
    result => {
      deferred.resolve(result);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getProductsById = (productIds, productTypes) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Product_vod__c',
      fields: ['Id', 'Name'],
      where:
        'Id IN ' +
        Utils.getInStatementArray(productIds) +
        ' AND Product_Type_vod__c IN ' +
        Utils.getInStatementArray(productTypes),
      sort: ['Name ASC'],
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getChannelPreferences = (acctId, channelTypes) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Stakeholder_Preference_vod__c',
      fields: [
        'Title_vod__c',
        'Type_vod__c',
        'Record_Type_Name_vod__c',
        'JJ_Value__c',
      ],
      where:
        "Account_vod__c = '" +
        acctId +
        "' AND Record_Type_Name_vod__c IN {'Channel_Preference_vod', 'Offer_Preference_vod'}",
      sort: ['Title_vod__c ASC', 'Record_Type_Name_vod__c ASC'],
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getCalls = acctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Call2_vod__c',
      fields: [
        'Id',
        'OwnerId',
        'CreatedById',
        'Call_Type_vod__c',
        'Call_Sub_Type_JNJ__c',
        'Call_Date_vod__c',
        'Is_Sampled_Call_vod__c',
        'CLM_vod__c',
        'Presentations_vod__c',
        'Call_Channel_vod__c',
      ],
      where: "Account_vod__c = '" + acctId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getEmails = (acctId, userId, past) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Sent_Email_vod__c',
      fields: [
        'Id',
        'OwnerId',
        'CreatedDate',
        'Subject_vod__c',
        'Opened_vod__c',
        'Open_Count_vod__c',
        'Clicked_vod__c',
        'Click_Count_vod__c',
      ],
      where: "Account_vod__c = '" + acctId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getMCA = acctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Multichannel_Activity_vod__c',
      fields: [
        'Id',
        'Account_vod__c',
        'CreatedDate',
        'Record_Type_Name_vod__c',
        'CreatedById',
        'Campaign_Title_JNJ__c',
      ],
      where: "Account_vod__c = '" + acctId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getInquiries = acctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Medical_Inquiry_vod__c',
      fields: ['Id', 'Account_vod__c', 'CreatedDate', 'CreatedById'],
      where: "Account_vod__c = '" + acctId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getSellingDivision = division => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'JJ_Selling_Division__c',
      fields: [
        'Suggestion_Start_Date__c',
        'Suggestion_End_Date__c',
        'JJ_Wizard_URL__c',
      ],
      where: "Selling_Division_JNJ__c = '" + division + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getSegmentations = acctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Segmentation__c',
      fields: [
        'Segment_Value__c',
        'Product__c',
        'Therapeutic_Class__c',
        'Type__c',
        'LastModifiedDate',
        'Account_JNJ__c',
      ],
      where: "Account_JNJ__c = '" + acctId + "'",
      sort: ['LastModifiedDate DESC'],
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

export const getAcctSegmentations = segmentations => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Segmentation__c',
      fields: [
        'Segment_Value__c',
        'Product__c',
        'Therapeutic_Class__c',
        'Type__c',
        'Account_JNJ__c',
      ],

      where: 'Segment_Value__c IN ' + Utils.getInStatementArray(segmentations),
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

//*************************************************************//

// get user territory TM
export const getUserTerritory = (userId, isETMEnabled) => {
  let sObject = '',
    fieldsToQuery = [];
  if (isETMEnabled == true) {
    sObject = 'UserTerritory2Association';
    fieldsToQuery.push('Territory2Id');
  } else {
    sObject = 'UserTerritory';
    fieldsToQuery.push('TerritoryId');
  }

  const queryConfig = {
    params: {
      object: sObject,
      fields: fieldsToQuery,
      where: "UserId = '" + userId + "'",
    },
  };
  const deferred = $q.defer();
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get territory
export const getTerritory = (terrIds, isETMEnabled) => {
  const sObject = isETMEnabled == true ? 'Territory2' : 'Territory';
  const queryConfig = {
    params: {
      object: sObject,
      fields: ['Id', 'Name'],
      where: "Id = '" + terrIds + "'",
    },
  };
  const deferred = $q.defer();
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get veeva messages
export const getCallPlanning = userId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'MC_Cycle_Plan_vod__c',
      fields: [
        'Channel_Interaction_Attainment_vod__c',
        'Channel_Interaction_Status_vod__c',
      ],
      where: "OwnerId = '" + userId + "'",
    },
  };
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// update record
export const updateRecord = obj => {
  const deferred = $q.defer();
  ds.updateRecord(obj).then(
    result => {
      deferred.resolve(result);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};
// new record
export const newRecord = obj => {
  const deferred = $q.defer();
  ds.newRecord(obj).then(
    result => {
      deferred.resolve(result);
    },
    err => {
      console.log(err);
      deferred.resolve(err);
    },
  );
  return deferred.promise;
};

// get veeva messages
export const getVeevaMessage = language => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Message_vod__c',
      fields: ['Name', 'Text_vod__c'],
      where:
        "Language_vod__c = '" +
        language +
        "'" +
        ' AND Category_vod__c IN ' +
        Utils.getInStatementArray(['Custom_MyInsights']),
    },
  };
  ds.queryRecord(queryConfig.params).then(
    result => {
      deferred.resolve(
        Utils.arrayToObject(result.Message_vod__c, 'Name', 'Text_vod__c'),
      );
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

/* NITRO DB CALLS */

// get Territory Shipments Pace to Quota
export const TerritoryShipPace = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_order_shipments_sales_qtrly__v',
      fields: [
        'num_shipments__v',
        'total_dirt_territory_shipment_quota__c',
        'PctToQuota_Planned__c',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'Territory'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_order_shipments_sales_qtrly__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Territory Shipments Pace to Quota
export const TerritoryReturnPace = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_order_shipments_sales_qtrly__v',
      fields: [
        'num_kit_returns__v',
        'total_dirt_territory_kit_return_quota__c',
        'PctToQuota_Planned__c',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'Territory'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_order_shipments_sales_qtrly__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get R4W Performance (Targets)
export const R4WTarget = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_rate_of_return__v',
      fields: [
        'target_non_target_flg__v',
        'num_breadth_4wk_avg__v',
        'num_depth_4wk_avg__v',
        'num_portal_hcp_26wk__v',
        'num_portal_accts_26wk__v',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'PMR Target'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_rate_of_return__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get R4W Performance (Territory)
export const R4WTerritory = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_rate_of_return__v',
      fields: [
        'target_non_target_flg__v',
        'num_breadth_4wk_avg__v',
        'num_depth_4wk_avg__v',
        'num_portal_hcp_26wk__v',
        'num_portal_accts_26wk__v',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'PMR Overlay Target'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_rate_of_return__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get R4W Performance (Territory)
export const PortalOrder = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_rate_of_return__v',
      fields: [
        'target_non_target_flg__v',
        'num_breadth_4wk_avg__v',
        'num_depth_4wk_avg__v',
        'num_portal_hcp_26wk__v',
        'num_portal_accts_26wk__v',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'Territory'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_rate_of_return__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Rescreen Data
export const Rescreen = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_provider_patient_rescreen__v',
      fields: ['cnt_rescreen_completed__v'],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        'rolling_month_num__v > 0' +
        "crm_user_id__v = '" +
        userId +
        "'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_provider_patient_rescreen__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Days on Territory Data
export const DaysTerritory = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_crm_calls_daily__v',
      fields: ['date__v', 'activity_type_es__v'],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        'rolling_qtr_num__v = 0' +
        'rolling_yr_num__v = 0' +
        'parent_call_vod__v = ""' +
        "crm_user_id__v = '" +
        userId +
        "'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_crm_calls_daily__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Target Pace Goal
export const TargetPaceGoal = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_territory_order_shipments_sales_qtrly__v',
      fields: [
        'target_non_target_flg__v',
        'num_shipments__v',
        'total_dirt_territory_shipment_quota__c',
        'PctToQuota_Planned__c',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        "crm_user_id__v = '" +
        userId +
        "'" +
        "target_non_target_flg__v = 'PMR Target'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_territory_rate_of_return__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get QTD Count
export const QTDCount = () => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'dim_rolling_date__v',
      fields: ['date__v'],
      where:
        "weekend_flag__v = 'f'" +
        'rolling_qtr_num__v = 0' +
        'rolling_yr_num__v = 0',
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.dim_rolling_date__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Attempted Activities
export const AttemptedActivities = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_crm_calls_daily__v',
      fields: ['date__v', 'activity_type_es__v'],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        'rolling_qtr_num__v = 0' +
        'rolling_yr_num__v = 0' +
        'parent_call_vod__v = ""' +
        "crm_user_id__v = '" +
        userId +
        "'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_crm_calls_daily__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Attempted Activities
export const MeaningfulActivities = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_crm_calls_daily__v',
      fields: ['date__v', 'activity_type_es__v'],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        'rolling_qtr_num__v = 0' +
        'rolling_yr_num__v = 0' +
        'parent_call_vod__v = ""' +
        "crm_user_id__v = '" +
        userId +
        "'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_crm_calls_daily__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get QTD Activies
export const QTDAct = (crmTerrName, userId) => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'fact_crm_calls_daily__v',
      fields: [
        'date__v',
        'With_Designee_ES__v',
        'pmr_target_es__v',
        'pmr_overlay_target_es__v',
        'activity_type_es__v',
        'total_call_count__v',
      ],
      where:
        "crm_territory_name__v = '" +
        crmTerrName +
        "'" +
        'total_call_count__v > 0' +
        "crm_user_id__v = '" +
        userId +
        "'",
    },
  };
  ds.queryVDSRecord(queryConfig.params).then(
    result => {
      deferred.resolve(result.fact_crm_calls_daily__v);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};

// get Best Address
export const getBestAddress = currAcctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Address_vod__c',
      fields: [
        'Id',
        'License_vod__c',
        'License_Expiration_Date_vod__c',
        'License_Status_vod__c',
        'License_Valid_To_Sample_vod__c',
        'Account_vod__c',
      ],
      where:
        "Account_vod__c = '" + currAcctId + "'" + ' AND Primary_vod__c = true',
    },
  };
  //console.log('Where:: ', queryConfig.params.where);
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};
// get Best Address
export const getBestAddressB = currAcctId => {
  const deferred = $q.defer();
  const queryConfig = {
    params: {
      object: 'Address_vod__c',
      fields: [
        'Id',
        'License_vod__c',
        'License_Expiration_Date_vod__c',
        'License_Status_vod__c',
        'License_Valid_To_Sample_vod__c',
        'Account_vod__c',
      ],
      where: "Account_vod__c = '" + currAcctId + "'",
    },
  };
  //console.log('Where:: ', queryConfig.params.where);
  ds.runQuery(queryConfig.params).then(
    result => {
      deferred.resolve(result.data);
    },
    err => {
      console.log(err);
      deferred.resolve(null);
    },
  );
  return deferred.promise;
};
