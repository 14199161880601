import axios from 'axios';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import FEEDBACK_FORM_ID from '../constants/feedbackFormId.constant';

/**
 * The function `useAddFeedbackAnswer` is a custom hook that returns a mutation function for adding a
 * feedback response.
 * @returns The `useAddFeedbackAnswer` function is returning the result of calling the `useMutation`
 * hook with the `mutationFn` function as an argument.
 */
const addFeedbackResponse = feedbackResponse => {
  const feedbackId = uuid();
  const { messageId, conversationId, response } = feedbackResponse;
  const { questionId } = feedbackResponse;
  const payload = {
    feedbackId,
    messageId,
    conversationId,
    response,
  };
  const addFeedbackURL = `feedback_question_catalog/${FEEDBACK_FORM_ID}/questions/${questionId}`;
  return axios.post(addFeedbackURL, payload);
};

const useAddFeedbackAnswer = () => {
  const mutationFn = payload => addFeedbackResponse(payload);
  return useMutation(mutationFn);
};

export default useAddFeedbackAnswer;
