import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OptionSelectCard from '../../components/OptionSelectCard/OptionSelectCard';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';
import HCPModal from '../../components/HCPModal/HCPModal';
import ConversationContext from '../../context/Conversation.context';
import {
  useAddConversation,
  useFetchConversations,
} from '../../httpRequestHooks';
import { conversationIcon } from '../../assets/icons';

/**
 * The `ConversationSelection` function is a React component that renders the conversation selection section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useCallback` hook is used to memoize the function that handles the modal open and close.
 * The `useCallback` hook is used to memoize the function that handles the new chat click.
 * @returns The `ConversationSelection` function returns a React component.
 */
function ConversationSelection() {
  const { setSelectedHcp, setIsHcpConversation, setConversationId } =
    useContext(ConversationContext);
  const { t } = useTranslation('common');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onAddHCPConversation = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { mutateAsync: addConversationAsync } = useAddConversation();
  const { refetch: refetchConversations } = useFetchConversations({
    conversationType: CONVERSATION_TYPE.GENERIC,
  });
  const defaultTitle = t('common.newConversation');

  const onAddGeneralConversation = useCallback(async () => {
    const conversation = await addConversationAsync({
      conversationTitle: defaultTitle,
    });
    setConversationId(conversation.conversationId);
    setIsHcpConversation(false);
    refetchConversations();
  }, []);

  const messageText = t('copilotLandingPage.chatArea.chatOptionHeading');

  const conversationOptions = [
    {
      type: t('common.territoryViewConversationHeading'),
      onSelect: onAddGeneralConversation,
      optionIcon: conversationIcon,
    },
    {
      type: t('common.HCPViewConversationHeading'),
      onSelect: onAddHCPConversation,
      optionIcon: conversationIcon,
    },
  ];

  return (
    <div>
      <OptionSelectCard
        messageText={messageText}
        flowOptions={conversationOptions}
      />
      <hr className="border-secondary-800" />
      {isModalOpen && (
        <HCPModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setSelectedHcp={setSelectedHcp}
          setIsHcpConversation={setIsHcpConversation}
        />
      )}
    </div>
  );
}

export default ConversationSelection;
