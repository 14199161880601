export const SCOPE_TYPE = {
  REP_ACCESS: 'rep_access',
  HCP: 'hcp',
  GENERAL: 'general',
  COPILOT: 'copilot',
};

export const DIVISION_SCOPE = {
  SD: 'rep_access',
  SR: 'rep_access',
  RM: 'rep_access',
  OI: 'general hcp',
};
