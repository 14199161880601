import React from 'react';
import { useTranslation } from 'react-i18next';
import { aiIcon } from '../../assets/icons';
import Avatar from '../Avatar/Avatar';

/* The code you provided is a React functional component called `DefaultAIMessage`. It is a component
that renders a default message from an AI (Artificial Intelligence) chatbot. */
function DefaultAIMessage() {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-row my-4">
      <Avatar srcImage={aiIcon} />
      <div className="flex flex-col ml-3 w-full">
        <div className="border p-3 flex-1 bg-secondary-100">
          <p>{t('defaultAltMessage')}</p>
        </div>
      </div>
    </div>
  );
}

export default DefaultAIMessage;
