import React from 'react';
import PropTypes from 'prop-types';
import Error from '.';

/* The code is defining a React component called `ErrorFallbackComponent`. This component takes in a
prop called `error` and renders an `Error` component with the `error` prop and an additional prop
called `errorMessage` which is set to `error?.message`. */
function ErrorFallbackComponent({ error }) {
  return <Error error={error} errorMessage={error?.message} />;
}

ErrorFallbackComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

ErrorFallbackComponent.defaultProps = {
  error: {
    message: null,
  },
};

export default ErrorFallbackComponent;
