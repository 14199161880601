import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The function `useFetchBookmarks` is a custom hook that uses the `useQuery` hook to fetch bookmark
 * data from a specified URL.
 * @returns The `useFetchBookmarks` function is returning the result of the `useQuery` hook.
 */
const fetchBookmarkData = () => {
  const fetchBookmarkURL = 'bookmark';
  return axios.get(fetchBookmarkURL).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFetchBookmarks = () => {
  const queryKey = 'bookmarks';
  const queryConfig = { enabled: false };
  return useQuery(queryKey, fetchBookmarkData, queryConfig);
};

export default useFetchBookmarks;
