const handleScrollToElementId = elementId => {
  /**
   * @param {string} elementId
   * @returns {HTMLElement} - The element with the given id
   */
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'auto' });
  }
  return element;
};
export default handleScrollToElementId;
