/* eslint-disable no-useless-return */
/* eslint-disable no-console */
import { get, isNil } from 'lodash';
import ADMIN_USERS from '../../../constants/adminUserOnVeeva.constant';
import { DIVISION_SCOPE } from '../../../constants/scope.constant';
import {
  getSSOAccessToken,
  refreshSSOAccessToken,
} from '../../lib/myInsights/query';
import { getUserInfo } from '../../lib/myInsights';
import { AuthProvider } from '../AuthProvider/AuthProvider';
import VeevaSecrets from './VeevaSecrets';
import VeevaStore from './VeevaStore';

class Veeva extends AuthProvider {
  getCurrentAccessToken() {
    const veevaStore = new VeevaStore();
    return veevaStore.getAccessToken();
  }

  getProfile() {
    const veevaStore = new VeevaStore();
    return veevaStore.getProfile();
  }

  getScope() {
    const veevaStore = new VeevaStore();
    return veevaStore.getScope();
  }

  getSwitchUser() {
    const veevaStore = new VeevaStore();
    return veevaStore.getSwitchUser();
  }

  refetchToken() {
    if (this.isRefreshing) return;
    const veevaStore = new VeevaStore();
    const expiredToken = veevaStore.getAccessToken();

    if (expiredToken) {
      this.isRefreshing = true;
      refreshSSOAccessToken('PingOne_Oauth', 'Open Id Connect', expiredToken)
        .then(accessTokenResponse => {
          accessTokenResponse.data.token.replace('"', '');
          this.isRefreshing = false;
          this.#manageTokenResponse(accessTokenResponse);
        })
        .catch(() => {
          this.isRefreshing = false;
          this.logout();
        });
    }
  }

  async #manageTokenResponse(tokenResponse) {
    const veevaStore = new VeevaStore();

    const accessToken = get(tokenResponse, 'data.token', null);
    veevaStore.storeAccessToken(accessToken);

    const profile = VeevaSecrets.decodeAccessToken(accessToken);

    veevaStore.storeProfile(profile);
    await this.#manageScope();

    this.isLogin = true;
  }

  async #manageScope() {
    const veevaStore = new VeevaStore();
    const userDetails = await getUserInfo();

    const scope = get(userDetails, '[0].Division.value', null);
    const repId = get(userDetails, '[0].JnJ_External_ID__c.value', null);
    const profileScope = DIVISION_SCOPE[scope];

    veevaStore.storeScope(profileScope);

    const isSwitchUser = ADMIN_USERS.includes(repId) ? 'enabled' : 'disabled';
    veevaStore.storeSwitchUser(isSwitchUser);
  }

  logout() {
    this.isLogin = false;
    const veevaStore = new VeevaStore();
    veevaStore.clearTokens();
  }

  async #flowStep1GetAccessToken() {
    try {
      const accessToken = await getSSOAccessToken(
        VeevaSecrets.authIdentifier,
        VeevaSecrets.providerName,
      );
      accessToken.data.token.replace('"', '');
      await this.#manageTokenResponse(accessToken);
    } catch {
      this.logout();
    }
  }

  #flowStep2CheckAccessToken() {
    const veevaStore = new VeevaStore();
    const accessToken = veevaStore.getAccessToken();

    if (isNil(accessToken)) {
      this.logout();
    } else {
      this.isLogin = true;
    }
  }

  #flowStep3ScheduleRefresh() {
    setInterval(() => {
      this.refetchToken();
    }, VeevaSecrets.refreshInterval);
  }

  #flowStep4MonitorLogout() {
    window.addEventListener('storage', () => {
      const veevaStore = new VeevaStore();
      const accessToken = veevaStore.getAccessToken();

      if (isNil(accessToken)) {
        this.logout();
      } else {
        this.isLogin = true;
      }
    });
  }

  async authenticate() {
    await this.#flowStep1GetAccessToken();

    this.#flowStep2CheckAccessToken();

    this.#flowStep3ScheduleRefresh();

    this.#flowStep4MonitorLogout();
  }
}

export default new Veeva();
