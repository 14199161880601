const drawSendIcon = (color = '#F3F4F6') => {
  /**
   * The function `drawSendIcon` generates a SVG image of a send icon with customizable color, and the
   * resulting image is encoded in base64 format.
   * @param [color=#F3F4F6] - The `color` parameter is a string that represents the color of the circle
   * in the SVG image. It is set to `'#F3F4F6'` by default, but you can pass a different color value to
   * the `drawSendIcon` function if desired.
   * @returns The `drawSendIcon` function returns a base64-encoded SVG image string.
   */
  const svgString = `
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="${color}"/>
    <path d="M34 14L23 25M34 14L27 34L23 25M34 14L14 21L23 25" stroke="#333333" stroke-opacity="0.5" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const sendHover = drawSendIcon('#F3F4F6');
export const sendInactive = drawSendIcon('#DBDCDD');
