/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { aiIcon } from '../../assets/icons';
import Avatar from '../Avatar/Avatar';
import { SCOPE_TYPE } from '../../constants/scope.constant';

function OptionSelectCard({ messageText, flowOptions, scope }) {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-row my-4">
      <Avatar className="w-12 h-12" srcImage={aiIcon} />
      <div className="flex flex-col ml-3 w-full">
        <div className="border p-3 flex-1 bg-secondary-100">
          <p>{messageText}</p>
          <div
            className={classnames(
              'bg-card-500 p-3 rounded-lg mt-2 cursor-pointer',
              {
                'flex gap-x-3': scope === SCOPE_TYPE.REP_ACCESS,
              },
            )}
          >
            {flowOptions.map((conversation, index) => (
              <div
                data-testid={`conversation-option-${index}`}
                className="flex flex-row justify-between border p-3 flex-1 mb-1 rounded-lg"
                onClick={conversation.onSelect}
                key={conversation.type}
              >
                <div className="w-3/4">
                  <p>{conversation.type}</p>
                  <div className="text-secondary-1000 opacity-70">
                    <h6>{conversation.metaData}</h6>
                  </div>
                </div>
                <img
                  src={conversation.optionIcon}
                  alt={t('common.chat')}
                  className="max-w-[24px]"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

OptionSelectCard.propTypes = {
  messageText: PropTypes.string.isRequired,
  scope: PropTypes.string,
  flowOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
      optionIcon: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

OptionSelectCard.defaultProps = {
  scope: SCOPE_TYPE.COPILOT,
};

export default OptionSelectCard;
