import React from 'react';
import PropTypes from 'prop-types';

/**
 * The Avatar component is a React component that displays an image with an optional custom class.
 * @returns The Avatar component is returning a div element with a className that is a combination of
 * "mx-2" and the className prop passed in. Inside the div, there is an img element with the src set to
 * the srcImage prop and alt set to "avatar".
 */
function Avatar({ srcImage, className }) {
  return (
    <div className={`mx-2 ${className}`}>
      <img src={srcImage} alt="avatar" />
    </div>
  );
}

Avatar.propTypes = {
  srcImage: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
};

export default Avatar;
