import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';
import MultiSelectTiles from './MultiSelectTiles';
import JSON_QUES_TYPE from '../../constants/jsonQuestionType';

function SpecialMessage(props) {
  const { specialMessage, isSelectionPermitted } = props;
  const { onCancel, onSubmit } = props;
  const behaviors = get(specialMessage, 'behaviors', []);
  const [behaviorsData, setBehaviorsData] = useState(behaviors);
  const { t } = useTranslation('common');

  const [selectAllText, setSelectAllText] = useState({
    text: t('preCallPlanner.buttons.selectAll'),
    select: true,
  });

  useEffect(() => {
    const selected = behaviors.map(behavior =>
      behavior.behaviorOptions.map(bs => bs.isSelected),
    );
    if (selected.flat().some(sel => sel === false)) {
      setSelectAllText({
        text: t('preCallPlanner.buttons.selectAll'),
        select: true,
      });
    } else {
      setSelectAllText({
        text: t('preCallPlanner.buttons.deselectAll'),
        select: false,
      });
    }
  }, []);

  const onSelectAll = () => {
    if (isSelectionPermitted === false) return;
    const updatedBehavior = behaviorsData.map(behavior => {
      const currentOptions = get(behavior, 'behaviorOptions', '');
      const updatedOptions = currentOptions.map(option => ({
        ...option,
        isSelected: selectAllText.select,
      }));
      return {
        ...behavior,
        behaviorOptions: updatedOptions,
      };
    });
    setBehaviorsData(updatedBehavior);
    if (selectAllText.select === true) {
      setSelectAllText({
        text: t('preCallPlanner.buttons.deselectAll'),
        select: false,
      });
    } else {
      setSelectAllText({
        text: t('preCallPlanner.buttons.selectAll'),
        select: true,
      });
    }
  };

  return (
    <div className="border flex-1 p-3 bg-secondary-100">
      <p>{specialMessage.body}</p>

      <MultiSelectTiles
        behaviorsData={behaviorsData}
        setBehaviorsData={setBehaviorsData}
        isSelectionPermitted={isSelectionPermitted}
        setSelectAllText={setSelectAllText}
      />
      {isSelectionPermitted && (
        <div className="flex flex-row">
          <Button
            dataTestid="select-all-button"
            onClick={onSelectAll}
            content={selectAllText.text}
            buttonType="secondary"
            className="w-32 bg-white mt-3 mr-2"
          />
          <Button
            dataTestid="submit-button"
            content={t('preCallPlanner.buttons.submit')}
            onClick={() => onSubmit(behaviorsData, isSelectionPermitted)}
            buttonType="secondary"
            className="w-24 bg-white mt-3 mr-2"
          />
          <Button
            dataTestid="cancel-button"
            content={t('preCallPlanner.buttons.cancel')}
            onClick={onCancel}
            buttonType="secondary"
            className="w-24 mt-3"
          />
        </div>
      )}
    </div>
  );
}

SpecialMessage.propTypes = {
  isSelectionPermitted: PropTypes.bool,
  specialMessage: JSON_QUES_TYPE.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
SpecialMessage.defaultProps = {
  isSelectionPermitted: false,
  onSubmit: () => {},
  onCancel: () => {},
};

export default SpecialMessage;
