import React, { useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import ConversationHistoryItem from '../../components/ConversationHistoryItem/ConversationHistoryItem';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error';
import {
  useDeleteConversation,
  useEditConversation,
  useFetchBookmarks,
  useFetchConversations,
} from '../../httpRequestHooks';
import ConversationContext from '../../context/Conversation.context';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';

/**
 * The `GeneralChatHistory` function is a React component that renders the general chat history section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useContext` hook to get the conversation id, selected HCP, and set the conversation id.
 * The `useFetchConversations` hook is used to fetch the conversations.
 * The `useFetchBookmarks` hook is used to fetch the bookmarks.
 * The `useDeleteConversation` hook is used to delete the conversation.
 * The `useEditConversation` hook is used to edit the conversation.
 * The `useEffect` hook is used to refetch the conversations.
 * @returns The `GeneralChatHistory` function returns a React component.
 * The `GeneralChatHistory` function renders the general chat history section.
 */
function GeneralChatHistory() {
  const { conversationId } = useContext(ConversationContext);
  const { setConversationId, setIsHcpConversation, setSelectedHcp } =
    useContext(ConversationContext);
  const { t } = useTranslation('common');

  const conversationType = CONVERSATION_TYPE.GENERIC;
  const {
    isLoading: isConversationsLoading,
    data: conversationData,
    isSuccess: isConversationsSuccess,
    isError: isConversationsError,
    error: conversationsError,
    refetch: refetchConversations,
  } = useFetchConversations({ conversationType });

  useEffect(() => {
    refetchConversations();
  }, []);

  const onSelectingConversation = useCallback(selectedId => {
    setConversationId(selectedId);
    setIsHcpConversation(false);
  }, []);

  const { refetch: refetchBookmarks } = useFetchBookmarks();

  const onDeleteSuccess = useCallback(() => {
    refetchConversations();
    refetchBookmarks();
    setConversationId('');
    setSelectedHcp(null);
    setIsHcpConversation(null);
  }, []);

  const { mutateAsync: deleteConversation } =
    useDeleteConversation(onDeleteSuccess);

  const { mutateAsync: editConversation, isEditSuccess } =
    useEditConversation();

  const onRename = useCallback(async (currentConversationId, inputText) => {
    await editConversation({
      title: inputText,
      conversationId: currentConversationId,
    });
    return isEditSuccess;
  }, []);

  const onDelete = useCallback(currentConversationId => {
    deleteConversation({ conversationId: currentConversationId });
  }, []);

  return (
    <div className="max-h-1/2 flex flex-col">
      <h3>{t('common.territoryViewConversationHeading')}</h3>
      <hr className="border-secondary-800" />

      {isConversationsLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isConversationsError && <Error error={conversationsError} />}
      {isConversationsSuccess && (
        <div className="h-full overflow-y-auto my-2 mb-8">
          <div className="flex flex-col-reverse w-full pl-auto self-start my-2">
            {conversationData.map(conversation => {
              const title = get(conversation, 'title', '');
              const updatedAt = get(conversation, 'updatedAt', '');
              const currentConversationId = get(
                conversation,
                'conversationId',
                '',
              );
              return (
                <ConversationHistoryItem
                  key={`conversation-list-item-${currentConversationId}-${updatedAt}`}
                  conversationId={conversationId}
                  conversationTitle={title}
                  currentConversationId={currentConversationId}
                  isSelectedConversation={
                    conversationId === currentConversationId
                  }
                  onSelectingConversation={() =>
                    onSelectingConversation(currentConversationId)
                  }
                  onRename={onRename}
                  onDelete={onDelete}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneralChatHistory;
