/* eslint-disable no-console */
import { get } from 'lodash';
import AxiosHttpErrors from '../errors/axiosHttpError';

const MAX_FAILURE_COUNT = 20;

const retryConfiguration = (failureCount, error) => {
  const ALLOWED_ERROR_CODES = [
    '500',
    '501',
    '502',
    '503',
    '504',
    '401',
    undefined,
  ];

  const statusCode = get(error, 'statusCode', '500');
  if (
    error instanceof AxiosHttpErrors &&
    !ALLOWED_ERROR_CODES.includes(`${statusCode}`)
  ) {
    return false;
  }

  if (failureCount > MAX_FAILURE_COUNT - 1) {
    return false;
  }

  return true;
};

const BASIC_CACHE = {
  staleTime: 0,
  refetchInterval: 1,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  refetchOnMount: true,
  retry: retryConfiguration,
};

export default BASIC_CACHE;
