import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'react-toggle-button';
import { useTranslation } from 'react-i18next';
import { SCOPE_TYPE } from '../../constants/scope.constant';
import authFactory from '../../utility/auth/authFactory';
import SwitchUser from '../SwitchUser/SwitchUser';
import { useFetchRepData } from '../../httpRequestHooks';

function AdminToolsInner({ setConversationTool }) {
  const { t } = useTranslation('common');
  const auth = authFactory();
  const authScope = auth.getScope();

  // Temporary toggle for testing until landing screen for applications is built
  const onSwitchApp = () => {
    const scopeChange = authScope.includes(SCOPE_TYPE.REP_ACCESS)
      ? `${SCOPE_TYPE.GENERAL} ${SCOPE_TYPE.HCP}`
      : SCOPE_TYPE.REP_ACCESS;
    localStorage.setItem('currentAuthScope', scopeChange);
    setConversationTool(
      scopeChange === 'rep_access' ? scopeChange : SCOPE_TYPE.COPILOT,
    );
  };

  const { refetch: refetchRepsData } = useFetchRepData();

  useEffect(() => {
    // load data for SwitchUser component
    refetchRepsData();
  }, []);

  return (
    <div className="flex flex-row" style={{ alignItems: 'center' }}>
      <div className="m-3">{t('button.switchApp')}</div>
      <ToggleButton
        activeLabel={t('toggle.repAccess')}
        inactiveLabel={t('toggle.repCopilot')}
        onToggle={onSwitchApp}
        value={authScope.includes(SCOPE_TYPE.REP_ACCESS)}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <SwitchUser />
      </div>
    </div>
  );
}

AdminToolsInner.propTypes = {
  setConversationTool: PropTypes.func.isRequired,
};

/**
 * The `AdminTools` function is a React component that renders the admin tools section.
 * @returns The `AdminTools` function returns a React component.
 */
function AdminTools({ setConversationTool }) {
  const auth = authFactory();
  const switchUserAccess = auth.getSwitchUser() || 'disabled';
  const isSwitchUserEnabled = `${process.env.REACT_APP_ENABLE_USER_SWITCH}`;

  if (switchUserAccess === 'enabled' && isSwitchUserEnabled === 'enabled') {
    return <AdminToolsInner setConversationTool={setConversationTool} />;
  }
  return null;
}

AdminTools.propTypes = {
  setConversationTool: PropTypes.func.isRequired,
};

export default AdminTools;
