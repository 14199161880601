import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sendHover, sendInactive } from '../../assets/icons/sendIconGenerator';
import { sendActive } from '../../assets/icons';

/**
 * The `InputChat` component is a React
 * component that renders an input field
 * and a send button,
 * allowing users to enter text
 * and submit it.
 * @returns The `InputChat`
 * component is being returned.
 * @param {object} props The `InputChat`
 * component takes in several props,
 * including `isInputDisabled`,
 * `onSubmit`, and `onChange`.
 * The `isInputDisabled` prop is a boolean
 */
function InputChat(props) {
  const { t } = useTranslation('common');
  const { onSubmit, onChange } = props;

  const { isInputDisabled } = props;

  const [inputText, setInputText] = useState('');
  const [sendIcon, setSendIcon] = useState(sendInactive);

  useEffect(() => {
    onChange(inputText);
  }, [inputText]);

  const onChangeHandler = useCallback(event => {
    setInputText(event.target.value);
  }, []);

  const onKeyupHandler = useCallback(
    clickEvent => {
      if (clickEvent.key === 'Enter') {
        if (inputText.length === 0) return;
        onSubmit(inputText);
      }
    },
    [inputText, onSubmit],
  );

  const onSubmitHandler = useCallback(() => {
    if (inputText.length === 0) return;
    onSubmit(inputText);
  }, [inputText, onSubmit]);

  const isSendDisabled = isInputDisabled || !inputText;

  const handleMouseOver = useCallback(() => {
    const icon = isSendDisabled ? sendHover : sendActive;
    setSendIcon(icon);
  }, [isSendDisabled]);

  const handleMouseOut = useCallback(() => {
    const icon = isSendDisabled ? sendInactive : sendActive;
    setSendIcon(icon);
  }, [isSendDisabled]);

  useEffect(() => {
    const icon = isSendDisabled ? sendInactive : sendActive;
    setSendIcon(icon);
  }, [isSendDisabled]);

  return (
    <div className="flex flex-row w-full">
      <div className="w-full">
        <input
          data-testid="input-chat"
          name={t('input.label')}
          disabled={isInputDisabled}
          value={inputText}
          placeholder={`${t('input.label')}...`}
          className={`w-full focus:outline-none bg-white ${
            isInputDisabled ? 'cursor-not-allowed' : ''
          }`}
          onChange={onChangeHandler}
          onKeyUp={onKeyupHandler}
          autoComplete="off"
        />
        <hr
          className={`my-2 border ${
            isInputDisabled ? 'border-secondary-800' : 'border-primary-800'
          }`}
        />
      </div>
      <img
        src={sendIcon}
        alt="send"
        onClick={onSubmitHandler}
        className={`ml-4 ${
          isSendDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleMouseOver}
        onBlur={handleMouseOut}
      />
    </div>
  );
}

InputChat.propTypes = {
  isInputDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
};

InputChat.defaultProps = {
  isInputDisabled: false,
  onSubmit: () => {},
  onChange: () => {},
};
export default InputChat;
