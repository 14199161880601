import classnames from 'classnames';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { expand } from '../../assets/icons';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import ConversationContext from '../../context/Conversation.context';
import { useAddConversation, useChatsData } from '../../httpRequestHooks';
import { getSummaryCardData } from '../../utility/fetchRepAccessAnswers';

function RepAccessHistory() {
  const {
    conversationId,
    setConversationId,
    currentChat,
    setCurrentChat,
    sections,
  } = useContext(ConversationContext);

  const { t } = useTranslation('common');

  const [summaryCardData, setSummaryCardData] = useState({});

  const { isLoading: isAddConversationLoading } = useAddConversation();
  const { data: allChatsData } = useChatsData(conversationId);

  const onRestart = () => {
    setConversationId(null);
  };

  useEffect(() => {
    if (Array.isArray(allChatsData) && allChatsData.length !== 0) {
      const summaryDetails = getSummaryCardData(allChatsData);
      const result = summaryDetails.reduce((acc, item) => {
        acc[item.questionsection] = acc[item.questionsection] || [];
        acc[item.questionsection].push(item);
        return acc;
      }, Object.create(null));
      setSummaryCardData(result);
    }
  }, [allChatsData]);

  return (
    <div className="flex flex-col h-full">
      {sections.map(section => {
        const isActiveChat = section.sectionId === currentChat;
        return (
          <div
            className={`flex flex-col ${
              isActiveChat ? 'flex-grow overflow-y-auto' : ''
            } bg-secondary-500 py-2 px-4 mb-2`}
            key={section.sectionId}
          >
            <div className={`flex flex-col ${isActiveChat ? '' : 'py-2'}`}>
              <div
                className={`flex flex-row justify-between content-between items-center w-full ${
                  isActiveChat ? '' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (!isActiveChat) {
                    setCurrentChat(section.sectionId);
                    section.onSelect();
                  }
                }}
                data-testid="rep-access-section"
              >
                <h3>{section.sectionName}</h3>
                {!isActiveChat ? (
                  <img src={expand} className="content-between" alt="expand" />
                ) : (
                  <Button
                    className="capitalize h-9"
                    content={t('button.restart')}
                    dataTestid="restart"
                    onClick={() => {
                      if (conversationId) {
                        onRestart();
                      }
                    }}
                    isDisabled={!conversationId}
                  />
                )}
              </div>
              {isActiveChat && <hr className="border-secondary-800 mt-2" />}
            </div>

            {isActiveChat &&
              (isAddConversationLoading ||
              (allChatsData && allChatsData.length === 0) ? (
                <Loader />
              ) : (
                <div
                  className={classnames(
                    'p-4 w-full mt-5 rounded-lg gap-y-[10px] flex flex-col bg-white overflow-y-auto',
                    {
                      hidden: Object.keys(summaryCardData).length === 0,
                    },
                  )}
                >
                  {Object.keys(summaryCardData)?.map((summary, index) => (
                    <div
                      className={classnames({
                        'pb-3':
                          index !== Object.keys(summaryCardData).length - 1,
                      })}
                      key={summary}
                    >
                      {summaryCardData[summary].map(summaryDetails => (
                        <div
                          key={summaryDetails?.optiontext}
                          className="flex justify-between mb-2 last:mb-0"
                        >
                          <p className="text-textColor-meta float-left w-2/5 break-words">
                            {summaryDetails?.questionSummaryText}
                          </p>
                          <p
                            className="max-w-[124px] float-left w-2/5 break-words"
                            title={summaryDetails?.optiontext}
                          >
                            {summaryDetails?.optiontext}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
}

export default RepAccessHistory;
