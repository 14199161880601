import { get, isNil } from 'lodash';
import PingIdStore from './PingIdStore';
import PingIdSecrets from './PingIdSecrets';
import PingIdRouter from './PingIdRouter';
import PingIdMiddlewareRequests from './PingIdMiddlewareRequests';
import { AuthProvider } from '../AuthProvider/AuthProvider';

/**
 * @class PingId
 * @description This class is responsible for managing the authentication flow.
 * It will check if the user is authenticated and if not it will redirect the user to the login page.
 * It will also monitor the user's authentication status and will redirect the user to the login page if the user is logged out.
 * @example
 * import PingId from 'utility/auth/PingId';
 * PingId.authenticate();
 * @example
 * import PingId from 'utility/auth/PingId';
 * PingId.addLoginCallback((isLogin) => {
 *  if (isLogin) {
 *   // Do something when the user is logged in
 * } else {
 *  // Do something when the user is logged out
 * }
 */
class PingId extends AuthProvider {
  #isMonitoring = false;

  #manageTokenResponse(tokenResponse) {
    this.isLogin = true;
    const pingIdStore = new PingIdStore();
    const accessToken = get(tokenResponse, 'data.access_token', null);
    pingIdStore.storeAccessToken(accessToken);

    const refreshToken = get(tokenResponse, 'data.refresh_token', null);
    pingIdStore.storeRefreshToken(refreshToken);

    const profile = PingIdSecrets.decodeAccessToken(accessToken);
    pingIdStore.storeProfile(profile);

    const scope = get(tokenResponse, 'data.scope', null);
    const switchUser = get(tokenResponse, 'data.switchUser', null);

    pingIdStore.storeScope(scope);
    pingIdStore.storeSwitchUser(switchUser);
  }

  async #flowStep1CheckAuthToken() {
    const authToken = PingIdRouter.getAuthToken();
    if (authToken) {
      try {
        const tokenResponse =
          await PingIdMiddlewareRequests.generateAccessAndRefreshToken(
            authToken,
          );
        this.#manageTokenResponse(tokenResponse);
        PingIdRouter.removeAuthTokenFromUrl();
      } catch {
        this.logout();
      }
    }
  }

  #flowStep2CheckAccessToken() {
    const pingIdStore = new PingIdStore();
    const accessToken = pingIdStore.getAccessToken();

    if (isNil(accessToken)) {
      this.logout();
    } else {
      this.isLogin = true;
    }
  }

  refetchToken() {
    if (this.isRefreshing) return;
    const pingIdStore = new PingIdStore();
    const refreshToken = pingIdStore.getRefreshToken();

    if (refreshToken) {
      this.isRefreshing = true;
      PingIdMiddlewareRequests.reGenerateAccessAndRefreshToken(refreshToken)
        .then(tokenResponse => {
          this.isRefreshing = false;
          this.#manageTokenResponse(tokenResponse);
        })
        .catch(() => {
          this.isRefreshing = false;
          this.logout();
        });
    }
  }

  #flowStep3ScheduleRefresh() {
    setInterval(() => {
      this.refetchToken();
    }, PingIdSecrets.refreshInterval);
  }

  #flowStep4MonitorLogout() {
    window.addEventListener('storage', () => {
      const pingIdStore = new PingIdStore();
      const accessToken = pingIdStore.getAccessToken();

      if (isNil(accessToken)) {
        this.logout();
      } else {
        this.isLogin = true;
      }
    });
  }

  getCurrentAccessToken() {
    const pingIdStore = new PingIdStore();
    return pingIdStore.getAccessToken();
  }

  getProfile() {
    const pingIdStore = new PingIdStore();
    return pingIdStore.getProfile();
  }

  getScope() {
    const pingIdStore = new PingIdStore();
    return pingIdStore.getScope();
  }

  getSwitchUser() {
    const pingIdStore = new PingIdStore();
    return pingIdStore.getSwitchUser();
  }

  logout() {
    this.isLogin = false;
    const pingIdStore = new PingIdStore();
    pingIdStore.clearTokens();
    const url = new URL(window.location.href);
    const isLoggedOut = url.searchParams.get('isLoggedOut');
    if (!isLoggedOut) {
      PingIdRouter.redirectToLogin();
    }
  }

  async authenticate() {
    if (this.#isMonitoring) return;

    await this.#flowStep1CheckAuthToken();

    this.#flowStep2CheckAccessToken();

    this.#flowStep3ScheduleRefresh();

    this.#flowStep4MonitorLogout();
  }
}

export default new PingId();
