/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button/Button';
import {
  useAddConversation,
  useAddUserMessage,
  useChatsData,
} from '../../httpRequestHooks';
import ConversationContext from '../../context/Conversation.context';
import OptionListPlanModal from '../OptionListPlanModal/OptionListPlanModal';
import { repAccessAnswer } from '../../utility/fetchRepAccessAnswers';
import DateTimeField from '../DateTimeField/DateTimeField';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';
/**
 * The `RepAccessChatMessageDisplayCard` component is a React component that displays a chat message, with an
 * optional typing effect.
 * @returns The `RepAccessChatMessageDisplayCard` component is returning a `div` element. Inside the `div`,
 * there is a conditional rendering of either the `TypingEffect` component or a `p` element, depending
 * on the value of the `typeEffect` prop. The `TypingEffect` component is rendered if `typeEffect` is
 * truthy, and the `p` element is rendered
 */
function RepAccessChatMessageDisplayCard(props) {
  const {
    isUserMessage,
    currentAIQuestion,
    oldAnswerContext,
    isActive,
    nextAIQuestion,
    chatDialogContent,
    messageBody,
    messageCreatedAt,
  } = props;
  let repAccessMessage = '';
  const nextQuestion = currentAIQuestion?.[0]?.next_question;

  if (!isUserMessage) {
    repAccessMessage = nextQuestion
      ? nextQuestion?.questiontext?.replace(/\n/g, '<br />')
      : messageBody?.body;
  } else {
    repAccessMessage = chatDialogContent?.replace(/\n/g, '<br />');
  }

  const { conversationId, currentChat, setConversationId } =
    useContext(ConversationContext);

  const { mutateAsync: addUserMessageAsync } = useAddUserMessage();
  const [selectedPlan, setSelectedPlan] = useState({});
  const { refetch: refetchChatData } = useChatsData(conversationId);
  const {
    mutateAsync: addConversationAsync,
    data: newConversationData,
    isSuccess: isConversationAdded,
  } = useAddConversation();

  const getSelectedOption = () => {
    const questionKey = get(
      currentAIQuestion,
      '[0]next_question.questionkey',
      null,
    );
    return get(nextAIQuestion, `jsonContext.access_context.${questionKey}`);
  };

  useEffect(() => {
    setSelectedPlan(() => {
      if (!isActive && nextAIQuestion?.jsonContext?.access_context) {
        const answerKey = getSelectedOption();
        return repAccessAnswer(answerKey, currentAIQuestion);
      }
      return {};
    });
  }, []);

  useEffect(() => {
    if (isConversationAdded) {
      setConversationId(newConversationData.conversationId);
    }
  }, [isConversationAdded]);

  const onClose = async () => {
    await addConversationAsync({
      conversationTitle: currentChat,
      conversationType: CONVERSATION_TYPE.REP_ACCESS,
    });
  };

  const selectionHappens = async (question, selectedOption) => {
    await addUserMessageAsync({
      conversationId,
      message: {
        body: question,
        jsonContext: {
          access_context: {
            ...oldAnswerContext?.jsonContext?.access_context,
            [nextQuestion.questionkey]: selectedOption,
          },
          app: currentChat,
        },
      },
      messageType: 'json',
      questionId: currentChat,
    });
    refetchChatData();
  };

  const isSelected = optionkey => {
    if (!isActive && !isUserMessage) {
      const answerKey = getSelectedOption();

      return optionkey === answerKey;
    }
    return false;
  };

  return (
    <div className="flex flex-col">
      <div
        className={classnames('border flex-1 p-3', {
          'bg-card-500': isUserMessage,
          'bg-secondary-100': !isUserMessage,
        })}
        data-testid="rep-access-chat-message"
      >
        <p
          className={classnames('text-[#888781] text-xs mb-[10px] uppercase', {
            hidden: !nextQuestion?.questionsection,
          })}
        >
          {nextQuestion?.questionsection}
        </p>
        <p dangerouslySetInnerHTML={{ __html: repAccessMessage }} />
        <div
          className={classnames('flex gap-x-3 flex-wrap', {
            'pointer-events-none': !isActive,
          })}
        >
          {!isUserMessage && nextQuestion?.questiontype === 'end' && (
            <div className="flex flex-col">
              <Button
                content="Close"
                onClick={onClose}
                buttonType="tertiary"
                className="mt-3 w-fit min-w-[170px] max-w-full"
                isHoverActive
              />
            </div>
          )}
          {!isUserMessage &&
            nextQuestion?.questiontype === 'radio' &&
            nextQuestion?.questionoptions.map(item => (
              <Button
                content={item.optiontext}
                onClick={() =>
                  selectionHappens(nextQuestion?.questiontext, item.optionkey)
                }
                buttonType="tertiary"
                className="mt-3 w-fit min-w-[170px]"
                isHoverActive
                isSelected={isSelected(item.optionkey)}
                key={item.optionkey}
              />
            ))}
          {!isUserMessage && nextQuestion?.questiontype === 'list' && (
            <div className="flex flex-col">
              <div className="flex gap-x-3">
                <OptionListPlanModal
                  optionListData={nextQuestion?.questionoptions}
                  setSelectedPlan={setSelectedPlan}
                  selectedPlan={selectedPlan}
                />
                <Button
                  content="Proceed"
                  onClick={() =>
                    selectionHappens(
                      nextQuestion?.questiontext,
                      selectedPlan?.optionkey,
                    )
                  }
                  buttonType="tertiary"
                  className="mt-3 w-fit min-w-[170px]"
                  isHoverActive
                  isDisabled={
                    typeof selectedPlan === 'object' &&
                    Object.keys(selectedPlan).length === 0
                  }
                />
              </div>
              <div className="flex gap-x-3">
                {nextQuestion?.questionalternativeoptions?.map(item => (
                  <Button
                    content={item.optiontext}
                    onClick={() =>
                      selectionHappens(
                        nextQuestion?.questiontext,
                        item.optionkey,
                      )
                    }
                    buttonType="tertiary"
                    className="mt-3 w-fit min-w-[170px]"
                    isHoverActive
                    isSelected={isSelected(item.optionkey)}
                    key={item.optionkey}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <DateTimeField messageCreation={messageCreatedAt} />
    </div>
  );
}

RepAccessChatMessageDisplayCard.propTypes = {
  isUserMessage: PropTypes.bool.isRequired,
  currentAIQuestion: PropTypes.arrayOf(
    PropTypes.shape({
      next_question: PropTypes.shape({
        questiontext: PropTypes.string.isRequired,
        questiontype: PropTypes.string.isRequired,
        questionsection: PropTypes.string.isRequired,
        questionoptions: PropTypes.arrayOf(
          PropTypes.shape({
            optiontext: PropTypes.string.isRequired,
            optionexplanation: PropTypes.string.isRequired,
          }),
        ),
      }),
    }),
  ).isRequired,
  oldAnswerContext: PropTypes.shape({
    jsonContext: PropTypes.shape({
      access_context: PropTypes.shape({
        answerText: PropTypes.string.isRequired,
      }),
    }),
  }),
  chatDialogContent: PropTypes.string.isRequired,
};

RepAccessChatMessageDisplayCard.defaultProps = {
  oldAnswerContext: {
    jsonContext: {
      access_context: {},
    },
  },
};

export default RepAccessChatMessageDisplayCard;
