import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { closeButton } from '../../assets/icons';
import CaretDown from '../../assets/icons/CaretDown.svg';
/**
 * The code is defining a
 * React functional component
 * called `OptionListPlanModal`.
 * It is a modal component that
 * displays a search input field
 * and a list of healthcare professionals
 * (Insurance Plan) based on the search input.
 * The user can select an Insurance Plan from the list,
 * and the selected Insurance Plan will be passed
 * to the `setSelectedPlan` function and
 * the `setSelectedPlan` function.
 * The modal can be opened or closed by
 * setting the `isModalOpen` state and
 * calling the `setIsModalOpen` function.
 */
function OptionListPlanModal({
  optionListData,
  selectedPlan,
  setSelectedPlan,
}) {
  const { t } = useTranslation('common');
  const [inputText, setInputText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shownOptions, setShownOptions] = useState(optionListData);
  useEffect(() => {
    setShownOptions(optionListData);
  }, [optionListData]);

  const onSearch = searchString => {
    if (searchString && Array.isArray(optionListData)) {
      const result = optionListData.filter(item =>
        item?.optiontext?.toLowerCase().includes(searchString.toLowerCase()),
      );
      setShownOptions(result);
    } else {
      setShownOptions(optionListData);
    }
  };

  const onChangeHandler = useCallback(event => {
    setInputText(event.target.value);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSelectOption = useCallback(optionDetails => {
    setSelectedPlan(optionDetails);
    closeModal();
  }, []);

  useEffect(() => {
    onSearch(inputText);
  }, [inputText]);

  return (
    <>
      <div
        className={classnames(
          'w-[247px] h-11 flex justify-between border border-secondary-500 rounded items-center mt-3 bg-[#FDFDFD] px-2  font-medium text-base',
          {
            'text-secondary-1000': selectedPlan.optionkey,
            'text-[#888781]': !selectedPlan.optionkey,
          },
        )}
        onClick={() => setIsModalOpen(true)}
      >
        <p className="max-w-[174px] whitespace-nowrap overflow-hidden text-ellipsis">
          {Object.keys(selectedPlan).length === 0
            ? t('common.searchOrType')
            : selectedPlan.optiontext}
        </p>{' '}
        <img src={CaretDown} alt="caret" />
      </div>
      <Modal
        isOpen={isModalOpen}
        className="fixed inset-0 flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center"
        appElement={document.getElementById('root')}
        ariaHideApp={false}
      >
        <div className="bg-white min-w-1/4 min-h-1/3 rounded-lg max-w-md">
          <div className="p-0 my-4">
            <div className="flex flex-row mb-7 relative">
              <img
                src={closeButton}
                alt="close"
                className="flex justify-end items-center mx-4 cursor-pointer absolute right-0"
                onClick={closeModal}
              />
            </div>
            <hr className="border-secondary-800" />
            <div className="m-4">
              <input
                data-testid="optionItem-search"
                name={t('common.searchOrType')}
                value={inputText}
                placeholder={t('common.searchOrType')}
                className="w-full focus:outline-none p-1"
                onChange={onChangeHandler}
                autoComplete="off"
              />
              <hr className="border-primary-800" />
              <div className="mt-3 mx-2 max-h-40 overflow-y-auto">
                {shownOptions?.map(optionItemDetails => (
                  <div
                    className="flex justify-between cursor-pointer hover:bg-secondary-100 p-1"
                    onClick={() => onSelectOption(optionItemDetails)}
                    key={optionItemDetails.optionkey}
                  >
                    <p
                      data-testid="optionItem-name"
                      key={`${optionItemDetails.optionkey}`}
                    >
                      {optionItemDetails.optiontext}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

OptionListPlanModal.propTypes = {
  setSelectedPlan: PropTypes.func.isRequired,
  optionListData: PropTypes.arrayOf(
    PropTypes.shape({
      optiontext: PropTypes.string.isRequired,
      optionkey: PropTypes.string.isRequired,
      optionexplanation: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedPlan: PropTypes.shape({
    optiontext: PropTypes.string,
    optionkey: PropTypes.string,
    optionexplanation: PropTypes.string,
  }),
};

OptionListPlanModal.defaultProps = {
  selectedPlan: {
    optiontext: '',
    optionkey: '',
    optionexplanation: '',
  },
};
export default OptionListPlanModal;
