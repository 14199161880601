import React from 'react';
import PropTypes from 'prop-types';
import { whiteArrow } from '../../assets/icons';

/**
 * The TileLink component is a reusable React component that renders a clickable div with customizable
 * content, styling, and event handling including a title and option subtext.
 * @returns The TileLink component is returning a div element with the specified props and content. The
 * div an onClick event handler that brings the user to the associated link.
 */
function TileLink({ title, subtext, onClick }) {
  return (
    <div
      className="cursor-pointer p-3 bg-white flex flex-row"
      onClick={onClick}
      data-testid="tile-link"
    >
      <div>
        <div className="flex flex-row p-1 gap-2">
          <div className="bg-secondary-100 p-1 center rounded-full w-7" />
          <h2 className="text-xl">{title}</h2>
        </div>
        <aside className="p-1">{subtext}</aside>
      </div>
      <div className="bg-gradient-to-br from-primary-400 to-primary-800 rounded-full mt-auto w-11 aspect-square flex justify-center items-center">
        <img className="h-2" src={whiteArrow} alt="arrow" />
      </div>
    </div>
  );
}

TileLink.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

TileLink.defaultProps = {
  subtext: '',
  onClick: () => {},
};

export default TileLink;
