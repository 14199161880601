import axios from 'axios';
import { useMutation } from 'react-query';

/**
 * The `useAddBookmark` function is a custom hook that handles the mutation logic for adding a bookmark
 * to a conversation message.
 * @param conversationId - The conversationId parameter represents the unique identifier of a
 * conversation. It is used to specify which conversation the bookmarked message belongs to.
 * @param messageId - The `messageId` parameter is the unique identifier of the message that you want
 * to bookmark.
 * @param isBookmarked - isBookmarked is a boolean value that indicates whether the message should be
 * bookmarked or not.
 * @returns The `useAddBookmark` function is returning a custom hook that can be used to add a bookmark
 * to a conversation message.
 */
const addBookmark = (conversationId, messageId, isBookmarked) => {
  const payload = {
    isBookmarked,
  };
  const addBookmarkURL = `conversation/${conversationId}/message/${messageId}/bookmark`;
  return axios.post(addBookmarkURL, payload);
};

const useAddBookmark = onSuccess => {
  const mutationFn = payload => {
    const { conversationId, messageId, isBookmarked } = payload;
    return addBookmark(conversationId, messageId, isBookmarked);
  };
  const mutationConfig = { onSuccess };
  return useMutation(mutationFn, mutationConfig);
};

export default useAddBookmark;
