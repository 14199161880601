import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import GreetUser from '../../components/GreetUser/GreetUser';
import ErrorFallbackComponent from '../../components/Error/ErrorFallbackComponent';
import ChatHistoryPanel from '../../sections/ChatHistoryPanel/ChatHistoryPanel.section';
import ConversationContext from '../../context/Conversation.context';
import authFactory from '../../utility/auth/authFactory';
import DisclaimerCard from '../../components/DisclaimerCard/DisclaimerCard';
import { SCOPE_TYPE } from '../../constants/scope.constant';
import RepAccessConversationSection from '../../sections/RepAccessConversationSection.section/RepAccessConversationSection.section';
import CONVERSATION_TYPE from '../../constants/conversationType.constant';
import { REP_ACCESS_CONVERSATION } from '../../constants/repAccess.constant';
import { useAddConversation, useChatsData } from '../../httpRequestHooks';
import  useIsMainTab from '../../hooks/useIsMainTab'

/**
 * The `RepAccess` component is a React component that renders the main page of the application.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useState` hook to keep track of the current conversation and selected HCP.
 * The `useMemo` hook is used to memoize the context value.
 * The `useEffect` hook is used to fetch the rep data and set the rep user flag.
 * The `useEffect` hook is used to set the viewport height.
 * The `useCallback` hook is used to memoize the functions that handle the modal open and close.
 * The `useCallback` hook is used to memoize the function that handles the view guidelines click.
 * @returns The `RepAccess` component is being returned.
 * @param {object} props The `RepAccess` component does not take in any props.
 * The `RepAccess` component renders the main page of the application.
 * It renders the chat history panel, the chat panel, and the bookmark summary.
 * It also renders the conversation selection panel if there is no conversation or selected HCP.
 * It renders the Rep Access Section.
 */
function RepAccess({ setConversationTool }) {
  const existingConversation =
    localStorage.getItem('currentChat') ||
    REP_ACCESS_CONVERSATION.PROBLEM_SOLVE;
  const [currentChat, setCurrentChat] = useState(existingConversation);

  const existingConversationId = JSON.parse(
    localStorage.getItem('conversationIds'),
  );
  const problemSolveConversationId =
    (existingConversationId &&
      existingConversationId[REP_ACCESS_CONVERSATION.PROBLEM_SOLVE]) ||
    null;
  const faqConversationId =
    (existingConversationId &&
      existingConversationId[REP_ACCESS_CONVERSATION.FAQ]) ||
    null;
  const [conversation, setConversation] = useState({
    [REP_ACCESS_CONVERSATION.PROBLEM_SOLVE]: problemSolveConversationId,
    [REP_ACCESS_CONVERSATION.FAQ]: faqConversationId,
  });

  const [conversationId, setConversationId] = useState(
    conversation[currentChat],
  );

  const { data: allChatsData, refetch  } = useChatsData(conversationId);
  const {
    mutateAsync: addConversationAsync,
    data: newConversationData,
    isSuccess: isConversationAdded,
  } = useAddConversation();

  const { t } = useTranslation('common');
  const isMainTab = useIsMainTab();

  const onAddProblemSolveSituation = () => {
    setCurrentChat(REP_ACCESS_CONVERSATION.PROBLEM_SOLVE);
  };

  const onAddFAQAccessAndFulfillment = useCallback(() => {
    setCurrentChat(REP_ACCESS_CONVERSATION.FAQ);
  }, []);

  const sections = [
    {
      sectionId: REP_ACCESS_CONVERSATION.PROBLEM_SOLVE,
      sectionName: t(
        'repAccessLandingPage.conversationSection.section1.heading',
      ),
      onSelect: onAddProblemSolveSituation,
    },
    {
      sectionId: REP_ACCESS_CONVERSATION.FAQ,
      sectionName: t(
        'repAccessLandingPage.conversationSection.section2.heading',
      ),
      onSelect: onAddFAQAccessAndFulfillment,
    },
  ];

  const contextValue = useMemo(
    () => ({
      conversationId,
      setConversationId,
      currentChat,
      setCurrentChat,
      sections,
    }),
    [conversationId, currentChat],
  );

  useEffect(() => {
    const repAccessConversations = {
      ...conversation,
      [currentChat]: conversationId,
    };
    setConversation(repAccessConversations);
    localStorage.setItem(
      'conversationIds',
      JSON.stringify(repAccessConversations),
    );
  }, [conversationId]);

  useEffect(() => {
    localStorage.setItem('currentChat', currentChat);
  }, [currentChat]);

  const startNewConversation = async () => {
    await addConversationAsync({
      conversationTitle: currentChat,
      conversationType: CONVERSATION_TYPE.REP_ACCESS,
    });
  };

  useEffect(() => {
    if (isConversationAdded) {
      setConversationId(newConversationData.conversationId);
    }
  }, [isConversationAdded]);

  useEffect(() => {
    if (conversation[currentChat] === null) {
      startNewConversation();
    } else {
      setConversationId(conversation[currentChat]);
    }
  }, [currentChat]);

  const getUserName = () => {
    const auth = authFactory();
    const pingIdProfile = auth.getProfile();
    return get(pingIdProfile, 'given_name', '');
  };

  const userName = getUserName();

  const copyrightYear = new Date().getFullYear();

  useEffect(() => {
    const createNewConversation = async () => {
      await startNewConversation();
    };

    if (!conversationId) {
      createNewConversation();
    }
  }, [conversationId]);

  // Fetch the latest conversation data when the tab becomes the main tab
  useEffect(() => {
    if (isMainTab) {
      refetch();
    }
  }, [isMainTab, refetch]);

  const initialChatLength = 2;

  return (
    <ConversationContext.Provider value={contextValue}>
      <div className="flex flex-row h-full">
        <div className="w-3/12 p-4 bg-secondary-100 h-full">
          <div className="rounded h-full">
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ChatHistoryPanel
                scope={SCOPE_TYPE.REP_ACCESS}
                setConversationTool={setConversationTool}
              />
            </ErrorBoundary>
          </div>
        </div>
        <div className="w-9/12 p-5 h-full">
          <div className="flex flex-col bg-white-500 h-full">
            <div className="flex flex-col h-full">
              {allChatsData && allChatsData.length > initialChatLength ? (
                <GreetUser userName={userName} />
              ) : (
                <div className="flex-1 overflow-y-auto">
                  <DisclaimerCard
                    userName={userName}
                    scope={SCOPE_TYPE.REP_ACCESS}
                  />
                </div>
              )}

              <div className="flex flex-col overflow-y-auto flex-1">
                <RepAccessConversationSection />
              </div>
              <hr className="border-secondary-800" />
              <span className="self-center mt-2">
                {t('copilotLandingPage.disclaimer')} &nbsp;
                <br />
              </span>
              <span className="self-center">
                {t('copilotLandingPage.copyright', { year: copyrightYear })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ConversationContext.Provider>
  );
}

RepAccess.propTypes = {
  setConversationTool: PropTypes.func.isRequired,
};

export default RepAccess;
