import React from 'react';
import PropTypes from 'prop-types';

/**
 * The DateTimeField component in JavaScript React formats a given date and time and displays it in a
 * specific format.
 * @returns The DateTimeField component is returning a div element that displays the formatted date and
 * time. The formatted date is displayed as "Today" if the messageCreation date is the same as the
 * current date, "Yesterday" if it is one day before the current date, or the full date in the format
 * "Month Day, Year" otherwise. The formatted time is displayed in the format "HH:MM AM
 */
function DateTimeField({ messageCreation }) {
  const utcDate = new Date(messageCreation);

  function areDatesSame(date1, date2) {
    const isYearSame = date1.getFullYear() === date2.getFullYear();
    const isMonthSame = date1.getMonth() === date2.getMonth();
    const isDateSame = date1.getDate() === date2.getDate();
    return isYearSame && isMonthSame && isDateSame;
  }

  function formatTime(date) {
    const options = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return date.toLocaleTimeString(undefined, options);
  }

  function formatDate(date) {
    const currentDate = new Date();
    if (areDatesSame(date, currentDate)) {
      return 'Today';
    }
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    if (areDatesSame(date, yesterday)) {
      return 'Yesterday';
    }
    return date.toLocaleDateString();
  }
  const formattedDate = formatDate(utcDate);
  const formattedTime = formatTime(utcDate);

  return (
    <div className="text-secondary-800" data-testid="date-time-field">
      <span>{formattedDate},</span>&nbsp;<data>{formattedTime}</data>
    </div>
  );
}

DateTimeField.propTypes = {
  messageCreation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};
export default DateTimeField;
