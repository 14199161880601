import AxiosHttpError from '../errors';
import { UNAUTHORIZED_ERROR_CODE } from '../../constants/errorCodes.constant';
import authFactory from '../auth/authFactory';

/**
 * The above code defines two response interceptors for handling errors and successful responses in an
 * Axios HTTP client.
 * @returns In the `errorResponseInterceptor` function, a `Promise` is being returned with the
 * rejection of the `axiosHttpError` object.
 */
export const errorResponseInterceptor = error => {
  const axiosHttpError = new AxiosHttpError(error);
  if (axiosHttpError.statusCode === UNAUTHORIZED_ERROR_CODE) {
    const auth = authFactory();
    auth.refetchToken();
  }
  return Promise.reject(axiosHttpError);
};

export const successResponseInterceptor = response => response;
