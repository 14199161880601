import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

/**
 * The function `useFetchConversations` is a custom hook that fetches conversation data using axios and
 * returns a query object for managing the data fetching state.
 * @returns The `useFetchConversations` function is returning the result of the `useQuery` hook.
 */
const fetchConversationData = params => {
  const fetchConversationURL = 'conversation';
  return axios.get(fetchConversationURL, { params }).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFetchConversations = (params, onSuccess, onError) => {
  const queryKey = ['conversations', params];
  const queryConfig = { enabled: false, onSuccess, onError };
  const queryFn = () => fetchConversationData(params);

  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFetchConversations;
