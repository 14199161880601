import axios from 'axios';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';

/**
 * The function `useAddUserMessage` is a custom hook that uses the `useMutation` hook to add a user
 * message to a conversation.
 * @param conversationId - The conversationId parameter is the unique identifier for the conversation
 * in which the new message will be added.
 * @param newMessage - The `newMessage` parameter is the content of the message that the user wants to
 * add to the conversation. It can be a string containing any text that the user wants to send as a
 * message.
 * @returns The `useAddUserMessage` function is returning the result of calling the `useMutation` hook
 * with the `mutationFn` function as an argument.
 */
const preparePayload = (newMessage, questionId, messageType) => {
  const messageId = uuid();
  let payload = {
    messageId,
    messageMetadata: {
      question_id: questionId,
    },
  };
  if (questionId === 'pre-call-planner') {
    payload = {
      message: {
        type: messageType,
        body: newMessage.body,
        jsonContext: {
          behaviors: newMessage.behaviors,
          app: questionId,
        },
      },
      ...payload,
    };
  } else if (['rep-access', 'faq-rep-access'].includes(questionId)) {
    payload = {
      message: {
        type: messageType,
        body: newMessage.body,
        jsonContext: { ...newMessage.jsonContext },
      },
      ...payload,
    };
  } else {
    payload = {
      message: {
        type: messageType,
        body: newMessage,
        jsonContext: { app: 'co-pilot' },
      },
      ...payload,
    };
  }
  return payload;
};

const addMessageData = (
  conversationId,
  newMessage,
  questionId,
  messageType,
) => {
  const payload = preparePayload(newMessage, questionId, messageType);
  const addMessageURL = `conversation/${conversationId}/message`;
  return axios.post(addMessageURL, payload);
};

const useAddUserMessage = onError => {
  const mutationFn = payload => {
    const { message, messageType, conversationId, questionId } = payload;
    return addMessageData(conversationId, message, questionId, messageType);
  };
  const mutationConfig = { onError };
  return useMutation(mutationFn, mutationConfig);
};

export default useAddUserMessage;
