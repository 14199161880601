import { get } from 'lodash';

export const repAccessAnswer = (selectedAnswer, options) => {
  /**
   * @param {Array} options - List of option
   * @param {String} selectedAnswer - selected answer key by user
   * @returns {String} - return string which is in user readable format
   */
  let selectedOption = options?.[0]?.next_question?.questionoptions?.find(
    optionDetails => optionDetails.optionkey === selectedAnswer,
  );
  if (!selectedOption)
    selectedOption =
      options?.[0]?.next_question?.questionalternativeoptions?.find(
        optionDetails => optionDetails.optionkey === selectedAnswer,
      );
  return selectedOption;
};
export const getSummaryCardData = allChatsData => {
  let lastAIResponse = null;
  const optionsSelected = [];
  allChatsData?.forEach((chatData, index) => {
    const currentChatRole = get(chatData, 'role');
    if (currentChatRole === 'USER' && index !== 0) {
      const questionKey = get(
        lastAIResponse,
        'messageMetadata.additional_context[0].next_question.questionkey',
        null,
      );
      const chosenOption = get(
        chatData,
        `message.jsonContext.access_context.${questionKey}`,
        null,
      );
      const questionSummaryText = get(
        lastAIResponse,
        'messageMetadata.additional_context[0].next_question.questionsummarytext',
        null,
      );
      const questionsection = get(
        lastAIResponse,
        'messageMetadata.additional_context[0].next_question.questionsection',
        null,
      );
      const questionalternativeoptions = get(
        lastAIResponse,
        'messageMetadata.additional_context[0].next_question.questionalternativeoptions',
        null,
      );
      const questionOptions = get(
        lastAIResponse,
        'messageMetadata.additional_context[0].next_question.questionoptions',
        null,
      );
      let selectedOptionData = questionOptions?.find(
        option => option?.optionkey === chosenOption,
      );
      if (!selectedOptionData && questionalternativeoptions)
        selectedOptionData = questionalternativeoptions?.find(
          option => option?.optionkey === chosenOption,
        );
      optionsSelected.push({
        ...selectedOptionData,
        questionSummaryText,
        questionsection,
      });
    } else {
      lastAIResponse = chatData;
    }
  });
  return optionsSelected;
};
