import React from 'react';
import { isNil, omit } from 'lodash';
import PropTypes from 'prop-types';
import { DEFAULT_PLACE_HOLDER } from './constants/placeholders';

/**
 * The SafeHtmlElements function
 * is a React component that
 * renders HTML elements with
 * safe handling of
 * placeholder and children props.
 * @returns The `SafeHtmlElements`
 * function returns a React component.
 * @param {object} props The `SafeHtmlElements`
 * function takes in several props,
 * including `placeholder`,
 * `tagName`, `className`,
 * `onClick`, `dataTestId`,
 * and `children`.
 * The `placeholder` prop is a string
 * or a React node that is displayed
 */
function SafeHtmlElements(props) {
  const { placeholder } = props;
  const { className, onClick, tagName } = props;

  const { children } = props;
  const { dataTestId } = props;

  const TagName = tagName;

  const remainingProps = omit(props, [
    'placeholder',
    'tagName',
    'children',
    'dataTestId',
    'className',
    'onClick',
  ]);

  const generatePlaceHolder = () => {
    if (typeof placeholder === 'object') {
      return placeholder;
    }

    if (isNil(placeholder)) {
      return (
        <TagName
          className={className}
          data-testid={dataTestId}
          {...remainingProps}
        >
          {DEFAULT_PLACE_HOLDER}
        </TagName>
      );
    }

    return (
      <TagName
        data-testid={dataTestId}
        className={className}
        {...remainingProps}
      >
        {placeholder}
      </TagName>
    );
  };

  if (isNil(children)) {
    return generatePlaceHolder();
  }

  if (Array.isArray(children)) {
    if (children.length === 0) {
      return generatePlaceHolder();
    }

    const stringArray = children.map(arrayItem => {
      const joinAbleDataTypes = ['string', 'array', 'null', 'undefined'];
      if (joinAbleDataTypes.indexOf(typeof arrayItem) >= 0) {
        return `${arrayItem}`;
      }
      return '[invalid]';
    });
    const displayString = stringArray.join(', ');

    return (
      <TagName
        className={className}
        onClick={onClick}
        data-testid={dataTestId}
        {...remainingProps}
      >
        {displayString}
      </TagName>
    );
  }

  if (typeof children === 'object') {
    return children;
  }

  return (
    <TagName
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      {...remainingProps}
    >
      {children}
    </TagName>
  );
}

SafeHtmlElements.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  precision: PropTypes.node,
  tagName: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,

  // Note: here we are manually handling all datatypes
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

SafeHtmlElements.defaultProps = {
  placeholder: DEFAULT_PLACE_HOLDER,
  precision: 1,
  className: '',
  tagName: 'p',
  onClick: null,
  dataTestId: null,
};

export default SafeHtmlElements;
