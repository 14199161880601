import axios from 'axios';
import { get } from 'lodash';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
/**
 * The code exports a custom hook called useAddConversation that uses axios to make a POST request to
 * add a conversation with a given title, HCP ID, and conversation ID.
 * @param conversationTitle - The conversation title is a string that represents the title or name of
 * the conversation. It is used as a parameter when adding a new conversation.
 * @param hcpId - The `hcpId` parameter represents the ID of the healthcare professional (HCP)
 * associated with the conversation.
 * @param conversationId - The `conversationId` parameter is a unique identifier for the conversation.
 * It is generated using the `uuid` function from the `uuid` package.
 * @returns The `useAddConversation` function returns a custom hook that can be used to add a
 * conversation. It uses the `useMutation` hook from the `react-query` library to handle the mutation
 * logic. The `mutationFn` function is defined within the `useAddConversation` hook and is responsible
 * for calling the `addConversation` function with the necessary parameters. The `addConversation`
 * function makes
 */

const addConversation = (
  conversationTitle,
  hcpId,
  conversationId,
  conversationType,
) => {
  const addConversationURL = 'conversation';
  let payload = {
    title: conversationTitle,
    conversationId,
    hcpId,
  };
  if (conversationType) {
    payload = { ...payload, conversationType };
  }
  return axios.post(addConversationURL, payload).then(conversation => {
    const payloadPath = 'data.data';
    return get(conversation, payloadPath, null);
  });
};

const useAddConversation = () => {
  const mutationFn = payload => {
    const { conversationTitle, hcpId } = payload;
    const conversationId = uuid();
    return addConversation(
      conversationTitle,
      hcpId,
      conversationId,
      payload.conversationType ?? '',
    );
  };
  return useMutation(mutationFn);
};

export default useAddConversation;
