import i18next from 'i18next';

const PRE_CALL_PLANNER_KEY = 'pre-call-planner';

// eslint-disable-next-line max-lines-per-function
const preCallPlannerOptions = (sellingDivision = 'Darzalex') => ({
  questionType: PRE_CALL_PLANNER_KEY,
  body: i18next.t('preCallPlanner.body'),
  behaviors: [
    {
      behaviorType: i18next.t(
        'preCallPlanner.accountActivitySection.behaviors.behaviorType',
      ),
      behaviorOptions: [
        {
          id: 1,
          option: i18next.t(
            'preCallPlanner.accountActivitySection.behaviors.behaviorOptions.option1',
            { sellingDivision },
          ),
          isSelected: false,
        },
        {
          id: 2,
          option: i18next.t(
            'preCallPlanner.accountActivitySection.behaviors.behaviorOptions.option2',
            { sellingDivision },
          ),
          isSelected: false,
        },
        {
          id: 3,
          option: i18next.t(
            'preCallPlanner.accountActivitySection.behaviors.behaviorOptions.option3',
            { sellingDivision },
          ),
          isSelected: false,
        },
      ],
    },
    {
      behaviorType: i18next.t(
        'preCallPlanner.writingBehaviorSection.behaviors.behaviorType',
      ),
      behaviorOptions: [
        {
          id: 1,
          option: i18next.t(
            'preCallPlanner.writingBehaviorSection.behaviors.behaviorOptions.option1',
            { sellingDivision },
          ),
          isSelected: false,
        },
        {
          id: 2,
          option: i18next.t(
            'preCallPlanner.writingBehaviorSection.behaviors.behaviorOptions.option2',
          ),
          isSelected: false,
        },
        {
          id: 3,
          option: i18next.t(
            'preCallPlanner.writingBehaviorSection.behaviors.behaviorOptions.option3',
          ),
          isSelected: false,
        },
        {
          id: 4,
          option: i18next.t(
            'preCallPlanner.writingBehaviorSection.behaviors.behaviorOptions.option4',
            { sellingDivision },
          ),
          isSelected: false,
        },
        {
          id: 5,
          option: i18next.t(
            'preCallPlanner.writingBehaviorSection.behaviors.behaviorOptions.option5',
          ),
          isSelected: false,
        },
      ],
    },
    {
      behaviorType: i18next.t(
        'preCallPlanner.customerBehaviorSection.behaviors.behaviorType',
      ),
      behaviorOptions: [
        {
          id: 1,
          option: i18next.t(
            'preCallPlanner.customerBehaviorSection.behaviors.behaviorOptions.option1',
          ),
          isSelected: false,
        },
        {
          id: 2,
          option: i18next.t(
            'preCallPlanner.customerBehaviorSection.behaviors.behaviorOptions.option2',
          ),
          isSelected: false,
        },
        {
          id: 3,
          option: i18next.t(
            'preCallPlanner.customerBehaviorSection.behaviors.behaviorOptions.option3',
          ),
          isSelected: false,
        },
        {
          id: 4,
          option: i18next.t(
            'preCallPlanner.customerBehaviorSection.behaviors.behaviorOptions.option4',
          ),
          isSelected: false,
        },
        {
          id: 5,
          option: i18next.t(
            'preCallPlanner.customerBehaviorSection.behaviors.behaviorOptions.option5',
          ),
          isSelected: false,
        },
      ],
    },
  ],
});

export { preCallPlannerOptions, PRE_CALL_PLANNER_KEY };
