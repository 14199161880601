import React, { useContext, useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';
import BookmarkCard from '../../components/BookmarkCard/BookmarkCard';
import { useChatsData, useFetchBookmarks } from '../../httpRequestHooks';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error';
import ConversationContext from '../../context/Conversation.context';
import handleScrollToElementId from '../../utility/scroll/handleScrollToElementId';

/**
 * The `BookmarkSummary` function is a React component that renders the bookmark summary section.
 * It uses various hooks and functions to handle the state and logic of the component.
 * The component uses the `useState` hook to keep track of the selected message id and auto scroll.
 * The `useContext` hook is used to get the conversation id and set the conversation id.
 * The `useFetchBookmarks` hook is used to fetch the bookmarks.
 * The `useChatsData` hook is used to get the chats data.
 * The `useEffect` hook is used to refetch the bookmarks.
 */
function BookmarkSummary() {
  const { setConversationId, conversationId } = useContext(ConversationContext);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [isAutoScroll, setIsAutoScroll] = useState(false);

  const {
    isLoading: isBookmarksLoading,
    data: bookmarksData,
    isSuccess: isBookmarkSuccess,
    isError: isBookmarkError,
    error: bookmarkError,
    refetch: refetchBookmarks,
  } = useFetchBookmarks();

  useEffect(() => {
    refetchBookmarks();
  }, []);

  const onClickViewDetails = useCallback((messageId, currentConversationId) => {
    setSelectedMessageId(messageId);
    setConversationId(currentConversationId);
    setIsAutoScroll(true);
  }, []);

  const { data: allChatsData } = useChatsData(conversationId);

  useEffect(() => {
    if (isAutoScroll) {
      const element = handleScrollToElementId(selectedMessageId);
      if (element) setIsAutoScroll(false);
    }
  }, [allChatsData, isAutoScroll]);

  return (
    <div className="flex-1 overflow-y-auto flex flex-col">
      <div>
        <h3 className="mt-10">Bookmarks</h3>
        <hr className="border-secondary-800 mb-4 mt-3" />
      </div>
      {isBookmarksLoading && (
        <div className="flex flex-col items-center">
          <Loader />
        </div>
      )}
      {isBookmarkError && <Error error={bookmarkError} />}
      {isBookmarkSuccess && (
        <div className="flex flex-col flex-1 overflow-y-auto">
          {bookmarksData.map(bookMarkItem => {
            const messageText = get(bookMarkItem, 'message.body', '');
            const messageId = get(bookMarkItem, 'messageId', '');
            const currentConversationId = get(
              bookMarkItem,
              'conversationId',
              '',
            );
            return (
              <BookmarkCard
                bookMarkedMessage={messageText}
                messageId={messageId}
                onClickViewDetails={onClickViewDetails}
                currentConversationId={currentConversationId}
                key={messageId}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BookmarkSummary;
