import { REFRESH_INTERVAL } from '../../../constants/pollingDuration.constant';

/**
 * This class contains all the secrets required to communicate with PingID
 * @class PingIdSecrets
 * @description
 * This class contains all the secrets required to communicate with PingID
 */

class VeevaSecrets {
  static refreshInterval = REFRESH_INTERVAL;

  static authIdentifier = 'PingOne_Oauth';

  static providerName = 'Open Id Connect';

  static decodeAccessToken(jwtString) {
    const [, encodedPayload] = jwtString.split('.');
    return JSON.parse(atob(encodedPayload));
  }
}

export default VeevaSecrets;
