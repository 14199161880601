import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cancelIcon, dropdown, selectIcon } from '../../assets/icons';
import ConversationDropdown from './ConversationDropdown';

const changeFocus = nodeId => {
  const inputNode = document.getElementById(`input-${nodeId}`);
  const inputNodeLength = inputNode.value.length;
  inputNode.focus();
  inputNode.setSelectionRange(inputNodeLength, inputNodeLength);
};

/**
 * The code defines a React functional
 * component called `ConversationHistoryItem`.
 * It takes in several
 * props, including
 * `conversationId`,
 * `currentConversationId`,
 * `conversationTitle`,
 * `isSelectedConversation`,
 * `onSelectingConversation`,
 * `isHCPConversation`,
 * `onRename`, and
 * `onDelete`.
 * The component uses the `useState` hook to keep track of the current text that has been typed out.
 * The `useEffect` hook is used to set up an interval that will type out the text.
 */
function ConversationHistoryItem(props) {
  const { conversationId, currentConversationId } = props;
  const { conversationTitle } = props;
  const { isSelectedConversation, onSelectingConversation, isHCPConversation } =
    props;
  const { onRename, onDelete } = props;
  const divRef = useRef();

  const [inputText, setInputText] = useState(conversationTitle);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [overlayPosition, setOverlayPosition] = useState({ top: 0, left: 0 });

  const restoreDefaultState = () => {
    setIsInputDisabled(true);
    setIsDelete(false);
    setIsEdit(false);
    setIsDropdown(false);
  };

  useEffect(() => {
    if (!isInputDisabled) {
      changeFocus(currentConversationId);
    }
  }, [isInputDisabled, isSelectedConversation]);

  useEffect(() => {
    if (!isSelectedConversation) {
      restoreDefaultState();
    }
  }, [isSelectedConversation]);

  useEffect(() => {
    if (divRef && divRef.current) {
      const { top, left } = divRef.current.getBoundingClientRect();
      setOverlayPosition({ top, left });
    }
  }, [divRef, conversationId]);

  useEffect(() => {
    restoreDefaultState();
  }, [conversationId]);

  const onCloseDropdown = () => {
    setIsDropdown(false);
  };

  const onCancelHandler = useCallback(() => {
    restoreDefaultState();
  }, []);

  const onConfirmHandler = useCallback(() => {
    if (isEdit) {
      const isSuccess = onRename(currentConversationId, inputText);
      if (isSuccess) {
        restoreDefaultState();
      }
    }
    if (isDelete) {
      onDelete(currentConversationId);
      restoreDefaultState();
    }
  }, [isEdit, isDelete, currentConversationId, inputText]);

  const onTextChangeHandler = useCallback(event => {
    setInputText(event.target.value);
  }, []);

  const onDropdownHandler = useCallback(() => {
    setIsDropdown(true);
    onSelectingConversation();
  }, []);

  const onDeleteHandler = useCallback(() => {
    setIsDelete(true);
    setIsDropdown(false);
  }, []);

  const onRenameHandler = useCallback(() => {
    setIsInputDisabled(false);
    setIsEdit(true);
    setIsDropdown(false);
  }, [currentConversationId]);

  return (
    <div
      data-testid="conversation-history-item"
      className={`p-3 flex cursor-pointer rounded group my-1 ${
        isSelectedConversation
          ? 'bg-primary-100 border border-primary-800'
          : 'bg-card-500'
      }`}
      onClick={onSelectingConversation}
    >
      {isInputDisabled ? (
        <p
          className={`w-fit truncate flex-1 cursor-pointer ${
            isSelectedConversation ? 'bg-primary-100' : 'bg-white'
          } `}
          data-testid="conversation-history"
        >
          {inputText}
        </p>
      ) : (
        <input
          value={inputText}
          className={`w-fit truncate font-partialBold flex-1 focus:outline-none ${
            isSelectedConversation ? 'bg-primary-100' : 'bg-white'
          } `}
          id={`input-${currentConversationId}`}
          onChange={onTextChangeHandler}
          autoComplete="off"
          data-testid="conversation-history-input"
        />
      )}
      {isSelectedConversation && (isEdit || isDelete) ? (
        <div className="flex flex-row">
          <img
            src={selectIcon}
            alt="select"
            onClick={onConfirmHandler}
            className="mx-1"
          />
          <img src={cancelIcon} alt="cancel" onClick={onCancelHandler} />
        </div>
      ) : (
        <div>
          <div ref={divRef} className="relative">
            <img src={dropdown} alt="dropdown" onClick={onDropdownHandler} />
          </div>

          {isSelectedConversation && isDropdown && (
            <div>
              <div className="fixed w-screen h-screen z-40 top-0 left-0">
                <ConversationDropdown
                  onDelete={onDeleteHandler}
                  onRename={onRenameHandler}
                  onCloseDropdown={onCloseDropdown}
                  position={overlayPosition}
                  isHCPConversation={isHCPConversation}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

ConversationHistoryItem.propTypes = {
  conversationId: PropTypes.string.isRequired,
  conversationTitle: PropTypes.string,
  isSelectedConversation: PropTypes.bool,
  onSelectingConversation: PropTypes.func.isRequired,
  onRename: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  currentConversationId: PropTypes.string.isRequired,
  isHCPConversation: PropTypes.bool,
};

ConversationHistoryItem.defaultProps = {
  conversationTitle: 'Conversation Title',
  isSelectedConversation: false,
  isHCPConversation: false,
  onRename: () => {},
};

export default ConversationHistoryItem;
