import axios from 'axios';
import { useMutation } from 'react-query';

/**
 * The `useAddFeedback` function is a custom hook that allows users to add feedback to a conversation
 * message.
 * @param conversationId - The conversationId parameter is the unique identifier for a conversation. It
 * is used to specify which conversation the feedback is being added to.
 * @param messageId - The `messageId` parameter is the unique identifier of the message for which the
 * feedback is being added.
 * @param isHelpful - A boolean value indicating whether the feedback is helpful or not.
 * @returns The `useAddFeedback` function is returning a custom hook that can be used to add feedback
 * to a conversation message.
 */
const addFeedback = (conversationId, messageId, isHelpful) => {
  const payload = {
    isHelpful,
  };
  const addMessageURL = `conversation/${conversationId}/message/${messageId}/feedback`;
  return axios.post(addMessageURL, payload);
};

const useAddFeedback = onSuccess => {
  const mutationFn = payload => {
    const { conversationId, messageId, isHelpful } = payload;
    return addFeedback(conversationId, messageId, isHelpful);
  };
  const mutationConfig = { onSuccess };
  return useMutation(mutationFn, mutationConfig);
};

export default useAddFeedback;
