import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import FEEDBACK_FORM_ID from '../constants/feedbackFormId.constant';

/**
 * The function `useFeedbackQuestions` is a custom hook that uses the `useQuery` hook from a library
 * called `react-query` to fetch feedback questions from an API.
 * @returns The `useFeedbackQuestions` function is returning the result of the `useQuery` hook, which
 * is an object containing the query state and query methods.
 */
const fetchQues = () => {
  const fetchQuesURL = `feedback_question_catalog/${FEEDBACK_FORM_ID}/questions`;
  return axios.get(fetchQuesURL).then(data => {
    const payloadPath = 'data.data';
    return get(data, payloadPath, []);
  });
};

const useFeedbackQuestions = () => {
  const queryKey = 'get questions';
  const queryConfig = { enabled: false };
  const queryFn = fetchQues;
  return useQuery(queryKey, queryFn, queryConfig);
};

export default useFeedbackQuestions;
